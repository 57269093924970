/* eslint-disable @typescript-eslint/naming-convention */

export type BoolFlagName =
  | 'com_project_creation_limits'
  | 'config_event_segment'
  | 'lab_block_multi_review'
  | 'lab_multi_tabs'
  | 'lab_prevent_right_click'
  | 'lab_splitted_json_response'
  | 'lab_synchronize_video_from_bucket'
  | 'lab_video_konvas_v1'
  | 'lops_auth_email_verification';

export type ValueFlagName = never;

export const DEFAULT_FLAGS = {
  com_project_creation_limits: {
    enabled: false,
  },
  config_event_segment: {
    enabled: false,
  },
  data_copilot_number_of_annotations: {
    enabled: false,
  },
  lab_block_multi_review: {
    enabled: false,
  },
  lab_multi_tabs: {
    enabled: false,
  },
  lab_prevent_right_click: {
    enabled: false,
  },
  lab_splitted_json_response: {
    enabled: false,
  },
  lab_synchronize_video_from_bucket: {
    enabled: false,
  },
  lab_video_konvas_v1: {
    enabled: false,
  },
  lops_auth_email_verification: {
    enabled: true,
  },
};

export type FlagName = BoolFlagName | ValueFlagName;
export type FlagValue = { enabled: boolean; value?: string };
export const myFlags: Record<FlagName, FlagValue> = DEFAULT_FLAGS;

export default myFlags;
