import {
  type TranscriptionAnnotationValue,
  type TranscriptionAnnotation,
} from '@/__generated__/globalTypes';

import { type AnnotationFactoryProperties, type FactoryProperties } from './types';

import { getIdForNewAnnotation } from '../../common';

export function createTranscriptionAnnotationValue(
  properties: FactoryProperties<TranscriptionAnnotationValue>,
): TranscriptionAnnotationValue {
  const { id, isPrediction, text } = properties;
  return {
    __typename: 'TranscriptionAnnotationValue',
    id,
    isPrediction,
    text,
  };
}

export function createTranscriptionAnnotation(
  properties: AnnotationFactoryProperties<TranscriptionAnnotation>,
): TranscriptionAnnotation {
  const { annotationValue, job, labelId, path, chatItemId } = properties;
  const { isPrediction, text } = annotationValue;
  const id = getIdForNewAnnotation();
  return {
    __typename: 'TranscriptionAnnotation',
    annotationValue: createTranscriptionAnnotationValue({ id, isPrediction, text }),
    chatItemId,
    id,
    job,
    labelId,
    path,
  };
}
