import createAuth0Client, {
  type Auth0Client,
  type Auth0ClientOptions,
  type getIdTokenClaimsOptions,
  type GetTokenSilentlyOptions,
  type GetTokenWithPopupOptions,
  type IdToken,
  type LogoutOptions,
  type PopupLoginOptions,
  type RedirectLoginOptions,
  type RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import _get from 'lodash/get';
import React, { useState, useEffect, useContext } from 'react';

import { environment, isTestingOldSignInOnCypress } from './config';
import { Environment } from './constants/environment';
import { useCacheOriginalUrl } from './url';

export interface Auth0RedirectState {
  targetUrl?: string;
}

// eslint-disable-next-line
export interface Auth0User extends Omit<IdToken, '__raw'> {}

interface IAuth0Context {
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>;
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  isInitializing: boolean;
  isPopupOpen: boolean;
  loginWithPopup(o?: PopupLoginOptions): Promise<void>;
  loginWithRedirect(o?: RedirectLoginOptions): Promise<void>;
  logout(o?: LogoutOptions): void;
  user?: Auth0User;
}
interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback(result: RedirectLoginResult): void;
}

export const AUTH0_ACCESS_TOKEN_FOR_CYPRESS = 'auth0_access_token';

export const Auth0Context = React.createContext<IAuth0Context>({} as IAuth0Context);
export const useAuth0 = (): IAuth0Context => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions): React.ReactElement => {
  useCacheOriginalUrl();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [user, setUser] = useState<Auth0User>();
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes('code=')) {
        try {
          const { appState }: RedirectLoginResult = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        } catch (e) {
          onRedirectCallback({});
        }
      }

      if (await auth0FromHook.isAuthenticated()) {
        const userProfile = await auth0FromHook.getUser();

        setIsAuthenticated(true);
        setUser(userProfile);

        /* If the user is not identified with auth0, we should not care if the email is verified */
        if (userProfile) {
          const isAuth0User = userProfile.sub?.startsWith('auth0|');
          setIsEmailVerified(isAuth0User ? userProfile.email_verified ?? false : true);
        } else setIsEmailVerified(false);
      }

      setIsInitializing(false);
    };

    initAuth0();
    // useEffect has no dependency according to Auth0 documentation
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (options?: PopupLoginOptions) => {
    setIsPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(options);
    } catch (error) {
      console.error(error);
    } finally {
      setIsPopupOpen(false);
    }

    const userProfile = await auth0Client?.getUser();
    setUser(userProfile);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    if (!auth0Client) return Promise.reject();
    setIsInitializing(true);

    const result = await auth0Client?.handleRedirectCallback();
    const userProfile = await auth0Client?.getUser();

    setIsInitializing(false);
    setIsAuthenticated(true);
    setUser(userProfile);

    return result;
  };

  const loginWithRedirect = (options?: RedirectLoginOptions) =>
    auth0Client?.loginWithRedirect(options) || Promise.reject();

  const getTokenSilently = (options?: GetTokenSilentlyOptions) =>
    auth0Client?.getTokenSilently(options) || Promise.reject();

  const logout = (options?: LogoutOptions) => auth0Client?.logout(options) || Promise.reject();

  const getIdTokenClaims = (options?: getIdTokenClaimsOptions) =>
    auth0Client?.getIdTokenClaims(options) || Promise.reject();

  const getTokenWithPopup = (options?: GetTokenWithPopupOptions) =>
    auth0Client?.getTokenWithPopup(options) || Promise.reject();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const valueProps = {
    getIdTokenClaims,
    getTokenSilently,
    getTokenWithPopup,
    handleRedirectCallback,
    isAuthenticated,
    isEmailVerified,
    isInitializing,
    isPopupOpen,
    loginWithPopup,
    loginWithRedirect,
    logout,
    user,
  };

  return <Auth0Context.Provider value={valueProps}>{children}</Auth0Context.Provider>;
};

export const isUsingOpenIdConnect = !!_get(
  window,
  '_env_.AUTHENTICATION__OPENID_CONNECT_AUTO_ISSUER',
);
export const isUsingLocalConnect =
  (isTestingOldSignInOnCypress && environment === Environment.DEVELOPMENT) ||
  (!isUsingOpenIdConnect && _get(window, '_env_.AUTHENTICATION__IS_USING_AUTH0') !== 'True');
export const isUsingAuth0 = !isUsingOpenIdConnect && !isUsingLocalConnect;
