import { css } from '@emotion/css';

export const classes = {
  iconContainer: css`
    display: flex;
    flex: 1;
    flex-grow: 0;
  `,

  root: css`
    align-items: center;
    display: inline-flex;
  `,

  rootWithGap: css`
    gap: var(--space-1);
  `,

  textContent: css`
    color: var(--color-omega-accent-5);
    margin: 0;
  `,
};
