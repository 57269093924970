import { type PoseEstimationPoint } from '@kili-technology/types';
import L from 'leaflet';

import { type PoseEstimation } from '../../../Image/LeafletMapv2/KiliLeafletLayers/KiliPoseEstimation/KiliPoseEstimation';

/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
L.Edit.PolyVerticesEditPose = (L.Edit.PolyVerticesEdit as any).extend({
  _createMarker(latlng: L.LatLng, index: number) {
    // @ts-expect-error Extending L.Marker in TouchEvents.js to include touch.
    const marker = new L.Marker.Touch(latlng, {
      draggable: true,
      icon: this.options.icon,
    });

    marker._origLatLng = latlng;
    marker._index = index;

    marker
      .on('dragstart', this._onMarkerDragStart, this)
      .on('drag', this._onMarkerDrag, this)
      .on('dragend', this._fireEdit, this)
      .on('touchmove', this._onTouchMove, this)
      .on('touchend', this._fireEdit, this)
      .on('MSPointerMove', this._onTouchMove, this)
      .on('MSPointerUp', this._fireEdit, this);

    this._markerGroup.addLayer(marker);

    return marker;
  },

  _createMiddleMarker() {
    // Do not create middle edit markers for pose estimation
  },

  _onMarkerClick() {},

  initialize(
    poly: L.Polyline,
    latlngs: L.LatLngExpression[],
    options: L.EditOptions.EditPolyVerticesEditOptions,
    points: PoseEstimationPoint[],
  ) {
    // if touch, switch to touch icon
    if (L.Browser.touch) {
      this.options.icon = this.options.touchIcon;
    }
    this._poly = poly;

    this._latlngs = latlngs;
    this.points = points;

    L.setOptions(this, options);
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
L.Edit.PoseEstimation = (L.Edit.Poly as any).extend({
  _initHandlers() {
    const poly = this._poly;
    const { poseOptions } = poly;
    const { allPoints } = poseOptions;
    if (!allPoints) {
      return;
    }
    this._verticesHandlers = [];
    for (let i = 0; i < this.latlngs.length; i += 1) {
      this._verticesHandlers.push(
        new L.Edit.PolyVerticesEditPose(
          this._poly,
          this.latlngs[i],
          this._poly.options.poly,
          allPoints,
        ),
      );
    }
  },

  addHooks() {
    this._poly.removeHooks();
    this._initHandlers();
    this._eachVertexHandler((handler: L.Handler) => {
      handler.addHooks?.();
    });
  },

  getNewDrawer(): L.Draw.PoseEstimation {
    const poly = this._poly;
    const { poseOptions, options, kiliOptions } = poly;
    const { allPoints, pointIndicesDrawn } = poseOptions;
    return new L.Draw.PoseEstimation(poly._map, {
      // autoEnable: false,
      categories: kiliOptions?.categories,
      isEditing: true,
      jobName: kiliOptions?.jobName,
      pointIndicesDrawn,
      posePoints: (allPoints as PoseEstimationPoint[]).map(point => ({
        ...point,
        color: options.color,
      })),
      repeatMode: true,
      shapeOptions: {
        color: options.color,
      },
    });
  },

  initialize(poly: PoseEstimation) {
    this.canKeepDrawing = poly.canKeepDrawing();
    this.isFinished = poly.isFinished;
    this.latlngs = [poly._latlngs];

    this._poly = poly;

    this._poly.on('revert-edited', this._updateLatLngs, this);
    document.addEventListener('keydown', event => {
      if (event.key === 'Escape') {
        if (this.drawer) this.drawer.removeHooks();
      }
    });
  },

  removeHooks() {
    this._poly.fire('mouseout');
    this._eachVertexHandler((handler: L.Handler) => {
      handler.removeHooks?.();
    });
    this._poly.addHooks();
  },
});
