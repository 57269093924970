import { type Jobs, type JsonInterface, MachineLearningTask } from '@kili-technology/types';

import { type VideoObjectDetectionAnnotation } from '@/__generated__/globalTypes';
import { type VideoAnnotation } from '@/graphql/annotations/types';
import { broadcastQueriesAfterUpdatingCache, getCacheIdFromGraphQLObject } from '@/graphql/helpers';
import { projectJobs } from '@/redux/selectors';
import { canChangeClass, type CategoryResponse } from '@/services/jobs/changeClass';
import { store } from '@/store';
import { useStore } from '@/zustand';
import { useHistoryStore } from '@/zustand-history';

import { addAnnotationInCache } from './cache/addAnnotationInCache';
import { findAnnotationInCache } from './cache/findAnnotationInCache';
import { findAnnotationsInCache } from './cache/findAnnotationsInCache';
import { getAnnotationsRelatedToMidFromCache } from './cache/getAnnotationsRelatedToMidFromCache';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';

import {
  deleteAnnotationsInCache,
  updateCategoryNameAndJobForVideoObjectDetectionAnnotationInCache,
} from '../actions';

type AvailableClassesToChangeSplitPayload = {
  annotations: VideoAnnotation[];
  jsonInterface: JsonInterface | undefined;
};

const getAllowedCategoryResponsesSplit = (
  annotation: VideoAnnotation,
  jobs: Jobs,
  allowCurrentCategory: boolean,
): CategoryResponse[] => {
  if (!isVideoObjectDetectionAnnotation(annotation)) {
    return [];
  }

  const { category: categoryCodeForAnnotation, job: jobNameForAnnotation } = annotation;
  const mlTask = MachineLearningTask.OBJECT_DETECTION;
  const jobOfAnnotation = jobs?.[jobNameForAnnotation];
  const changedLayerTool = jobOfAnnotation?.tools?.[0];

  if (!changedLayerTool) {
    throw new Error('Tool not provided. Impossible to select available classes for change class');
  }

  const allowedJobAndCategoryCodes = Object.entries(jobs)
    .map(([jobName, job]) => {
      const isValidMlTask = mlTask === job.mlTask;
      if (isValidMlTask && canChangeClass(jobName, jobs, changedLayerTool)) {
        const allowedCategories = jobs?.[jobName]?.content?.categories ?? {};
        const currentAllowedJobAndCategoryCodes = Object.keys(allowedCategories).map(
          categoryCode => {
            if (
              !allowCurrentCategory &&
              categoryCode === categoryCodeForAnnotation &&
              jobName === jobNameForAnnotation
            ) {
              return null;
            }
            return {
              categoryCode,
              categoryName: allowedCategories[categoryCode].name,
              jobName,
            };
          },
        );
        return currentAllowedJobAndCategoryCodes;
      }
      return [];
    })
    .flat()
    .filter((obj: CategoryResponse | null): obj is CategoryResponse => {
      return !!obj;
    });
  return allowedJobAndCategoryCodes;
};

export const availableClassesToChangeSplit = (
  payload: AvailableClassesToChangeSplitPayload,
): CategoryResponse[] => {
  const { annotations, jsonInterface } = payload;
  const jobs = jsonInterface?.jobs;

  if (!annotations.length || !jobs) {
    return [];
  }

  const allowCurrentCategory = annotations.length > 1;

  return annotations.reduce((commonCategoryResponses, annotation, index) => {
    const annotationCategoryResponses = getAllowedCategoryResponsesSplit(
      annotation,
      jobs,
      allowCurrentCategory,
    );

    if (commonCategoryResponses.length === 0 && index === 0) {
      return annotationCategoryResponses;
    }

    return commonCategoryResponses.filter(commonCategoryResponse =>
      annotationCategoryResponses.some(
        ({ categoryCode, categoryName, jobName }) =>
          commonCategoryResponse.categoryCode === categoryCode &&
          commonCategoryResponse.categoryName === categoryName &&
          commonCategoryResponse.jobName === jobName,
      ),
    );
  }, [] as CategoryResponse[]);
};

export const changeAnnotationClassVideoSplit = ({
  categoryCode,
  jobName,
  mid,
  name,
}: {
  categoryCode: string;
  jobName: string;
  mid: string;
  name: string;
}) => {
  const annotationOfMid = findAnnotationInCache(
    (annotation): annotation is VideoObjectDetectionAnnotation =>
      isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
  );

  if (!annotationOfMid) return;

  // First delete all subjobs corresponding to old annotation
  const annotationsToDelete = findAnnotationsInCache(annotation =>
    annotation.path.some(p => p[0] === annotationOfMid.id && p[1] === annotationOfMid.category),
  );
  deleteAnnotationsInCache(annotationsToDelete.map(d => d.id));

  // Then update the category
  updateCategoryNameAndJobForVideoObjectDetectionAnnotationInCache(
    getCacheIdFromGraphQLObject(annotationOfMid),
    categoryCode,
    name,
    jobName,
  );
  broadcastQueriesAfterUpdatingCache();
};

export const changeAnnotationsClassVideoSplitWithHistory = ({
  categoryCode,
  categoryCurrentCounter,
  jobName,
  mids,
}: {
  categoryCode: string;
  categoryCurrentCounter: number;
  jobName: string;
  mids: string[];
}) => {
  const { setAnnotationName } = useStore.getState().labelFrame;
  const jobsInterface = projectJobs(store.getState());
  const categoryName = jobsInterface[jobName].content.categories?.[categoryCode].name;

  if (categoryName === undefined) {
    throw new Error(`Unable to get name for category ${categoryCode} of job ${jobName}`);
  }

  const annotationChanges = mids
    .slice()
    .sort()
    .map(mid => {
      const [annotationOfMid, ...annotationsChildrenOfMid] =
        getAnnotationsRelatedToMidFromCache(mid);

      return { annotationOfMid, annotationsChildrenOfMid, mid };
    })
    .filter(
      ({ annotationOfMid }) =>
        annotationOfMid.job !== jobName || annotationOfMid.category !== categoryCode,
    )
    .map(({ annotationOfMid, annotationsChildrenOfMid, mid }, index) => ({
      annotationOfMid,
      annotationsChildrenOfMid,
      mid,
      newName: `${categoryName} ${categoryCurrentCounter + index + 1}`,
    }));

  const action = {
    name: 'changeAnnotationsClassVideoSplit',
    redo: () => {
      annotationChanges.forEach(({ mid, newName }) => {
        changeAnnotationClassVideoSplit({ categoryCode, jobName, mid, name: newName });
        setAnnotationName({ mid, name: newName });
      });
    },
    undo: () => {
      annotationChanges.forEach(({ annotationOfMid, annotationsChildrenOfMid, mid }) => {
        changeAnnotationClassVideoSplit({
          categoryCode: annotationOfMid.category,
          jobName: annotationOfMid.job,
          mid,
          name: annotationOfMid.name as string,
        });
        setAnnotationName({ mid, name: annotationOfMid.name as string });
        annotationsChildrenOfMid.forEach(addAnnotationInCache);
      });
    },
  };

  action.redo();

  useHistoryStore.getState().history.addAction(action);
};
