/* eslint-disable no-underscore-dangle */
import L, { type DrawMap } from 'leaflet';

L.EditToolbar.include({
  _checkDisabled() {
    const { featureGroup } = this.options;
    const hasLayers = Object.keys(featureGroup._layers).length !== 0;
    let button;
    if (this.options.edit) {
      // @ts-expect-error TYPE missing from edit toolbar type definition
      button = this._modes[L.EditToolbar.Edit.TYPE].button;
      if (hasLayers) {
        L.DomUtil.removeClass(button, 'leaflet-disabled');
      } else {
        L.DomUtil.addClass(button, 'leaflet-disabled');
      }
      button.setAttribute(
        'title',
        hasLayers
          ? L.drawLocal.edit.toolbar.buttons.edit
          : L.drawLocal.edit.toolbar.buttons.editDisabled,
      );
    }
  },

  addToolbar(map: DrawMap) {
    const container = L.Toolbar.prototype.addToolbar.call(this, map);

    this._checkDisabled();

    this.options.featureGroup.on('layerremove', this._checkDisabled, this);

    return container;
  },

  removeToolbar() {
    this.options.featureGroup.off('layerremove', this._checkDisabled, this);

    L.Toolbar.prototype.removeToolbar.call(this);
  },
});
