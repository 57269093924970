import { cx } from '@emotion/css';
import { Typography } from '@material-ui/core';
import { type FC, type FunctionComponent, type SVGProps } from 'react';

import { classes } from './IconLabel.style';

import Tooltip from '../../../../../../components/Tooltip';

interface IconLabelProps {
  hasGap?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  showText?: boolean;
  testId?: string;
  text?: string;
  title?: string;
}

const IconLabel: FC<IconLabelProps> = ({
  hasGap = true,
  icon: Icon,
  showText = true,
  testId = '',
  text = '',
  title = '',
  children,
}) => {
  return (
    <Typography
      className={cx(classes.root, hasGap && classes.rootWithGap)}
      component="span"
      data-testid={testId}
      variant="body2"
    >
      <Tooltip enableDefaultDelays title={title} wrapperClassName={classes.iconContainer}>
        {Icon ? <Icon /> : null}
        {children}
      </Tooltip>
      {showText && <p className={classes.textContent}>{text}</p>}
    </Typography>
  );
};

export default IconLabel;
