import _cloneDeep from 'lodash/cloneDeep';

import { type Modification, type SplitLabelSlice, type SplitLabelSliceValues } from './types';

import { type ZustandSlice, type ZustandStore } from '..';
import createLogger from '../helpers';

const initialState: SplitLabelSliceValues = {
  nextModifications: { annotation: {}, annotationValue: {} },
  pendingModifications: null,
};

const log = createLogger('splitLabel');

const addAnnotationModification = (id: string, modification: Modification, state: ZustandStore) => {
  const { annotationValue, annotation } = state.splitLabel.nextModifications;
  if (modification === 'delete') {
    delete annotationValue[id];
  }
  if (annotation[id] === 'add') {
    if (modification === 'delete') {
      delete annotation[id];
    }
    return;
  }
  if (annotation[id] === 'delete') {
    if (modification === 'add') {
      annotation[id] = 'update';
    }
    return;
  }
  annotation[id] = modification;
};

const addAnnotationValueModification = (
  idAnnotation: string,
  idAnnotationValue: string,
  modification: Modification,
  state: ZustandStore,
) => {
  const { annotationValue, annotation } = state.splitLabel.nextModifications;
  if (annotation[idAnnotation] === 'add' || annotation[idAnnotation] === 'delete') {
    // changes will be persisted in the annotation modification
    return;
  }
  if (!annotationValue[idAnnotation]) {
    annotationValue[idAnnotation] = {};
  }
  if (annotationValue[idAnnotation][idAnnotationValue] === 'add') {
    if (modification === 'delete') {
      delete annotationValue[idAnnotation][idAnnotationValue];
    }
    return;
  }
  if (annotationValue[idAnnotation][idAnnotationValue] === 'delete') {
    if (modification === 'add') {
      annotationValue[idAnnotation][idAnnotationValue] = 'update';
    }
    return;
  }
  annotationValue[idAnnotation][idAnnotationValue] = modification;
};

export const createSplitLabelSlice: ZustandSlice<SplitLabelSlice> = set => ({
  ...initialState,
  addAnnotationModification: (id, modification) =>
    set(
      state => {
        addAnnotationModification(id, modification, state);
      },
      false,
      log('addModification'),
    ),
  addAnnotationModifications: (ids, modification) =>
    set(
      state => {
        ids.forEach(id => {
          addAnnotationModification(id, modification, state);
        });
      },
      false,
      log('addModifications'),
    ),
  addAnnotationValueModification: (idAnnotation, idAnnotationValue, modification) =>
    set(
      state => {
        addAnnotationValueModification(idAnnotation, idAnnotationValue, modification, state);
      },
      false,
      log('addValueModification'),
    ),
  addAnnotationValueModifications: (idAnnotation, idAnnotationValues, modification) =>
    set(
      state => {
        idAnnotationValues.forEach(idAnnotationValue => {
          addAnnotationValueModification(idAnnotation, idAnnotationValue, modification, state);
        });
      },
      false,
      log('addValueModification'),
    ),
  cleanClassicAnnotations: annotations => {
    set(
      state => {
        const { annotation, annotationValue } = state.splitLabel.nextModifications;
        Object.keys(annotation).forEach(id => {
          if (
            annotations.some(
              annotationToClean => id === `${annotationToClean.__typename}:${annotationToClean.id}`,
            )
          )
            delete annotation[id];
        });
        Object.keys(annotationValue).forEach(idAnnotation => {
          if (
            annotations.some(
              annotationToClean =>
                idAnnotation === `${annotationToClean.__typename}:${annotationToClean.id}`,
            )
          )
            delete annotationValue[idAnnotation];
        });
      },
      false,
      log('cleanAnnotationsOfChatItem'),
    );
  },
  initialize: () =>
    set(
      state => {
        delete state.splitLabel.latestSavedLabelId;
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;

          (state.splitLabel[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize'),
    ),
  moveNextToPendingModifications: () => {
    set(
      state => {
        if (state.splitLabel.pendingModifications) {
          throw new Error(
            'Trying to move next modifications to pending modifications while there are already pending modifications',
          );
        }
        state.splitLabel.pendingModifications = state.splitLabel.nextModifications;
        state.splitLabel.nextModifications = { annotation: {}, annotationValue: {} };
      },
      false,
      log('moveNextToPendingModifications'),
    );
  },
  saveFailure: () => {
    set(
      state => {
        // first do a deep copy of the current next modifications
        const currentNextModifications = _cloneDeep(state.splitLabel.nextModifications);

        if (!state.splitLabel.pendingModifications) {
          throw new Error('Cannot find pending modifications');
        }

        // Then we replace the next modifications with the pending modifications
        state.splitLabel.nextModifications = state.splitLabel.pendingModifications;

        // Then we add each modifications that were in the current next modifications
        Object.entries(currentNextModifications.annotation).forEach(([id, modification]) => {
          addAnnotationModification(id, modification, state);
        });
        Object.entries(currentNextModifications.annotationValue).forEach(
          ([idAnnotation, annotationValue]) => {
            Object.entries(annotationValue).forEach(([idAnnotationValue, modification]) => {
              addAnnotationValueModification(idAnnotation, idAnnotationValue, modification, state);
            });
          },
        );

        state.splitLabel.pendingModifications = null;
      },
      false,
      log('saveFailure'),
    );
  },
  saveSuccess: (labelId: string) =>
    set(
      state => {
        state.splitLabel.pendingModifications = null;
        state.splitLabel.latestSavedLabelId = labelId;
      },
      false,
      log('saveSuccess'),
    ),
});

export * from './types';
