/*
 This files was created to patch the Draw.Rectangle class from leaflet-draw to be able to support the rotation of the map when drawing a rectangle
 */

import L from 'leaflet';
import '../Path/KiliRectangle';

const MIN_MOVE_DISTANCE_PX = 2;

/* eslint-disable no-underscore-dangle */

interface ExtendedDrawRectangle extends L.Draw.Rectangle {
  _downEvent: L.LeafletMouseEvent | undefined; // Custom property, not from leaflet-draw
}

L.Draw.Rectangle.include({
  _drawShape(this: L.Draw.Rectangle, latlng: L.LatLng) {
    const startContainerPoint = this._map.latLngToContainerPoint(this._startLatLng);
    const endContainerPoint = this._map.latLngToContainerPoint(latlng);

    // Then we need to get the 4 corners of the rectangle as container points. We have to put first the point with the
    // smallest x and biggest y values, then the point with the smallest x and the smallest y value, then the point with
    // the biggest x and the smallest y value, and finally the point with the biggest x and the biggest y value.
    const allRectanglePointsAsContainerPoints = [
      L.point(
        Math.min(startContainerPoint.x, endContainerPoint.x),
        Math.max(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.min(startContainerPoint.x, endContainerPoint.x),
        Math.min(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.max(startContainerPoint.x, endContainerPoint.x),
        Math.min(startContainerPoint.y, endContainerPoint.y),
      ),
      L.point(
        Math.max(startContainerPoint.x, endContainerPoint.x),
        Math.max(startContainerPoint.y, endContainerPoint.y),
      ),
    ];

    const allRectanglePoints = allRectanglePointsAsContainerPoints.map(point =>
      this._map.containerPointToLatLng(point),
    ) as L.RectangleLatLngs;

    if (!this._shape) {
      this._shape = new L.KiliRectangle(
        allRectanglePoints,
        this._map,
        this.options.shapeOptions,
        true,
      );
      this._map.addLayer(this._shape);
    } else {
      this._shape.setLatLngs(allRectanglePoints);
    }
  },

  _fireCreatedEvent(this: L.Draw.Rectangle) {
    const latLngs = this._shape.getLatLngs()[0] as L.RectangleLatLngs;

    // If 2 consecutive points are strictly identical (ie have the same latitude and the same
    // longitude), the rectangle is invalid.
    const isRectangleInvalid = latLngs.slice(1).some((latLng, index) => {
      const previousLatLng = latLngs[index];

      return (
        Math.abs(latLng.lat - previousLatLng.lat) < Number.EPSILON &&
        Math.abs(latLng.lng - previousLatLng.lng) < Number.EPSILON
      );
    });

    if (isRectangleInvalid) {
      return;
    }

    const rectangle = new L.KiliRectangle(latLngs, this._map, this.options.shapeOptions, true);
    L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this, rectangle);
  },

  _onMouseDown(this: ExtendedDrawRectangle, event: L.LeafletMouseEvent) {
    this._downEvent = event;

    if (!this._isCurrentlyTwoClickDrawing) {
      L.Draw.SimpleShape.prototype._onMouseDown.call(this, event);
    }
  },

  _onMouseMove(this: ExtendedDrawRectangle, event: L.LeafletMouseEvent) {
    if (!this._downEvent) {
      return;
    }

    // If a mouse move is triggered while the user tries to draw a rectangle using the 2-clicks
    // method, a shape will be created, that will break some internal leaflet-draw check inside
    // Rectangle mouseup listener, and will lead to the 2-clicks feature being broken. To avoid
    // this, we check if the mouse moved enough before calling the move handler.

    const deltaX = Math.abs(this._downEvent.originalEvent.clientX - event.originalEvent.clientX);
    const deltaY = Math.abs(this._downEvent.originalEvent.clientY - event.originalEvent.clientY);

    if (deltaX >= MIN_MOVE_DISTANCE_PX || deltaY >= MIN_MOVE_DISTANCE_PX) {
      L.Draw.SimpleShape.prototype._onMouseMove.call(this, event);
    }
  },
});
