import { batch } from 'react-redux';

import { PROJECTS_UPDATE } from './slice';
import { type ProjectTable, type UpdateProjectsInReduxPayload } from './types';

import { cleanStateForProjectList } from '../application/actions';
import { initializeState as assetInit } from '../asset-label/actions';
import { LABELED_ASSETS_RESET } from '../asset-label/slice';
import { initializeState as projectInit } from '../project/actions';
import { type ProjectState } from '../project/types';
import { utcToLocalTime } from '../project-user/helpers';
import { PROJECT_USER_INITIALIZE } from '../project-user/slice';
import { type AppThunk } from '../types';

export const formatProjectsForTable = (projects: ProjectState[]): ProjectTable[] =>
  projects?.map(formatProjectForTable) || [];

const formatProjectForTable = (rowData: ProjectState): ProjectTable => {
  return {
    archivedAt: rowData.archivedAt,
    createdAt: utcToLocalTime(rowData.createdAt),
    description: rowData.description,
    lastUpdate: utcToLocalTime(rowData.updatedAt),
    mlTasks: rowData.mlTasks,
    myRole: rowData.myRole,
    name: rowData.title,
    numberOfAssets: rowData.numberOfAssets,
    numberOfRemainingAssets: rowData.numberOfRemainingAssets,
    projectID: rowData.id,
    roles: rowData.roles,
    type: rowData.inputType,
  };
};

export const initializeState = (): AppThunk => {
  return async dispatch => {
    batch(() => {
      dispatch(assetInit());
      dispatch(cleanStateForProjectList());
      dispatch(LABELED_ASSETS_RESET());
      dispatch(projectInit());
      dispatch(PROJECT_USER_INITIALIZE());
    });
  };
};

export const updateProjectsInRedux = (payload: UpdateProjectsInReduxPayload): AppThunk => {
  return async dispatch => {
    const { data, totalCount } = payload;
    if (!data) return;
    const prettyProjects = formatProjectsForTable(data);
    const projectsTable = {
      data: prettyProjects.map(o => ({ ...o })),
      totalCount,
    };
    dispatch(PROJECTS_UPDATE(projectsTable));
  };
};
