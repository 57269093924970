import L from 'leaflet';

export const getProjection = (line: L.Line, ptH: L.Point) => {
  if (line.b === 0) {
    // x does not change
    return new L.Point(line.aPoint.x, ptH.y);
  }
  if (line.diviseur === 0) {
    // y does not change
    return new L.Point(ptH.x, line.bPoint.y);
  }
  const E = -1 / line.b;
  const alpha = E + 1 / E;
  const newYH = (ptH.x + E * ptH.y + line.c) / alpha;
  const newXH = (1 - E / alpha) * (ptH.x + E * ptH.y) - (E / alpha) * line.c;
  return new L.Point(newXH, newYH);
};
