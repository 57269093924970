import { gql } from '@apollo/client';

export const GQL_FRAGMENT_ANNOTATION = gql`
  fragment Annotation on Annotation {
    id
    job
    labelId
    path
    ... on ClassificationAnnotation {
      chatItemId
      annotationValue {
        categories
        id
        isPrediction
      }
    }
    ... on ComparisonAnnotation {
      chatItemId
      annotationValue {
        id
        isPrediction
        choice {
          code
          firstId
          secondId
        }
      }
    }
    ... on RankingAnnotation {
      chatItemId
      annotationValue {
        id
        isPrediction
        orders {
          elements
          rank
        }
      }
    }
    ... on TranscriptionAnnotation {
      chatItemId
      annotationValue {
        id
        isPrediction
        text
      }
    }
    ... on VideoAnnotation {
      frames {
        start
        end
      }
      keyAnnotations {
        id
        frame
        annotationValue {
          id
          isPrediction
          ... on ClassificationAnnotationValue {
            categories
          }
          ... on ObjectDetectionAnnotationValue {
            vertices {
              x
              y
            }
          }
          ... on TranscriptionAnnotationValue {
            text
          }
        }
      }
    }
    ... on VideoObjectDetectionAnnotation {
      name
      category
      mid
    }
  }
`;

export const GQL_FRAGMENT_ANNOTATION_VALUE = gql`
  fragment AnnotationValue on AnnotationValue {
    id
    isPrediction
    ... on ClassificationAnnotationValue {
      categories
    }
    ... on ObjectDetectionAnnotationValue {
      vertices {
        x
        y
      }
    }
    ... on ComparisonAnnotationValue {
      choice {
        code
        firstId
        secondId
      }
    }
    ... on RankingAnnotationValue {
      orders {
        elements
        rank
      }
    }
    ... on TranscriptionAnnotationValue {
      text
    }
  }
`;

export const GQL_FRAGMENT_VIDEOANNOTATION_FRAMES = gql`
  fragment Frames on VideoAnnotation {
    frames {
      start
      end
    }
  }
`;

export const GQL_FRAGMENT_VIDEOKEYANNOTATION_ID = gql`
  fragment VideoKeyAnnotationId on VideoKeyAnnotation {
    id
  }
`;

export const GQL_FRAGMENT_VIDEOANNOTATION_KEY_ANNOTATIONS = gql`
  fragment KeyAnnotations on VideoAnnotation {
    keyAnnotations {
      id
      frame
      annotationValue {
        ... on ObjectDetectionAnnotationValue {
          id
          isPrediction
          vertices {
            x
            y
          }
        }
        ... on ClassificationAnnotationValue {
          categories
          id
          isPrediction
        }
        ... on TranscriptionAnnotationValue {
          id
          isPrediction
          text
        }
      }
    }
  }
`;

export const GQL_FRAGMENT_VIDEOANNOTATION_JOB = gql`
  fragment Job on VideoAnnotation {
    job
  }
`;

export const GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_NAME = gql`
  fragment Name on VideoObjectDetectionAnnotation {
    name
  }
`;

export const GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_CATEGORY_AND_JOB = gql`
  fragment CategoryAndJob on VideoObjectDetectionAnnotation {
    category
    job
    name
  }
`;

export const GQL_FRAGMENT_VIDEOKEYANNOTATION = gql`
  fragment KeyAnnotation on VideoKeyAnnotation {
    id
    frame
    annotationValue {
      id
      isPrediction
      ... on ObjectDetectionAnnotationValue {
        vertices {
          x
          y
        }
      }
      ... on ClassificationAnnotationValue {
        categories
      }
      ... on TranscriptionAnnotationValue {
        text
      }
    }
  }
`;

export const GQL_FRAGMENT_VIDEOKEYANNOTATION_ANNOTATIONVALUE = gql`
  fragment VideoKeyAnnotationValue on VideoKeyAnnotation {
    annotationValue {
      id
      isPrediction
      ... on ObjectDetectionAnnotationValue {
        vertices {
          x
          y
        }
      }
      ... on ClassificationAnnotationValue {
        categories
      }
      ... on TranscriptionAnnotationValue {
        text
      }
    }
  }
`;

export const GQL_FRAGMENT_VIDEOANNOTATION_PATH = gql`
  fragment Path on VideoAnnotation {
    path
  }
`;

export const GQL_FRAGMENT_VIDEOKEYANNOTATION_FRAME = gql`
  fragment VideoKeyAnnotationFrame on VideoKeyAnnotation {
    frame
  }
`;
