import {
  type ClassificationAnnotation,
  type ClassificationAnnotationValue,
} from '@/__generated__/globalTypes';

import { type AnnotationFactoryProperties, type FactoryProperties } from './types';

import { getIdForNewAnnotation } from '../../common';

export function createClassificationAnnotationValue(
  properties: FactoryProperties<ClassificationAnnotationValue>,
): ClassificationAnnotationValue {
  const { categories, id, isPrediction } = properties;
  return {
    __typename: 'ClassificationAnnotationValue',
    categories,
    id,
    isPrediction,
  };
}

export function createClassificationAnnotation(
  properties: AnnotationFactoryProperties<ClassificationAnnotation>,
): ClassificationAnnotation {
  const { annotationValue, job, labelId, path, chatItemId } = properties;
  const { categories, isPrediction } = annotationValue;
  const id = getIdForNewAnnotation();
  return {
    __typename: 'ClassificationAnnotation',
    annotationValue: createClassificationAnnotationValue({ categories, id, isPrediction }),
    chatItemId,
    id,
    job,
    labelId,
    path,
  };
}
