/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

import { downloadImageAsset, isAssetServedByKili } from '../../../../../services/assets/download';

// @ts-expect-error TileLayerWithHeader does not exist in Leaflet
L.TileLayerWithHeader = L.TileLayer.extend({
  _initTile(tile: HTMLImageElement) {
    tile.classList.add('leaflet-tile');
    // eslint-disable-next-line no-param-reassign
    tile.onselectstart = L.Util.falseFn;
    // eslint-disable-next-line no-param-reassign
    tile.onmousemove = L.Util.falseFn;
  },

  // Override _invalidateAll to avoid flickering when zooming
  _invalidateAll() {},

  createTile(coords: L.Coords, done: () => void) {
    const url = this.getTileUrl(coords);
    const tile = document.createElement('img');

    L.DomEvent.on(tile, 'load', L.Util.bind(this._tileOnLoad, this, done, tile));
    L.DomEvent.on(tile, 'error', L.Util.bind(this._tileOnError, this, done, tile));

    tile.setAttribute('role', 'presentation');

    if (isAssetServedByKili(url)) {
      downloadImageAsset(url, this.authenticationToken, undefined, true).then((base64: string) => {
        tile.src = base64;
      });
    } else {
      tile.src = url;
    }
    return tile;
  },

  getBounds(): L.LatLngBounds {
    return this.options.bounds;
  },

  getTileUrl(coords: L.Coords) {
    const urlstringed = this._url
      .replace('{x}', coords.x.toString())
      .replace('{y}', coords.y.toString())
      .replace('{z}', coords.z.toString());
    const url = new URL(urlstringed);
    return url.toString();
  },

  initialize(url: string, options: L.TileLayerOptions, authenticationToken: string) {
    // @ts-expect-error initialize does not exist on TileLayer.prototype
    L.TileLayer.prototype.initialize.call(this, url, options);
    this.authenticationToken = authenticationToken;
  },
});
