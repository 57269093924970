import { type MachineLearningTask, type Tool } from '@kili-technology/types';

import { type InputType } from '../../__generated__/globalTypes';
import { type ToolName } from '../../components/asset-ui/Image/LeafletMapv2/type';
import { type KiliAnnotation } from '../../services/jobs/setResponse';
import { type AccordionTree } from '../../services/jsonInterface/accordion';

export enum TagMode {
  ALWAYS = 'Always',
  NEVER = 'Never',
  ON_HOVER = 'On hover',
}

export type ToolOptions = {
  isSelected?: boolean;
  isVisible?: boolean;
};

export type GrantedTools = Partial<Record<ToolName, ToolOptions>>;

export type RelationSettings = {
  showRelation: boolean;
};

export type ToolThenClassSettings = {
  showToolThenClass: boolean;
};

export type TagSettings = {
  displayMode: TagMode;
  prevDisplayMode: TagMode;
  showConfidence: boolean;
  showNestedClassification: boolean;
  showNestedTranscription: boolean;
};

export type PoseEstimationSettings = {
  showLines: boolean;
};

export type PropagationSettings = {
  isEnabled: boolean;
  isFullVideo: boolean;
  value: number;
};

export type TimelineSettings = {
  height: number;
};

export type InteractiveSegmentationSettings = {
  modelVariant: 'tiny' | 'large';
};

export type LabelInterfaceSettings = {
  interactiveSegmentation: InteractiveSegmentationSettings;
  poseEstimation: PoseEstimationSettings;
  propagation: PropagationSettings;
  relation: RelationSettings;
  tag: TagSettings;
  timeline: TimelineSettings;
  toolThenClass: ToolThenClassSettings;
};

export type LabelInterfaceSettingName = keyof LabelInterfaceSettings;

export type LabelInterfaceSettingsKeyParams = {
  projectId: string;
  userId: string;
};

export type SelectedCategory = {
  categoryCode: string;
  jobName: string;
  mlTask: MachineLearningTask;
};

export type LabelInterfaceSliceValues = Readonly<{
  accordionTree: AccordionTree;
  annotationCopy: KiliAnnotation | null;
  bearing: number;
  canDisplayLabels: boolean;
  creatingOrEditingObjectId: string | null;
  displayedAnnotationMids: string[];
  grantedTools: GrantedTools;
  hiddenObjectIds: string[];
  imageHeight: number;
  imageWidth: number;
  isAssetPanelExpanded: boolean;
  isHoveringWizard: boolean;
  isJobViewerExpanded: boolean;
  lastSelectedCategories: SelectedCategory[];
  queryString: string;
  selectedObjectIds: string[];
  settings: Record<string, LabelInterfaceSettings>;
  showOnlyMissingJobs: boolean;
  startTime: number;
  temporaryAnnotations: KiliAnnotation[];
  zoomLevel: number;
  zoomLevelMax: number | undefined;
  zoomLevelMin: number | undefined;
  zoomToFitTrigger: number | undefined;
}>;

export interface LabelInterfaceSetZoomLevelSettings {
  zoomLevelMax?: number | undefined;
  zoomLevelMin?: number | undefined;
}

export type LabelInterfaceInitializePayload = {
  inputType: InputType;
  projectTools: Tool[];
};

export type OpenCategoryInAccordionPayload = {
  children: {
    jobcode: string;
  };
  mid: string;
  parent: {
    categoryCode: string;
    jobcode: string;
  };
};

export type LabelInterfaceSliceSetters = Readonly<{
  addSelectedObjectId: (_: { mid: string }) => void;
  addSelectedObjectIds: (_: { mids: string[] }) => void;
  cancelTemporaryAnnotation: (_: { mid: string }) => void;
  clearTools: () => void;
  closeCategoriesFromJobsInAccordion: (_: string[]) => void;
  decrementZoomLevel: () => void;
  disableTool: (_: { name: ToolName }) => void;
  generateNewCreatingOrEditingObjectId: () => void;
  hideObjects: (_: string[] | string) => void;
  incrementZoomLevel: () => void;
  initialize: (_: LabelInterfaceInitializePayload) => void;
  openCategoryInAccordion: (_: OpenCategoryInAccordionPayload) => void;
  removeAllSelectedObjectIds: () => void;
  removeSelectedObjectId: (_: { mid: string }) => void;
  removeSelectedObjectIds: (_: { mids: string[] }) => void;
  replaceAllWithSelectedObjectId: (_: { mid: string }) => void;
  resetSettings: (_: LabelInterfaceSettingsKeyParams) => void;
  resetTools: (_: LabelInterfaceInitializePayload) => void;
  setImageSize: (_: { height: number; width: number }) => void;
  setTool: (_: { name: ToolName }) => void;
  setZoomLevel: (_: number) => void;
  setZoomLevelSettings: (_: LabelInterfaceSetZoomLevelSettings) => void;
  showObjects: (_: string[] | string) => void;
  toggleSelectedObjectId: (_: { mid: string }) => void;
  triggerZoomToFit: () => void;
  updateField: <T extends keyof LabelInterfaceSliceValues>(_: {
    path: T;
    value: LabelInterfaceSliceValues[T];
  }) => void;
  updateSettings: <T extends LabelInterfaceSettingName>(
    _: {
      settingName: T;
      settings: Partial<LabelInterfaceSettings[T]>;
    } & LabelInterfaceSettingsKeyParams,
  ) => void;
  updateTemporaryAnnotation: (_: { mid: string; newAnnotation: KiliAnnotation }) => void;
}>;

export type LabelInterfaceSlice = LabelInterfaceSliceValues & LabelInterfaceSliceSetters;
