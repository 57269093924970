import { LabelType } from '../../../../../__generated__/globalTypes';

export const CONSENSUS_MARK = 'consensusMark';
export const CREATED_AT = 'createdAt';
export const EXTERNAL_ID = 'externalIdIn';
export const HONEYPOT_MARK = 'honeypotMark';
export const INFERENCE_MARK = 'inferenceMark';
export const ISSUE_STATUS = 'issueStatus';
export const ISSUE_TYPE = 'issueType';
export const LABEL_HONEYPOT_MARK = 'labelHoneypotMark';
export const LABELED_BY = 'authorIn';
export const METADATA = 'metadata';
export const SEARCH = 'search';
export const SKIPPED = 'skipped';
export const STATUS = 'statusIn';
export const TYPE = 'typeIn';

export const WHITESPACE = ' ';
export const AND_OPERATOR = `${WHITESPACE}AND${WHITESPACE}`;
export const OR_OPERATOR = `${WHITESPACE}OR${WHITESPACE}`;

export const SCOPE_OF_LABELS_SEARCHED = [
  LabelType.DEFAULT,
  LabelType.INFERENCE,
  LabelType.PREDICTION,
  LabelType.REVIEW,
];
