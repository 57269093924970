import L from 'leaflet';

/* eslint-disable no-underscore-dangle */

L.Path.include({
  /**
   * Check if the feature was dragged, that'll supress the click event
   * on mouseup. That fixes popups for example
   *
   * @param  {MouseEvent} e
   */
  _onMouseClick(this: L.Path, e: MouseEvent) {
    if (
      (this.dragging && this.dragging.moved()) ||
      (this._map.dragging && (this._map.dragging as L.DraggingHandler).moved())
    ) {
      return;
    }

    this._fireMouseEvent(e);
  },

  /**
   * Applies matrix transformation to SVG
   * @param {Array.<Number>?} matrix
   */
  _transform(this: L.Path, matrix: L.MatrixArray | null) {
    if (this._renderer) {
      if (matrix) {
        this._renderer.transformPath(this, matrix);
      } else {
        // reset transform matrix
        // if not reset, next time I move, I move from the original position
        this._renderer._resetTransformPath(this);
        this._update();
      }
    }
    return this;
  },
  getMap(this: L.Path) {
    return this._map;
  },
  makeDraggable(this: L.Path) {
    return L.Handler.PathDrag.makeDraggable(this);
  },
});

L.Path.addInitHook(function initDragging(this: L.Path) {
  if (this.options.draggable) {
    // ensure interactive
    this.options.interactive = true;

    if (this.dragging) {
      this.dragging.enable();
    } else {
      this.dragging = new L.Handler.PathDrag(this);
      this.dragging.enable();
    }
  } else if (this.dragging) {
    this.dragging.disable();
  }
});
