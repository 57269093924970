/* eslint-disable no-underscore-dangle */

import { FliptWebProvider } from '@openfeature/flipt-web-provider';
import { OpenFeature, InMemoryProvider } from '@openfeature/react-sdk';

import { isUsingFlipt } from '@/config';

import { DEVELOPMENT_FLAGS } from './developmentFlags';

export const setOpenFeatureProvider = (): void => {
  /* InMemoryProvider does not support typing for flags keys
    https://github.com/open-feature/js-sdk/issues/967
  */

  if (!isUsingFlipt) {
    OpenFeature.setProvider(new InMemoryProvider(DEVELOPMENT_FLAGS as any));
  } else {
    const apiUrl = window._env_?.FEATURE_FLAG__API_URL;
    const apiToken = window._env_?.FEATURE_FLAG__API_TOKEN;
    const apiNamespace = window._env_?.FEATURE_FLAG__API_NAMESPACE;

    const provider = new FliptWebProvider(apiNamespace, {
      authentication: {
        clientToken: apiToken,
      },
      url: apiUrl,
    });
    OpenFeature.setProvider(provider);
  }
};

export const setOpenFeatureUserEmail = async (email: string): Promise<void> => {
  await OpenFeature.setContext({ email });
};
