import { type WritableDraft } from 'immer/dist/types/types-external';

import { initialState } from './initialState';
import { projectQueueSelection } from './selectors';
import {
  type PrioritizeAssetsPayload,
  type ProjectQueueSlice,
  type ProjectQueueSliceValues,
} from './types';

import { type ZustandSlice, type ZustandStore } from '..';
import { GQL_PRIORITIZE_ASSETS } from '../../graphql/asset/mutations';
import createLogger from '../helpers';

const log = createLogger('project-queue');

const setStateValues = (
  state: WritableDraft<ZustandStore>,
  sliceValues: ProjectQueueSliceValues = initialState,
) => {
  Object.entries(sliceValues).forEach(([key, value]) => {
    (state.projectQueue[key as keyof typeof sliceValues] as unknown) = value;
  });
};

export const createProjectQueueSlice: ZustandSlice<ProjectQueueSlice> = (set, getState) => ({
  ...initialState,
  initialize: () => set(setStateValues, false, log('initialize')),

  prioritizeAssets: async (payload: PrioritizeAssetsPayload) => {
    const { client, priority, callback, where = {}, projectID } = payload;
    const projectQueueWhere = projectQueueSelection(getState());

    await client.mutate({
      context: { clientName: 'V2' },
      mutation: GQL_PRIORITIZE_ASSETS,
      variables: {
        priority,
        where: {
          ...where,
          ...projectQueueWhere,
          project: { id: projectID },
        },
      },
    });

    callback();
  },

  resetProjectInfos: payload =>
    set(state => setStateValues(state, payload), false, log('resetProjectInfos')),

  selectOrUnselectAllAssets: (selectAll?: boolean) =>
    set(
      state => {
        const { areAllAssetsSelected } = state.projectQueue;
        setStateValues(state, {
          ...initialState,
          areAllAssetsSelected: selectAll ?? !areAllAssetsSelected,
        });
      },
      false,
      'selectOrUnselectAllAssets',
    ),

  selectOrUnselectAsset: (assetId: string) =>
    set(
      state => {
        setUpdateSelectionState(state, assetId);
      },
      false,
      log('selectOrUnselectAsset', assetId),
    ),

  updateSelectionState: (assetId: string) =>
    set(
      state => setUpdateSelectionState(state, assetId),
      false,
      log('updateSelectionState', assetId),
    ),
});

function setUpdateSelectionState(state: WritableDraft<ZustandStore>, assetId: string) {
  const { selectedAssetIds, areAllAssetsSelected, unselectedAssetIds } = state.projectQueue;

  const isSelectingAnAsset = unselectedAssetIds.includes(assetId);

  if (areAllAssetsSelected && isSelectingAnAsset) {
    state.projectQueue.unselectedAssetIds = unselectedAssetIds.filter(
      unselectedAssetId => unselectedAssetId !== assetId,
    );
    return;
  }

  if (areAllAssetsSelected) {
    state.projectQueue.unselectedAssetIds = [...unselectedAssetIds, assetId];
    return;
  }

  const isUnselectingAnAsset = selectedAssetIds.includes(assetId);
  if (isUnselectingAnAsset) {
    state.projectQueue.selectedAssetIds = selectedAssetIds.filter(
      selectedAssetId => selectedAssetId !== assetId,
    );
    return;
  }

  state.projectQueue.selectedAssetIds = [...selectedAssetIds, assetId];
}
