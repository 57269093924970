export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** Custom scalar enforcing a string to be an email */
  Email: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Custom scalar enforcing a pagination of queries. Maximum value is 500 */
  PageSize: number;
}

export interface AcceptAdmissionRequest {
  id: Scalars['ID'];
  organizationRole: OrganizationRole;
}

export interface AcceptOrDeclineInvitationData {
  isAccepting: Scalars['Boolean'];
}

export interface ActionLabelerMetric extends LabelerMetric {
  __typename: 'ActionLabelerMetric';
  action: LabelerAction;
  date: Scalars['DateTime'];
  userId: Scalars['ID'];
  /** Total number of action for user on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface ActivateOrDeactivatePluginWhere {
  pluginName: Scalars['String'];
  projectId: Scalars['ID'];
}

export interface AnnotateLLMAssetData {
  annotationChanges?: InputMaybe<AnnotationLocalChanges>;
  annotationValueChanges?: InputMaybe<AnnotationValueLocalChanges>;
  authorId: Scalars['ID'];
  elementId: Scalars['ID'];
}

export interface AnnotateWithCopilotData {
  /** Percentage of assets on which to run Copilot. Value from 0 to 100 */
  assetsPercentage?: InputMaybe<Scalars['Int']>;
  jobNames: Array<Scalars['String']>;
  useRandomAssets?: InputMaybe<Scalars['Boolean']>;
}

export interface Annotation {
  /** Id of annotation in DB */
  id: Scalars['ID'];
  /** Job of the annotation */
  job: Scalars['String'];
  /** Label id to which belongs the annotation */
  labelId: Scalars['ID'];
  /** Path to the annotation. It is a list of couples (annotationId, category) */
  path: Array<Array<Scalars['String']>>;
}

export interface AnnotationChangesInput {
  annotationChanges?: InputMaybe<AnnotationLocalChanges>;
  annotationValueChanges?: InputMaybe<AnnotationValueLocalChanges>;
  labelId?: InputMaybe<Scalars['ID']>;
}

export interface AnnotationCreateInput {
  category?: InputMaybe<Scalars['String']>;
  chatItemId?: InputMaybe<Scalars['ID']>;
  frames?: InputMaybe<Array<FrameIntervalInput>>;
  id: Scalars['ID'];
  job: Scalars['String'];
  mid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  path: Array<Array<Scalars['String']>>;
  task: Scalars['String'];
  values: Array<AnnotationValueCreateInput>;
}

export interface AnnotationDeleteInput {
  id: Scalars['ID'];
}

export interface AnnotationLocalChanges {
  creates?: InputMaybe<Array<AnnotationCreateInput>>;
  deletes?: InputMaybe<Array<AnnotationDeleteInput>>;
  updates?: InputMaybe<Array<AnnotationUpdateInput>>;
}

export interface AnnotationUpdateInput {
  category?: InputMaybe<Scalars['String']>;
  frames?: InputMaybe<Array<FrameIntervalInput>>;
  id: Scalars['ID'];
  job?: InputMaybe<Scalars['String']>;
  mid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  task?: InputMaybe<Scalars['String']>;
}

export interface AnnotationValue {
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
}

export interface AnnotationValueCreateInput {
  annotationId: Scalars['ID'];
  categories?: InputMaybe<Array<Scalars['String']>>;
  choice?: InputMaybe<ComparisonValueInput>;
  frameNumber?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isPrediction?: InputMaybe<Scalars['Boolean']>;
  value?: InputMaybe<Scalars['String']>;
  vertices?: InputMaybe<Array<Array<Array<VerticeInput>>>>;
}

export interface AnnotationValueDeleteInput {
  annotationId: Scalars['ID'];
  id: Scalars['ID'];
}

export interface AnnotationValueLocalChanges {
  creates?: InputMaybe<Array<AnnotationValueCreateInput>>;
  deletes?: InputMaybe<Array<AnnotationValueDeleteInput>>;
  updates?: InputMaybe<Array<AnnotationValueCreateInput>>;
}

export interface AnnotationWhere {
  labelId: Scalars['String'];
}

export interface AnnotationsLabelerMetric extends LabelerMetric {
  __typename: 'AnnotationsLabelerMetric';
  date: Scalars['DateTime'];
  userId: Scalars['ID'];
  /** Total number of annotations for user on given granularity */
  value: Maybe<Scalars['Int']>;
}

/** Api keys are used to connect to the API (Kili's Python SDK). */
export interface ApiKey {
  __typename: 'ApiKey';
  _: Maybe<Scalars['Boolean']>;
  /** Date at which the api key was created */
  createdAt: Scalars['DateTime'];
  /** Date at which the api key will expire */
  expiryDate: Scalars['DateTime'];
  /** ID of the api key. */
  id: Scalars['ID'];
  /** Api key */
  key: Scalars['String'];
  /** Name of the api key. */
  name: Scalars['String'];
  /** If true, the api key cannot be used */
  revoked: Scalars['Boolean'];
  /** User who owns the api key */
  user: Maybe<User>;
  /** ID of the user who owns the api key */
  userId: Scalars['ID'];
}

export interface ApiKeyWhere {
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserWhere>;
}

export interface AppendLabelData {
  annotationChanges?: InputMaybe<AnnotationLocalChanges>;
  annotationValueChanges?: InputMaybe<AnnotationValueLocalChanges>;
  assetID: Scalars['ID'];
  authorID?: InputMaybe<Scalars['ID']>;
  clientVersion?: InputMaybe<Scalars['Int']>;
  jsonResponse: Scalars['String'];
  modelName?: InputMaybe<Scalars['String']>;
  previousLabelID?: InputMaybe<Scalars['ID']>;
  reviewedLabel?: InputMaybe<ReviewedLabel>;
  secondsToLabel?: InputMaybe<Scalars['Float']>;
}

export interface AppendManyAssetsData {
  contentArray: Array<Scalars['String']>;
  externalIDArray?: InputMaybe<Array<Scalars['String']>>;
  idArray?: InputMaybe<Array<Scalars['String']>>;
  isHoneypotArray: Array<Scalars['Boolean']>;
  jsonContentArray: Array<InputMaybe<Scalars['String']>>;
  jsonMetadataArray: Array<Scalars['String']>;
}

export interface AppendManyFramesToDatasetAsynchronouslyData {
  contentArray?: InputMaybe<Array<Scalars['String']>>;
  externalIDArray?: InputMaybe<Array<Scalars['String']>>;
  idArray?: InputMaybe<Array<Scalars['String']>>;
  jsonContentArray?: InputMaybe<Array<Scalars['String']>>;
  jsonMetadataArray?: InputMaybe<Array<Scalars['String']>>;
  multiLayerContentArray?: InputMaybe<Array<InputMaybe<Array<MultiLayerContent>>>>;
  uploadType?: InputMaybe<UploadType>;
}

export interface AppendManyLabelsData {
  labelType: LabelType;
  labelsData: Array<AppendLabelData>;
  overwrite?: InputMaybe<Scalars['Boolean']>;
  refuseInvalidJsonResponse?: InputMaybe<Scalars['Boolean']>;
}

export interface AppendManyToDatasetData {
  contentArray?: InputMaybe<Array<Scalars['String']>>;
  externalIDArray?: InputMaybe<Array<Scalars['String']>>;
  idArray?: InputMaybe<Array<Scalars['String']>>;
  isHoneypotArray?: InputMaybe<Array<Scalars['Boolean']>>;
  jsonContentArray?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  jsonMetadataArray?: InputMaybe<Array<Scalars['String']>>;
  statusArray?: InputMaybe<Array<Status>>;
}

export interface AppendManyToDatasetWarnings {
  __typename: 'AppendManyToDatasetWarnings';
  duplicatedIds: Maybe<Array<Maybe<Scalars['String']>>>;
  linesWithNoId: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export interface AppendToCommentsData {
  inReview: Scalars['Boolean'];
  text: Scalars['String'];
}

export interface AppendToIssuesData {
  issueNumber: Scalars['Int'];
  labelID?: InputMaybe<Scalars['ID']>;
  objectMid?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: IssueType;
}

export interface AppendToLabelsData {
  annotationChanges?: InputMaybe<AnnotationLocalChanges>;
  annotationValueChanges?: InputMaybe<AnnotationValueLocalChanges>;
  authorID: Scalars['ID'];
  clientVersion?: InputMaybe<Scalars['Int']>;
  jsonResponse: Scalars['String'];
  labelType: LabelType;
  previousLabelID?: InputMaybe<Scalars['ID']>;
  reviewedLabel?: InputMaybe<ReviewedLabel>;
  secondsToLabel?: InputMaybe<Scalars['Float']>;
  skipped?: InputMaybe<Scalars['Boolean']>;
}

export interface AppendToRolesData {
  role: ProjectRole;
  userEmail: Scalars['String'];
}

export interface ApplyChangesToLabelsData {
  annotationChanges: AnnotationLocalChanges;
  annotationValueChanges: AnnotationValueLocalChanges;
  clientVersion: Scalars['Int'];
  labelType?: InputMaybe<LabelType>;
  secondsToLabel?: InputMaybe<Scalars['Float']>;
}

/** Asset are the data lines. They can either be images, text, videos, or pdf. */
export interface Asset {
  __typename: 'Asset';
  _: Maybe<Scalars['Boolean']>;
  assetProjectModels: Array<AssetProjectModel>;
  /**
   * Consensus is a quality parameter to measure the agreement between several annotations of the same asset. In the case of Asset used for consensus, it is the agreement score between the annotators.
   *
   * The global mark depends on the asset type, it can either be a mean estimator or the average of the Fleiss Kappas.
   */
  consensusMark: Maybe<Scalars['Float']>;
  /** Technical field. */
  consensusMarkCompute: Maybe<Scalars['JSON']>;
  /**
   * Consensus is a quality parameter to measure the agreement between several annotations of the same asset. In the case of Asset used for consensus, it is the agreement score between the annotators.
   *
   * The global mark depends on the asset type, it can either be a mean estimator or the average of the Fleiss Kappas.
   */
  consensusMarkPerCategory: Maybe<Scalars['JSON']>;
  /** Content of the Asset or URL to the Asset. */
  content: Scalars['String'];
  /** Used the same way as content, for upcoming video classification. Duplicate of jsonContent formatted as as JSON instead of a string. */
  contentJson: Maybe<Scalars['JSON']>;
  /** Technical field. */
  contentJsonCompute: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  /** Current step information for the asset */
  currentStep: Maybe<AssetWorkflowStep>;
  /** Returns the dataIntegration id if the asset is stored in a remote bucket, null if it isn't. */
  dataIntegrationId: Maybe<Scalars['String']>;
  /** Sum of durations along all labels of the asset (in seconds) */
  duration: Maybe<Scalars['Int']>;
  /** Technical field. */
  durationCompute: Maybe<Scalars['Int']>;
  /** Name of the Asset, visible on the top of the screen on the labelling page, and defined when uploading the image. */
  externalId: Maybe<Scalars['String']>;
  /**
   * Honeypot is a quality parameter to measure the agreement between a pre-annotated asset(a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * If this Asset is a Honeypot, average of the honeypots obtained by the annotators on this Asset.
   */
  honeypotMark: Maybe<Scalars['Float']>;
  /** Technical field. */
  honeypotMarkCompute: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Inference mark at asset level is the inference mark on the very last label by creation date. */
  inferenceMark: Maybe<Scalars['Float']>;
  /** Technical field. */
  inferenceMarkCompute: Maybe<Scalars['Float']>;
  /**
   * Honeypot is a quality parameter to measure the agreement between a pre-annotated asset (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * Indicates whether this asset is a Honeypot.
   */
  isHoneypot: Maybe<Scalars['Boolean']>;
  /**
   * Technical field, used in case the asset is created by remote storage to determine wether the data
   * can be processed by Kili services or not.
   */
  isProcessingAuthorized: Maybe<Scalars['Boolean']>;
  /** Indicates whether one or more user were attributed the asset to label */
  isToBeLabeledBy: Maybe<Scalars['Boolean']>;
  /**
   * Review is a quality assessment procedure in which the reviewer validate or not the label created on asset. He can create issues on the labels or on several annotations, or send back to annotation.
   *
   * This field indicates if the asset will be part of this validation procedure. The percentage of asset up for review is set at the project level (see reviewCoverage).
   */
  isToReview: Maybe<Scalars['Boolean']>;
  /**
   * Consensus is a quality parameter to measure the agreement between several annotations of the same asset. In the case of Asset used for consensus, it is the agreement score between the annotators.
   *
   * In the case of the use of consensus as a quality indicator for the project, this field indicates that the asset is retained to be annotated by different annotators.
   */
  isUsedForConsensus: Maybe<Scalars['Boolean']>;
  /** The issues created for this asset. */
  issues: Array<Issue>;
  /** Used the same way as content, for upcoming video classification */
  jsonContent: Maybe<Scalars['String']>;
  /** Asset metadata, i.e. additionnal information shown during the labeling process */
  jsonMetadata: Maybe<Scalars['String']>;
  /** Lastest version of the label. */
  labelVersion: Scalars['Int'];
  /** The labels created for this asset. */
  labels: Array<Label>;
  /** Latest label I can trust for my model. */
  latestLabel: Maybe<Label>;
  /** Technical field */
  latestLabelCompute: Maybe<Label>;
  /** Asset metadata, i.e. additionnal information shown during the labeling process. Duplicate of jsonMetadata formatted as as JSON instead of a string. */
  metadata: Maybe<Scalars['JSON']>;
  /** Technical field. */
  metadataCompute: Maybe<Scalars['JSON']>;
  /** Ocr metadata, i.e. hidden layer of text informations used for OCR tasks */
  ocrMetadata: Maybe<Scalars['String']>;
  /** Only applicable for PDF assets. A list of page resolutions */
  pageResolutions: Maybe<Array<PageResolution>>;
  /** Assets can be ordered in the labeling process, the minimum value being 0 */
  priority: Scalars['Int'];
  /** Assets are always related to one project. */
  project: Maybe<Project>;
  projectId: Scalars['String'];
  /** Technical field. */
  projectIdCompute: Maybe<Scalars['String']>;
  /** Only applicable for Image and Video assets: the asset resolution (width, height) */
  resolution: Maybe<Resolution>;
  /**
   * Technical field
   * @deprecated This field is not used anymore. Use toBeLabeledBy instead
   */
  sentBackTo: Maybe<Array<ProjectUser>>;
  /** A boolean indicating whether the asset was skipped or not */
  skipped: Scalars['Boolean'];
  /**
   * Technical field.
   * @deprecated This field is not used anymore. Please upgrade kili by typing pip install kili --upgrade
   */
  skippedCompute: Maybe<Scalars['Boolean']>;
  /** Current annotation status of the asset. Can take the values: TODO, ONGOING, LABELED, REVIEWED, TO_REVIEW, AUTOSAVE. */
  status: Status;
  /** Technical field. */
  statusCompute: Maybe<Status>;
  /**
   * Thumbnail of the asset to display in the frontend
   *
   * NB : only available for on-premise and SaaS users
   */
  thumbnail: Maybe<Scalars['String']>;
  /** Technical field */
  thumbnailCompute: Maybe<Scalars['String']>;
  /** One or more user that were attributed the asset to label */
  toBeLabeledBy: Maybe<Array<ProjectUser>>;
  updatedAt: Scalars['DateTime'];
}

export interface AssetData {
  consensusMark?: InputMaybe<Scalars['Float']>;
  content?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  honeypotMark?: InputMaybe<Scalars['Float']>;
  isHoneypot?: InputMaybe<Scalars['Boolean']>;
  isToReview?: InputMaybe<Scalars['Boolean']>;
  isUsedForConsensus?: InputMaybe<Scalars['Boolean']>;
  jsonContent?: InputMaybe<Scalars['String']>;
  jsonMetadata?: InputMaybe<Scalars['String']>;
  pageResolutions?: InputMaybe<Array<PageResolutionInput>>;
  priority?: InputMaybe<Scalars['Int']>;
  resolution?: InputMaybe<ResolutionInput>;
  sentBackTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shouldResetToBeLabeledBy?: InputMaybe<Scalars['Boolean']>;
  skipped?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Status>;
  toBeLabeledBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface AssetIdOnly {
  __typename: 'AssetIdOnly';
  id: Scalars['ID'];
}

export interface AssetMetric {
  date: Scalars['DateTime'];
  value: Maybe<Scalars['Int']>;
}

export enum AssetMetricInput {
  Consensus = 'Consensus',
  Honeypot = 'Honeypot',
  Inference = 'Inference',
  OpenQuestions = 'OpenQuestions',
  Skipped = 'Skipped',
  Status = 'Status',
}

export interface AssetProjectModel {
  __typename: 'AssetProjectModel';
  configuration: Scalars['JSON'];
  id: Scalars['ID'];
  projectModelId: Scalars['ID'];
}

export interface AssetWhere {
  assigneeIn?: InputMaybe<Array<Scalars['String']>>;
  assigneeNotIn?: InputMaybe<Array<Scalars['String']>>;
  consensusMarkGte?: InputMaybe<Scalars['Float']>;
  consensusMarkLte?: InputMaybe<Scalars['Float']>;
  createdAtGte?: InputMaybe<Scalars['String']>;
  createdAtLte?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalIdIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  externalIdStrictlyIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasJsonContent?: InputMaybe<Scalars['Boolean']>;
  honeypotMarkGte?: InputMaybe<Scalars['Float']>;
  honeypotMarkLte?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  idNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inferenceMarkGte?: InputMaybe<Scalars['Float']>;
  inferenceMarkLte?: InputMaybe<Scalars['Float']>;
  isHoneypot?: InputMaybe<Scalars['Boolean']>;
  issue?: InputMaybe<IssueWhere>;
  label?: InputMaybe<LabelWhere>;
  latestLabel?: InputMaybe<LabelWhere>;
  metadata?: InputMaybe<Scalars['JSON']>;
  metadataNot?: InputMaybe<Scalars['JSON']>;
  priorityGte?: InputMaybe<Scalars['Int']>;
  priorityLte?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<ProjectWhere>;
  skipped?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Scalars['String']>;
  statusIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedAtGte?: InputMaybe<Scalars['String']>;
  updatedAtLte?: InputMaybe<Scalars['String']>;
}

export interface AssetWorkflowStep {
  __typename: 'AssetWorkflowStep';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: AssetWorkflowStepStatus;
  type: ProjectWorkflowStepType;
}

export enum AssetWorkflowStepStatus {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  PARTIALLY_DONE = 'PARTIALLY_DONE',
  REWORK = 'REWORK',
  SKIPPED = 'SKIPPED',
  TO_DO = 'TO_DO',
}

/** The authentification object to create a token for a user. */
export interface AuthPayload {
  __typename: 'AuthPayload';
  _: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  token: Scalars['String'];
  user: User;
}

export interface AvailablePathWhere {
  currentTree?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  dataIntegrationId: Scalars['ID'];
  root: Scalars['String'];
}

export interface AverageQualityMetric extends CategoryMetric {
  __typename: 'AverageQualityMetric';
  categoryKey: Scalars['String'];
  date: Scalars['DateTime'];
  elementsCount: Maybe<Scalars['Int']>;
  jobKey: Scalars['String'];
  /** Quality parameter considered */
  type: Quality;
  /** Average value for given category */
  value: Maybe<Scalars['Float']>;
}

export interface CategoryMetric {
  categoryKey: Scalars['String'];
  date: Scalars['DateTime'];
  jobKey: Scalars['String'];
}

export enum CategoryMetricInput {
  Consensus = 'Consensus',
  NumberOfAnnotations = 'NumberOfAnnotations',
  NumberOfAssets = 'NumberOfAssets',
  NumberOfPredictedAnnotations = 'NumberOfPredictedAnnotations',
  NumberOfPredictedAssets = 'NumberOfPredictedAssets',
}

/** Related to LLM project */
export interface ChatItem {
  __typename: 'ChatItem';
  _: Maybe<Scalars['Boolean']>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  labelId: Scalars['ID'];
  modelId: Maybe<Scalars['ID']>;
  parentId: Maybe<Scalars['String']>;
  role: ChatItemRole;
}

export interface ChatItemKeyValue {
  __typename: 'ChatItemKeyValue';
  newChatItemId: Scalars['String'];
  previousChatItemId: Scalars['String'];
}

export enum ChatItemRole {
  ASSISTANT = 'ASSISTANT',
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export interface ChatItemWhere {
  id?: InputMaybe<Scalars['ID']>;
}

export interface ChatItemsWhere {
  assetId: Scalars['ID'];
  labelId: Scalars['ID'];
}

export interface CheckDataIntegrationResult {
  __typename: 'CheckDataIntegrationResult';
  error: Maybe<Scalars['String']>;
  isConnectionOk: Scalars['Boolean'];
}

/** CheckedTag are used to link a tag to a project. */
export interface CheckedTag {
  __typename: 'CheckedTag';
  projectId: Scalars['ID'];
  tagId: Scalars['ID'];
}

export interface CheckedTagData {
  projectId: Scalars['ID'];
  tagId: Scalars['ID'];
}

export interface CheckedTagWhere {
  projectId?: InputMaybe<Scalars['ID']>;
  tagId?: InputMaybe<Scalars['ID']>;
}

export interface ClassificationAnnotation extends Annotation {
  __typename: 'ClassificationAnnotation';
  annotationValue: Maybe<ClassificationAnnotationValue>;
  chatItemId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  job: Scalars['String'];
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface ClassificationAnnotationValue extends AnnotationValue {
  __typename: 'ClassificationAnnotationValue';
  categories: Array<Scalars['String']>;
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
}

export interface CleanPreviousReportsPayload {
  /** Project identifier */
  projectId: Scalars['ID'];
  /** Prefix filter on files */
  reportFileNamePrefix?: InputMaybe<Scalars['String']>;
}

/** Comment contains comments associated to an issue. */
export interface Comment {
  __typename: 'Comment';
  /** ProjectUser that created this comment. */
  author: ProjectUser;
  authorId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** ID of the issued this comment is attached to. */
  issue: Issue;
  issueId: Scalars['ID'];
  /** Content of the comment. */
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export interface CommentData {
  text: Scalars['String'];
}

export interface CommentWhere {
  author?: InputMaybe<UserWhere>;
  id?: InputMaybe<Scalars['ID']>;
  issue?: InputMaybe<IssueWhere>;
}

export interface ComparisonAnnotation extends Annotation {
  __typename: 'ComparisonAnnotation';
  annotationValue: Maybe<ComparisonAnnotationValue>;
  chatItemId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  job: Scalars['String'];
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface ComparisonAnnotationValue extends AnnotationValue {
  __typename: 'ComparisonAnnotationValue';
  choice: ComparisonValue;
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
}

export interface ComparisonValue {
  __typename: 'ComparisonValue';
  code: Scalars['String'];
  firstId: Scalars['String'];
  secondId: Scalars['String'];
}

export interface ComparisonValueInput {
  code: Scalars['String'];
  firstId: Scalars['String'];
  secondId: Scalars['String'];
}

export enum ComplianceTag {
  PHI = 'PHI',
  PII = 'PII',
}

export interface CreateChatItemData {
  content: Scalars['String'];
  labelId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
  role: ChatItemRole;
}

export interface CreateHoneypotData {
  jsonResponse: Scalars['String'];
}

export interface CreateLLMAssetData {
  authorId: Scalars['ID'];
  labelType?: InputMaybe<LabelType>;
  status?: InputMaybe<Status>;
}

export interface CreateModelInput {
  /** Credentials that allow to connect to the model */
  credentials: Scalars['JSON'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  type: ModelType;
}

export interface CreateOrganizationData {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  license?: InputMaybe<Scalars['String']>;
  matchingDomain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  zipCode: Scalars['String'];
}

export interface CreatePluginData {
  pluginName: Scalars['String'];
}

export interface CreatePluginRunnerData {
  /** Actions for which the plugin should be executed */
  handlerTypes?: InputMaybe<Array<PluginHandlerType>>;
  pluginName: Scalars['String'];
}

export interface CreatePredictionsData {
  jsonResponseArray: Array<Scalars['String']>;
  modelNameArray: Array<Scalars['String']>;
}

export interface CreateProjectData {
  complianceTags?: InputMaybe<Array<InputMaybe<ComplianceTag>>>;
  description: Scalars['String'];
  inputType: InputType;
  jsonInterface: Scalars['String'];
  projectType?: InputMaybe<ProjectType>;
  title: Scalars['String'];
}

export interface CreateProjectModelInput {
  configuration: Scalars['JSON'];
  modelId: Scalars['ID'];
  projectId: Scalars['ID'];
}

export interface CreateProjectWorkflowStepInput {
  assignees: Array<Scalars['ID']>;
  consensusCoverage?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfExpectedLabelsForConsensus?: InputMaybe<Scalars['Int']>;
  order: Scalars['Int'];
  stepCoverage?: InputMaybe<Scalars['Int']>;
  type: ProjectWorkflowStepType;
}

export interface CreateUserData {
  email: Scalars['String'];
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  organizationRole: OrganizationRole;
  password?: InputMaybe<Scalars['String']>;
}

export interface CreateWebhookData {
  /** Actions for which the webhook should be called */
  handlerTypes?: InputMaybe<Array<PluginHandlerType>>;
  /** Security Authorization header for calls made to the webhook */
  header?: InputMaybe<Scalars['String']>;
  pluginName: Scalars['String'];
  /** Url of the webhook */
  webhookUrl: Scalars['String'];
}

/** DataConnection is related with specific project. */
export interface DataConnection {
  __typename: 'DataConnection';
  _: Maybe<Scalars['Boolean']>;
  /** Data differences for specific data connection */
  dataDifferencesSummary: Maybe<DataDifferencesSummary>;
  /** Integration to use to connect to the bucket */
  dataIntegration: DataIntegration;
  /** Integration id to use to connect to the bucket */
  dataIntegrationId: Scalars['ID'];
  /** Exclude filter on assets */
  exclude: Maybe<Array<Scalars['String']>>;
  /** Unique id */
  id: Scalars['ID'];
  /** Include filter on assets */
  include: Maybe<Array<Scalars['String']>>;
  /** Applying data differences to the project */
  isApplyingDataDifferences: Scalars['Boolean'];
  /** Checking status, i.e. is computing differences between the project assets and the bucket objects. */
  isChecking: Scalars['Boolean'];
  /** Last time the project data connection was checked */
  lastCheck: Maybe<Scalars['DateTime']>;
  /** @deprecated Use nullable lastCheck field instead */
  lastChecked: Scalars['DateTime'];
  /** Number of assets in connection */
  numberOfAssets: Scalars['Int'];
  /** Prefix filter on assets */
  prefix: Maybe<Scalars['String']>;
  /** Project identifier of the data connection */
  project: Maybe<Project>;
  /** Project identifier of the data connection */
  projectId: Scalars['ID'];
  /** List of selected folders in scope of connection */
  selectedFolders: Maybe<Array<Scalars['String']>>;
}

export interface DataConnectionComputeDifferencesPayload {
  /** Paths of the files listed in the bucket sent for synchronization */
  blobPaths: Array<Scalars['String']>;
  contentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  warnings?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface DataConnectionIdWhere {
  id: Scalars['ID'];
}

export interface DataConnectionInput {
  /** Exclude filter on assets */
  exclude?: InputMaybe<Array<Scalars['String']>>;
  /** Unique id */
  id?: InputMaybe<Scalars['ID']>;
  /** Include filter on assets */
  include?: InputMaybe<Array<Scalars['String']>>;
  /** Integration identifier */
  integrationId: Scalars['ID'];
  /** Prefix filter on assets */
  prefix?: InputMaybe<Scalars['String']>;
  /** Project identifier */
  projectId: Scalars['ID'];
  /** List of selected folders in scope of connection */
  selectedFolders?: InputMaybe<Array<Scalars['String']>>;
}

export interface DataConnectionsWhere {
  integrationId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
}

/** DataDifference belong to a DataConnection. They are used to sync the diff */
export interface DataDifference {
  __typename: 'DataDifference';
  _: Maybe<Scalars['Boolean']>;
  /** The key to access the asset on the bucket */
  bucketKey: Maybe<Scalars['String']>;
  /** Id of the dataConnection where the difference belong to */
  connectionId: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  /** The id of the asset */
  dataAssetId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** The type of the difference */
  type: DataDifferenceType;
  updatedAt: Scalars['DateTime'];
}

export interface DataDifferenceData {
  bucketKey?: InputMaybe<Scalars['String']>;
  connectionId?: InputMaybe<Scalars['ID']>;
  dataAssetId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DataDifferenceType>;
}

export enum DataDifferenceType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export interface DataDifferenceWhere {
  connectionId: Scalars['ID'];
  type?: InputMaybe<DataDifferenceType>;
}

export interface DataDifferencesSummary {
  __typename: 'DataDifferencesSummary';
  added: Scalars['Int'];
  error: Maybe<Scalars['String']>;
  removed: Scalars['Int'];
  total: Scalars['Int'];
  warnings: Maybe<Array<Scalars['String']>>;
}

/** DataIntegration belong to an organization. They link the project data and an external bucket */
export interface DataIntegration {
  __typename: 'DataIntegration';
  _: Maybe<Scalars['Boolean']>;
  /** The list of allowed paths for the dataIntegration null if all paths are allowed */
  allowedPaths: Maybe<Array<Scalars['String']>>;
  /** The list of allowed Projects for the dataIntegration to be available null or empty array if allowed for all projects */
  allowedProjects: Maybe<Array<Scalars['String']>>;
  /** The user who created the integration */
  author: Maybe<User>;
  /** Access Point for AWS ARS (Amazon Resource Name) */
  awsAccessPointARN: Maybe<Scalars['String']>;
  /** Roles for Amazon Resource Name */
  awsRoleARN: Maybe<Scalars['String']>;
  /** External Id used for restricting role access */
  awsRoleExternalId: Maybe<Scalars['String']>;
  /** Url of the azure connection bucket */
  azureConnectionURL: Maybe<Scalars['String']>;
  /** If using an azure integration, defines the type of connection */
  azureIsUsingServiceCredentials: Maybe<Scalars['Boolean']>;
  /** If connecting via a token to an azure bucket, its service SAS token */
  azureSASToken: Maybe<Scalars['String']>;
  /** Id for the Azure bucket integration */
  azureTenantId: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Name of the GCP bucket */
  gcpBucketName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The integration include the root files of the bucket */
  includeRootFiles: Maybe<Scalars['Boolean']>;
  /** Kili is allowed to process internally the assets of the integration */
  internalProcessingAuthorized: Maybe<Scalars['Boolean']>;
  /** Last time the integration's data was checked */
  lastCheck: Scalars['DateTime'];
  /** The name of the integration */
  name: Scalars['String'];
  /** Id of the organization where the integration belong to */
  organizationId: Maybe<Scalars['ID']>;
  /** Third party plateform used by the integration to share data */
  platform: DataIntegrationPlatform;
  /** Projects where the integration is used via a data connection */
  projects: Maybe<Array<Project>>;
  /** Key for s3 custom bucket. */
  s3AccessKey: Maybe<Scalars['String']>;
  /** Name of the s3 custom bucket. */
  s3BucketName: Maybe<Scalars['String']>;
  /** Endpoint of the s3 custom bucket. */
  s3Endpoint: Maybe<Scalars['String']>;
  /** Region of the s3 custom bucket. */
  s3Region: Maybe<Scalars['String']>;
  /** Secret key for s3 custom bucket. */
  s3SecretKey: Maybe<Scalars['String']>;
  /** Session token for s3 custom bucket. */
  s3SessionToken: Maybe<Scalars['String']>;
  /** Status of the integration. */
  status: DataIntegrationStatus;
  updatedAt: Scalars['DateTime'];
}

export interface DataIntegrationAllowedProjectsData {
  dataIntegrationId: Scalars['ID'];
  projectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
}

export interface DataIntegrationData {
  allowedPaths?: InputMaybe<Array<Scalars['String']>>;
  allowedProjects?: InputMaybe<Array<Scalars['String']>>;
  authorId?: InputMaybe<Scalars['String']>;
  awsAccessPointARN?: InputMaybe<Scalars['String']>;
  awsRoleARN?: InputMaybe<Scalars['String']>;
  awsRoleExternalId?: InputMaybe<Scalars['String']>;
  azureConnectionURL?: InputMaybe<Scalars['String']>;
  azureIsUsingServiceCredentials?: InputMaybe<Scalars['Boolean']>;
  azureSASToken?: InputMaybe<Scalars['String']>;
  azureTenantId?: InputMaybe<Scalars['String']>;
  gcpBucketName?: InputMaybe<Scalars['String']>;
  includeRootFiles?: InputMaybe<Scalars['Boolean']>;
  internalProcessingAuthorized?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  platform?: InputMaybe<DataIntegrationPlatform>;
  s3AccessKey?: InputMaybe<Scalars['String']>;
  s3BucketName?: InputMaybe<Scalars['String']>;
  s3Endpoint?: InputMaybe<Scalars['String']>;
  s3Region?: InputMaybe<Scalars['String']>;
  s3SecretKey?: InputMaybe<Scalars['String']>;
  s3SessionToken?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DataIntegrationStatus>;
}

export enum DataIntegrationPlatform {
  AWS = 'AWS',
  Azure = 'Azure',
  CustomS3 = 'CustomS3',
  GCP = 'GCP',
}

export enum DataIntegrationStatus {
  CHECKING = 'CHECKING',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export interface DataIntegrationUpdatedSubscriptionData {
  __typename: 'DataIntegrationUpdatedSubscriptionData';
  dataIntegration: Maybe<DataIntegration>;
  error: Maybe<Scalars['String']>;
}

export interface DataIntegrationWhere {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<DataIntegrationPlatform>;
  projectId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<DataIntegrationStatus>;
}

export interface DeclineAdmissionRequest {
  id: Scalars['ID'];
}

export interface DeleteLocksInput {
  assetId: Scalars['ID'];
  projectId: Scalars['ID'];
}

export interface DeletePluginWhere {
  pluginName: Scalars['String'];
}

export interface DuplicateLlmLabelData {
  id: Scalars['ID'];
}

export interface DuplicatedLlmLabelAndMapping {
  __typename: 'DuplicatedLlmLabelAndMapping';
  mapping: Array<ChatItemKeyValue>;
  newLabelId: Scalars['ID'];
}

export interface EditProjectWorkflowSteps {
  creates?: InputMaybe<Array<CreateProjectWorkflowStepInput>>;
  deletes?: InputMaybe<Array<Scalars['ID']>>;
  updates?: InputMaybe<Array<UpdateProjectWorkflowStepInput>>;
}

export interface EditProjectWorkflowStepsInput {
  projectId: Scalars['ID'];
  steps: EditProjectWorkflowSteps;
}

export enum ExportType {
  LATEST = 'LATEST',
  NORMAL = 'NORMAL',
}

export interface FrameInterval {
  __typename: 'FrameInterval';
  end: Scalars['Int'];
  start: Scalars['Int'];
}

export interface FrameIntervalInput {
  end: Scalars['Int'];
  start: Scalars['Int'];
}

export interface GenerateApiKeyData {
  name: Scalars['String'];
}

export interface GenerateUpdateUrlData {
  pluginName: Scalars['String'];
}

export interface GetPluginBuildErrorsData {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  pluginName: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
}

export interface GetPluginLogsData {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  pluginName: Scalars['String'];
  projectId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
}

export interface GetUsageOverTimeInput {
  endDate: Scalars['DateTime'];
  granularity: OrganizationUsageMetricsGranularity;
  metrics?: InputMaybe<Array<OrganizationMetricInput>>;
  startDate: Scalars['DateTime'];
}

export enum Granularity {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export enum HighestRoleInProject {
  ADMIN = 'ADMIN',
  LABELER = 'LABELER',
  NA = 'NA',
  REVIEWER = 'REVIEWER',
  TEAM_MANAGER = 'TEAM_MANAGER',
}

export enum HubspotSubscriptionStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum InputType {
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  LLM_INSTR_FOLLOWING = 'LLM_INSTR_FOLLOWING',
  LLM_RLHF = 'LLM_RLHF',
  PDF = 'PDF',
  TEXT = 'TEXT',
  TIME_SERIES = 'TIME_SERIES',
  VIDEO = 'VIDEO',
  VIDEO_LEGACY = 'VIDEO_LEGACY',
}

/** Issue contains issues associated to a User and an Asset. */
export interface Issue {
  __typename: 'Issue';
  /** ID of the asset related to the issue. */
  asset: Maybe<Asset>;
  assetId: Scalars['ID'];
  /** ID of the projectUser assigned to the issue. */
  assignee: Maybe<ProjectUser>;
  assigneeId: Maybe<Scalars['ID']>;
  /** ID of the projectUser author of the issue. */
  author: Maybe<ProjectUser>;
  authorId: Scalars['ID'];
  /** All the comments related to this issue */
  comments: Maybe<Array<Comment>>;
  createdAt: Scalars['DateTime'];
  /**
   * Whether the last activity on the issue has been seen by the author of the issue.
   * @deprecated This field is not used anymore. It will be soon removed.
   */
  hasBeenSeen: Scalars['Boolean'];
  id: Scalars['ID'];
  /**
   * Numeric ID of the issue
   * @deprecated This field is not used anymore. It will be soon removed.
   */
  issueNumber: Scalars['Int'];
  /** Object MID of the issue */
  objectMid: Maybe<Scalars['String']>;
  /** ID of the project of the issue. */
  project: Maybe<Project>;
  projectId: Scalars['ID'];
  /** Current annotation status of the issue. Can take the values: OPENED, SOLVED. */
  status: IssueStatus;
  type: IssueType;
  updatedAt: Scalars['DateTime'];
}

export interface IssueData {
  assigneeId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}

export enum IssueStatus {
  OPEN = 'OPEN',
  SOLVED = 'SOLVED',
}

export interface IssueToCreate {
  assetId: Scalars['String'];
  issueNumber: Scalars['Int'];
  labelID?: InputMaybe<Scalars['ID']>;
  objectMid?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: IssueType;
}

export enum IssueType {
  ISSUE = 'ISSUE',
  QUESTION = 'QUESTION',
}

export interface IssueWhere {
  asset?: InputMaybe<AssetWhere>;
  assetIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  assignee?: InputMaybe<ProjectUserWhere>;
  assigneeIdsIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  author?: InputMaybe<ProjectUserWhere>;
  id?: InputMaybe<Scalars['ID']>;
  project?: InputMaybe<ProjectWhere>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IssueType>;
}

export interface LLMChatItemPartialContentReceivedSubscriptionData {
  __typename: 'LLMChatItemPartialContentReceivedSubscriptionData';
  assetId: Scalars['ID'];
  /** ID of the chat item the partial content was received for. */
  chatItemId: Scalars['ID'];
  labelId: Scalars['ID'];
  /** Partial response content received in the corresponding streaming chunk. */
  partialContent: Scalars['String'];
}

export interface LLMTemporaryChatItemCreatedSubscriptionData {
  __typename: 'LLMTemporaryChatItemCreatedSubscriptionData';
  assetId: Scalars['ID'];
  /** Temporary chat item created to display a streamed response. */
  chatItem: ChatItem;
}

/** A label include all information resulting of the action of annotation by a specific user. */
export interface Label {
  __typename: 'Label';
  _: Maybe<Scalars['Boolean']>;
  annotations: Array<Annotation>;
  assetId: Scalars['String'];
  /** User that created this label. */
  author: User;
  authorId: Scalars['String'];
  /**
   * Technical field.
   * @deprecated This field is not used anymore. It will be soon removed.
   */
  authorIdCompute: Maybe<Scalars['String']>;
  chatItems: Array<ChatItem>;
  /**
   * Version of the label given by the client. It differs from the version field because for each new save, either the client gives the version number or it is at 0 by default.
   *
   * This is used to check order of autosave.
   */
  clientVersion: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  /**
   * Honeypot is a quality parameter to measure the agreement between a pre-annotated asset (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * In the case of Honeypot Asset, chord note expressed in percentage between the label and the target. In the case of an Asset that is not of Honeypot type, the field is null.
   */
  honeypotMark: Maybe<Scalars['Float']>;
  /** Technical field. */
  honeypotMarkCompute: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** Inference mark is a quality parameter to measure the agreement between an inference run (from a model) and the annotation made by an annotator. */
  inferenceMark: Maybe<Scalars['Float']>;
  /** Technical field. */
  inferenceMarkCompute: Maybe<Scalars['Float']>;
  /** Boolean flagging whether the label is used as the base for computing the honeypot mark of the other labels */
  isHoneypot: Scalars['Boolean'];
  /** Boolean flagging whether the label is the latest submitted DEFAULT label for this asset for the user. */
  isLatestDefaultLabelForUser: Scalars['Boolean'];
  /** Technical field. */
  isLatestDefaultLabelForUserCompute: Maybe<Scalars['Boolean']>;
  /** Boolean flagging whether the label is the latest submitted label for this asset for the user */
  isLatestLabelForUser: Scalars['Boolean'];
  /** Technical field. */
  isLatestLabelForUserCompute: Maybe<Scalars['Boolean']>;
  /** Boolean flagging whether the label is the latest submitted PREDICTION label for this asset for the user. */
  isLatestPredictionLabelForUser: Scalars['Boolean'];
  /** Technical field. */
  isLatestPredictionLabelForUserCompute: Maybe<Scalars['Boolean']>;
  /** Boolean flagging whether the label is the latest submitted REVIEW label for this asset for the user. */
  isLatestReviewLabelForUser: Scalars['Boolean'];
  /** Technical field. */
  isLatestReviewLabelForUserCompute: Maybe<Scalars['Boolean']>;
  /** Technical field. */
  isSentBackToQueue: Maybe<Scalars['Boolean']>;
  /** The list of all annotations of this label, for each task of this project, in a JSON character string. Kili annotations closely follow the Google format. */
  jsonResponse: Scalars['String'];
  /** Asset related to this label. */
  labelOf: Maybe<Asset>;
  /**
   * Type of label used to identify if the label is a prediction (made by a model), an ordinary label (made by an annotator) or a journal (carried out by a reviewer).
   *
   * Can take the following values:
   * DEFAULT
   * INFERENCE
   * PREDICTION
   * REVIEW
   */
  labelType: LabelType;
  /** In case the label was uploaded (and is of type PREDICTION), name (chosen during the upload) of the model used to create the label. */
  modelName: Maybe<Scalars['String']>;
  /** Total number of annotation elements on the asset. */
  numberOfAnnotations: Maybe<Scalars['Int']>;
  /** Technical field. */
  numberOfAnnotationsCompute: Maybe<Scalars['Int']>;
  projectId: Scalars['String'];
  /** Technical field. */
  projectIdCompute: Maybe<Scalars['String']>;
  /** The list of all annotations of this label, for each task of this project, in a JSON field. Kili annotations closely follow the Google format. */
  response: Scalars['JSON'];
  /** Technical field. */
  responseCompute: Maybe<Scalars['JSON']>;
  /** Quality parameter to measure agreement score between labelers and reviewers computed on reviewed assets. */
  reviewScore: Maybe<Scalars['Float']>;
  /** Technical field. */
  search: Scalars['JSON'];
  /** Technical field. */
  searchCompute: Maybe<Scalars['JSON']>;
  /** Amount of time (in seconds) spent by the labeller on the interface before submitting the label. */
  secondsToLabel: Maybe<Scalars['Float']>;
  /** Total cumulated amount of time (in seconds) spent by the user on all other labels of the same asset on the interface. */
  totalSecondsToLabel: Maybe<Scalars['Int']>;
  /** Technical field. */
  totalSecondsToLabelCompute: Maybe<Scalars['Int']>;
  /** Version of the label. */
  version: Scalars['Int'];
}

export interface LabelData {
  isSentBackToQueue?: InputMaybe<Scalars['Boolean']>;
  jsonResponse?: InputMaybe<Scalars['String']>;
  modelName?: InputMaybe<Scalars['String']>;
  secondsToLabel?: InputMaybe<Scalars['Float']>;
}

export enum LabelFormat {
  LLM = 'LLM',
  PASCAL_VOC = 'PASCAL_VOC',
  RAW = 'RAW',
  /** @deprecated Removed from the application */
  SIMPLE = 'SIMPLE',
  YOLO_V4 = 'YOLO_V4',
  YOLO_V5 = 'YOLO_V5',
}

export enum LabelType {
  AUTOSAVE = 'AUTOSAVE',
  DEFAULT = 'DEFAULT',
  INFERENCE = 'INFERENCE',
  PREDICTION = 'PREDICTION',
  REVIEW = 'REVIEW',
}

export interface LabelWhere {
  asset?: InputMaybe<AssetWhere>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  consensusMarkGte?: InputMaybe<Scalars['Float']>;
  consensusMarkLte?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['String']>;
  createdAtGte?: InputMaybe<Scalars['String']>;
  createdAtLte?: InputMaybe<Scalars['String']>;
  honeypotMarkGte?: InputMaybe<Scalars['Float']>;
  honeypotMarkLte?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  labelerIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  labelerNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  project?: InputMaybe<ProjectWhere>;
  reviewScoreGte?: InputMaybe<Scalars['Float']>;
  reviewScoreLte?: InputMaybe<Scalars['Float']>;
  reviewerIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reviewerNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  transcriptionSearch?: InputMaybe<Scalars['String']>;
  typeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user?: InputMaybe<UserWhere>;
}

export enum LabelerAction {
  Labeling = 'Labeling',
  Reviewing = 'Reviewing',
}

export interface LabelerMetric {
  date: Scalars['DateTime'];
  userId: Scalars['ID'];
}

export enum LabelerMetricInput {
  Annotations = 'Annotations',
  Consensus = 'Consensus',
  Honeypot = 'Honeypot',
  LabeledAssets = 'LabeledAssets',
  LabelingTime = 'LabelingTime',
  PairedReviewScore = 'PairedReviewScore',
  ReviewedAssets = 'ReviewedAssets',
  ReviewingTime = 'ReviewingTime',
}

export interface License {
  __typename: 'License';
  _: Maybe<Scalars['Boolean']>;
  api: Maybe<Scalars['Boolean']>;
  apiPriority: Maybe<Scalars['Boolean']>;
  canUsePlugins: Maybe<Scalars['Boolean']>;
  contractedLabelingHours: Maybe<Scalars['Int']>;
  enableSmartTools: Maybe<Scalars['Boolean']>;
  expiryDate: Maybe<Scalars['String']>;
  inputType: Maybe<Array<Scalars['String']>>;
  maxNumberOfAnnotations: Maybe<Scalars['Int']>;
  maxNumberOfAnnotationsPerMonth: Maybe<Scalars['Int']>;
  maxNumberOfLabeledAssets: Maybe<Scalars['Int']>;
  /** @deprecated This is not part of the billing */
  maxNumberOfLabeledAssetsPerMonth: Maybe<Scalars['Int']>;
  maxNumberOfPredictionsPerMonth: Maybe<Scalars['Int']>;
  organizationId: Maybe<Scalars['String']>;
  planType: Maybe<Scalars['String']>;
  seats: Maybe<Scalars['Int']>;
  startDate: Maybe<Scalars['String']>;
  type: Maybe<LicenseType>;
  uploadCloudData: Maybe<Scalars['Boolean']>;
  uploadLocalData: Maybe<Scalars['Boolean']>;
}

export enum LicenseType {
  COMMUNITY = 'COMMUNITY',
  /** @deprecated COMMUNITY is the new free version of the application */
  DISCOVERY = 'DISCOVERY',
  /** @deprecated COMMUNITY is the new free version of the application */
  FREEMIUM = 'FREEMIUM',
  PAID = 'PAID',
}

export enum LockRejectReason {
  AlreadyAssigned = 'AlreadyAssigned',
  AlreadyLocked = 'AlreadyLocked',
  NotInStepAssignees = 'NotInStepAssignees',
}

export enum LogType {
  debug = 'debug',
  error = 'error',
  info = 'info',
  warn = 'warn',
}

export interface MembersAddedAt {
  __typename: 'MembersAddedAt';
  /** Date ISO 8601 representing a month or a day, depending on granularity */
  date: Scalars['String'];
  /** Number of members added at the provided date */
  value: Scalars['Int'];
}

export interface MembersAddedOnPeriod {
  __typename: 'MembersAddedOnPeriod';
  /** Period on which metrics are aggregated */
  period: Period;
  /** Number of members added at the provided date */
  value: Scalars['Int'];
}

export interface MembersAddedOverTime {
  __typename: 'MembersAddedOverTime';
  details: Array<MembersAddedAt>;
  granularity: OrganizationUsageMetricsGranularity;
  total: MembersAddedOnPeriod;
}

export interface Model {
  __typename: 'Model';
  createdAt: Scalars['DateTime'];
  /** Credentials without secrets */
  credentials: Scalars['JSON'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  projectModels: Array<ProjectModel>;
  type: ModelType;
  updatedAt: Scalars['DateTime'];
}

export interface ModelprojectModelsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
}

export enum ModelType {
  AZURE_OPEN_AI = 'AZURE_OPEN_AI',
  COHERE = 'COHERE',
  OPEN_AI_SDK = 'OPEN_AI_SDK',
}

export interface ModelWhere {
  organizationId: Scalars['ID'];
}

export interface MultiLayerContent {
  isBaseLayer?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
}

export interface Mutation {
  __typename: 'Mutation';
  _: Maybe<Scalars['Boolean']>;
  /** An admin of the organization can accept the user's admission request */
  acceptAdmissionRequest: Maybe<Scalars['Boolean']>;
  /** Accept/decline an invitation to joining a new project. */
  acceptOrDeclineInvitation: Maybe<ProjectUser>;
  /** Activate a plugin on a project */
  activatePluginOnProject: Scalars['String'];
  /** Add all labeled assets specified, or if None, all in the project to the review queue */
  addAllLabeledAssetsToReview: Maybe<Project>;
  /** Add project data connection */
  addDataConnection: DataConnection;
  /** Annotate an LLM Asset */
  annotateLLMAsset: Scalars['ID'];
  /** [Beta] Pre-Annotate assets with Kili Copilot */
  annotateWithCopilot: Maybe<Scalars['String']>;
  /** Permanently delete an organization and all its relative data */
  anonymizeOrganization: Maybe<Organization>;
  /**
   * Add one or more assets to the project specified.
   *
   * You may specify for each asset its externalID, if it should be considered as honeypot, and some metadata.
   */
  appendManyAssets: Maybe<Array<Asset>>;
  /**
   * Add one or more assets asynchronously to the project specified.
   *
   * You may specify for each asset its externalID, if it should be considered as honeypot, its status and some metadata.
   */
  appendManyFramesToDatasetAsynchronously: Maybe<Notification>;
  /** Add several new annotations */
  appendManyLabels: Maybe<Array<Label>>;
  /**
   * Add one or more assets to the project specified.
   *
   * You may specify for each asset its externalID, if it should be considered as honeypot, its status and some metadata.
   * @deprecated This resolver is deprecated, please use appendManyAssets.
   */
  appendManyToDataset: Maybe<Project>;
  /**
   * Add one or more assets to the project specified.
   *
   * You may specify for each asset its externalID, if it should be considered as honeypot, its status and some metadata.
   */
  appendManyToDatasetFrontend: Maybe<UploadAssetsWarnings>;
  /** Add a new comment */
  appendToComments: Maybe<Comment>;
  /** Add a new issue */
  appendToIssues: Maybe<Issue>;
  /** Add a new annotation */
  appendToLabels: Maybe<Label>;
  /** Add a new user in the specified project. */
  appendToRoles: Maybe<Project>;
  /**
   * Apply a list of modification to multiple annotations.
   * If labelId is not providen, creates a new label.
   */
  applyChangesToAnnotations: Scalars['ID'];
  applyChangesToLabel: Maybe<Label>;
  /** Assign assets to be labeled by given users */
  assignAssetsToLabelers: Maybe<Project>;
  /** Change the author of a project */
  changeProjectAuthor: Maybe<Project>;
  /** Add a checked tag */
  checkTag: Maybe<Tag>;
  /** Clean previous synchronization reports for project */
  cleanPreviousReports: Scalars['Boolean'];
  /**
   * Clear split annotations for a given label id.
   * Can only be called by a kili admin.
   */
  clearAnnotations: Scalars['Boolean'];
  /** Computes differences between the project assets and the bucket objects */
  computeDifferences: DataConnection;
  /** Create a new Chat Item */
  createChatItem: Array<ChatItem>;
  /** Create a DataIntegration and return the dataIntegration */
  createDataIntegration: Maybe<DataIntegration>;
  /**
   * Honeypot is a quality parameter to measure the agreement between aasset pre-annotated (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * Create a honeypot for an asset, specifying all anotations for this asset.
   */
  createHoneypot: Maybe<Label>;
  /** Add several new issues */
  createIssues: Maybe<Array<Issue>>;
  /** Create a new LLM Asset */
  createLLMAsset: Asset;
  createModel: Model;
  /** Create a new notification. */
  createNotification: Maybe<Notification>;
  /** Create a new organization */
  createOrganization: Maybe<Organization>;
  /** Create a plugin in DB and return an upload url */
  createPlugin: Scalars['String'];
  /** Create the plugin's runner */
  createPluginRunner: Scalars['String'];
  /** Upload labels, considered as predictions by a model, aiming at speeding annotation by helping the labeler. */
  createPredictions: Maybe<Project>;
  /** Create a new project */
  createProject: Maybe<Project>;
  createProjectModel: ProjectModel;
  /** Add a new tag */
  createTag: Maybe<Tag>;
  /**
   * Create a user if he does not exist or add him back to the organization if he already existed
   * Also allows you to update his organizationRole
   */
  createUser: Maybe<User>;
  /** Create a webhook in DB */
  createWebhook: Scalars['String'];
  /**
   * Tests a DataIntegration and updates the data integration with its new status.
   * You can access potential errors with the subscription dataIntegrationUpdated
   */
  dataIntegrationTest: Maybe<DataIntegration>;
  /** Deactivates a plugin on a project */
  deactivatePluginOnProject: Scalars['String'];
  /** An admin of the organization can decline the user's admission request */
  declineAdmissionRequest: Maybe<Scalars['Boolean']>;
  /** Delete a Chat item */
  deleteChatItem: Scalars['ID'];
  /** Permanently delete a DataIntegration */
  deleteDataIntegration: Maybe<Scalars['String']>;
  /** Delete a projectUser from the project. */
  deleteFromRoles: Maybe<Project>;
  /** Delete INFERENCE and PREDICTION labels. Other label types cannot be deleted. */
  deleteLabels: Array<Scalars['ID']>;
  /** Delete all locks of the user on a certain asset. */
  deleteLocks: Scalars['ID'];
  /** Delete one or more assets to the project specified, provided their IDs or the project ID */
  deleteManyFromDataset: Maybe<Project>;
  deleteModel: Scalars['Boolean'];
  /** Delete a plugin and deactivate it on all associated projects */
  deletePlugin: Scalars['String'];
  /** Permanently delete a project */
  deleteProject: Maybe<Project>;
  /** Permanently delete a project's assets */
  deleteProjectAsynchronously: Scalars['String'];
  deleteProjectModel: Scalars['Boolean'];
  /**
   * Deletes the given tag.
   *
   * This operation is organization-wide. The tag will no longer be proposed for projects of the organization.
   * If this tag is checked for one or more projects of the organization, it will be unchecked.
   */
  deleteTag: Maybe<Scalars['Boolean']>;
  /** Duplicate an LLM Label */
  duplicateLlmLabel: DuplicatedLlmLabelAndMapping;
  /** Update the project's workflow steps. */
  editProjectWorkflowSteps: Maybe<Array<ProjectWorkflowStep>>;
  /** Export performance data from project. */
  exportPerformanceData: Scalars['String'];
  /** Add a new annotation from the app */
  frontendAppendToLabels: Maybe<Label>;
  /** Add a new projectUser in the specified project and send an invite. */
  frontendAppendToRoles: Maybe<Project>;
  /** Generate an api key to a user */
  generateApiKey: Maybe<ApiKey>;
  /** Generate a new url to upload src */
  generateUpdateUrl: Scalars['String'];
  /** Permanently hard delete a project */
  hardDeleteProject: Maybe<Project>;
  /** Prioritize assets to specified priority */
  prioritizeAssets: Maybe<Project>;
  /** Regenerate assistant chat items */
  regenerateChatItems: Array<ChatItem>;
  registerUser: Maybe<User>;
  /** Reject dataDifferences who verify where input parameters */
  rejectDataDifferences: Maybe<Scalars['Boolean']>;
  /** Removes project data connection */
  removeDataConnection: Maybe<Scalars['ID']>;
  /** Renew a lock on an asset that has enough labeler or reviewer working on it when the initial lock timed out and the asset remains unsubmitted. */
  renewLock: RenewLockOutput;
  /** Reset password of a user. */
  resetAndReturnPassword: Maybe<Scalars['String']>;
  /** The request to reset a forgotten password */
  resetPassword: Maybe<User>;
  /** Run the associated plugins to a project on demand */
  runPlugin: Scalars['Boolean'];
  /** Save an LLM Asset */
  saveLLMAsset: Asset;
  /** Add all reviewed assets specified, or if None, all in the project to the to do queue */
  sendBackAssetsToQueue: Maybe<Project>;
  /** Send Email to support as free user */
  sendEmailToSupport: Maybe<Scalars['Boolean']>;
  /** Call Auth0 API to resend email verification */
  sendVerificationEmail: Maybe<Scalars['Boolean']>;
  /**
   * Honeypot is a quality parameter to measure the agreement between asset pre-annotated (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * Set several assets as Honeypot
   */
  setHoneypot: Maybe<Array<Scalars['String']>>;
  /**
   * The request to sign up a new email to the Kili app.
   *
   * If authorized, the email will receive informations to sign in.
   * @deprecated RegisterUser mutation has been created
   */
  signUp: Maybe<User>;
  /** Skip the asset if one asset is found */
  skipAsset: Maybe<Asset>;
  /**
   * Unchecks the given tag for the given project.
   *
   * Expects the tag to be checked for the project in the first place,
   * do not call optimistically.
   */
  uncheckTag: Maybe<Tag>;
  /** update the resolution (for IMAGE and VIDEO) or page resolutions (for PDF) of the asset */
  updateAssetResolution: Maybe<UpdateAssetResolutionOutput>;
  /** Update a Chat item */
  updateChatItem: ChatItem;
  /** Update project data connection */
  updateDataConnection: DataConnection;
  updateModel: Model;
  /** Update the settings of the organization */
  updateOrganizationSettings: Maybe<UpdateOrganizationSettingsPayload>;
  /** Update password of a user. */
  updatePassword: Maybe<User>;
  /** Update the plugin's runner */
  updatePluginRunner: Scalars['String'];
  /** Anonymize the project for the labelers and reviewers */
  updateProjectAnonymization: Maybe<Project>;
  updateProjectModel: ProjectModel;
  updatePropertiesInApiKey: Maybe<ApiKey>;
  /**
   * Update the properties of one asset.
   *
   * You may specify its externalID, its content (link to the asset), if it should be considered as a honeypot, its status and some metadata, among other things.
   * @deprecated This resolver is deprecated, please use updatePropertiesInAssets.
   */
  updatePropertiesInAsset: Maybe<Asset>;
  /** Update the properties of one or more assets. */
  updatePropertiesInAssets: Maybe<Array<Maybe<Asset>>>;
  /** Update an existing comment */
  updatePropertiesInComment: Maybe<Comment>;
  /** Update the dataIntegration's data */
  updatePropertiesInDataIntegration: Maybe<DataIntegration>;
  /** Update an existing issue */
  updatePropertiesInIssue: Maybe<Issue>;
  /** Change the annotations in a label. */
  updatePropertiesInLabel: Maybe<Label>;
  /** Update properties of the notification. */
  updatePropertiesInNotification: Maybe<Notification>;
  /** Update properties of the organization */
  updatePropertiesInOrganization: Maybe<Organization>;
  /** Update properties of the organization without license field */
  updatePropertiesInOrganizationWithoutLicense: Maybe<Organization>;
  /** Update the project's name and tasks. */
  updatePropertiesInProject: Maybe<Project>;
  /** Change properties of a projectUser */
  updatePropertiesInProjectUser: Maybe<ProjectUser>;
  updatePropertiesInProjectVersion: Maybe<ProjectVersion>;
  /** Change the role of a projectUser */
  updatePropertiesInRole: Maybe<ProjectUser>;
  /** An admin of an organization can modify every user's information. */
  updatePropertiesInUser: Maybe<User>;
  /**
   * Updates the given tag.
   *
   * This operation is organization-wide. The tag will be updated for all projects of the organization.
   */
  updateTag: UpdateTagReturn;
  /** Update the webhook */
  updateWebhook: Scalars['String'];
  /** Validate dataDifferences who verify where input parameters */
  validateDataDifferences: Maybe<DataConnection>;
}

export interface MutationacceptAdmissionRequestArgs {
  where: AcceptAdmissionRequest;
}

export interface MutationacceptOrDeclineInvitationArgs {
  data: AcceptOrDeclineInvitationData;
  where: ProjectWhere;
}

export interface MutationactivatePluginOnProjectArgs {
  where: ActivateOrDeactivatePluginWhere;
}

export interface MutationaddAllLabeledAssetsToReviewArgs {
  where: AssetWhere;
}

export interface MutationaddDataConnectionArgs {
  data: DataConnectionInput;
}

export interface MutationannotateLLMAssetArgs {
  data: AnnotateLLMAssetData;
  where: AssetWhere;
}

export interface MutationannotateWithCopilotArgs {
  data: AnnotateWithCopilotData;
  where: AssetWhere;
}

export interface MutationanonymizeOrganizationArgs {
  where: OrganizationWhere;
}

export interface MutationappendManyAssetsArgs {
  data: AppendManyAssetsData;
  where: ProjectWhere;
}

export interface MutationappendManyFramesToDatasetAsynchronouslyArgs {
  data: AppendManyFramesToDatasetAsynchronouslyData;
  where: ProjectWhere;
}

export interface MutationappendManyLabelsArgs {
  data: AppendManyLabelsData;
  where: AssetWhere;
}

export interface MutationappendManyToDatasetArgs {
  data: AppendManyToDatasetData;
  where: ProjectWhere;
}

export interface MutationappendManyToDatasetFrontendArgs {
  data: AppendManyToDatasetData;
  where: ProjectWhere;
}

export interface MutationappendToCommentsArgs {
  data: AppendToCommentsData;
  where: IssueWhere;
}

export interface MutationappendToIssuesArgs {
  data: AppendToIssuesData;
  where: AssetWhere;
}

export interface MutationappendToLabelsArgs {
  data: AppendToLabelsData;
  where: AssetWhere;
}

export interface MutationappendToRolesArgs {
  data: AppendToRolesData;
  where: ProjectWhere;
}

export interface MutationapplyChangesToAnnotationsArgs {
  data: AnnotationChangesInput;
}

export interface MutationapplyChangesToLabelArgs {
  data: ApplyChangesToLabelsData;
  where: LabelWhere;
}

export interface MutationassignAssetsToLabelersArgs {
  userIds: Array<Scalars['String']>;
  where: AssetWhere;
}

export interface MutationchangeProjectAuthorArgs {
  where: ProjectWhere;
}

export interface MutationcheckTagArgs {
  data: CheckedTagData;
}

export interface MutationcleanPreviousReportsArgs {
  data: CleanPreviousReportsPayload;
}

export interface MutationclearAnnotationsArgs {
  where: AnnotationWhere;
}

export interface MutationcomputeDifferencesArgs {
  data?: InputMaybe<DataConnectionComputeDifferencesPayload>;
  where: DataConnectionIdWhere;
}

export interface MutationcreateChatItemArgs {
  data: CreateChatItemData;
  where: AssetWhere;
}

export interface MutationcreateDataIntegrationArgs {
  data: DataIntegrationData;
}

export interface MutationcreateHoneypotArgs {
  data: CreateHoneypotData;
  where: AssetWhere;
}

export interface MutationcreateIssuesArgs {
  issues: Array<IssueToCreate>;
  where: AssetWhere;
}

export interface MutationcreateLLMAssetArgs {
  data: CreateLLMAssetData;
  where: ProjectWhere;
}

export interface MutationcreateModelArgs {
  input: CreateModelInput;
}

export interface MutationcreateNotificationArgs {
  data: NotificationData;
}

export interface MutationcreateOrganizationArgs {
  data: CreateOrganizationData;
}

export interface MutationcreatePluginArgs {
  data: CreatePluginData;
}

export interface MutationcreatePluginRunnerArgs {
  data: CreatePluginRunnerData;
}

export interface MutationcreatePredictionsArgs {
  data: CreatePredictionsData;
  where: AssetWhere;
}

export interface MutationcreateProjectArgs {
  data: CreateProjectData;
}

export interface MutationcreateProjectModelArgs {
  input: CreateProjectModelInput;
}

export interface MutationcreateTagArgs {
  data: TagData;
}

export interface MutationcreateUserArgs {
  data: CreateUserData;
}

export interface MutationcreateWebhookArgs {
  data: CreateWebhookData;
}

export interface MutationdataIntegrationTestArgs {
  dataIntegrationId: Scalars['ID'];
}

export interface MutationdeactivatePluginOnProjectArgs {
  where: ActivateOrDeactivatePluginWhere;
}

export interface MutationdeclineAdmissionRequestArgs {
  where: DeclineAdmissionRequest;
}

export interface MutationdeleteChatItemArgs {
  where: ChatItemWhere;
}

export interface MutationdeleteDataIntegrationArgs {
  where: DataIntegrationWhere;
}

export interface MutationdeleteFromRolesArgs {
  where: ProjectUserWhere;
}

export interface MutationdeleteLabelsArgs {
  ids: Array<Scalars['ID']>;
}

export interface MutationdeleteLocksArgs {
  input: DeleteLocksInput;
}

export interface MutationdeleteManyFromDatasetArgs {
  where: AssetWhere;
}

export interface MutationdeleteModelArgs {
  id: Scalars['ID'];
}

export interface MutationdeletePluginArgs {
  where: DeletePluginWhere;
}

export interface MutationdeleteProjectArgs {
  where: ProjectWhere;
}

export interface MutationdeleteProjectAsynchronouslyArgs {
  where: ProjectWhere;
}

export interface MutationdeleteProjectModelArgs {
  id: Scalars['ID'];
}

export interface MutationdeleteTagArgs {
  tagId: Scalars['ID'];
}

export interface MutationduplicateLlmLabelArgs {
  data: DuplicateLlmLabelData;
}

export interface MutationeditProjectWorkflowStepsArgs {
  input: EditProjectWorkflowStepsInput;
}

export interface MutationexportPerformanceDataArgs {
  projectId: Scalars['ID'];
}

export interface MutationfrontendAppendToLabelsArgs {
  data: AppendToLabelsData;
  where: AssetWhere;
}

export interface MutationfrontendAppendToRolesArgs {
  data: AppendToRolesData;
  where: ProjectWhere;
}

export interface MutationgenerateApiKeyArgs {
  data: GenerateApiKeyData;
  where: UserWhere;
}

export interface MutationgenerateUpdateUrlArgs {
  data: GenerateUpdateUrlData;
}

export interface MutationhardDeleteProjectArgs {
  where: ProjectWhere;
}

export interface MutationprioritizeAssetsArgs {
  priority: Scalars['Int'];
  where: AssetWhere;
}

export interface MutationregenerateChatItemsArgs {
  where: ChatItemWhere;
}

export interface MutationregisterUserArgs {
  data: SignUpData;
}

export interface MutationrejectDataDifferencesArgs {
  where: ValidateDataDifferencesWhere;
}

export interface MutationremoveDataConnectionArgs {
  where: DataConnectionIdWhere;
}

export interface MutationrenewLockArgs {
  where: AssetWhere;
}

export interface MutationresetAndReturnPasswordArgs {
  where: UserWhere;
}

export interface MutationresetPasswordArgs {
  where: UserWhere;
}

export interface MutationrunPluginArgs {
  data: RunPluginData;
}

export interface MutationsaveLLMAssetArgs {
  where: AssetWhere;
}

export interface MutationsendBackAssetsToQueueArgs {
  where: AssetWhere;
}

export interface MutationsendEmailToSupportArgs {
  data: SendEmailToSupportData;
}

export interface MutationsetHoneypotArgs {
  data: SetHoneypotData;
  where: AssetWhere;
}

export interface MutationsignUpArgs {
  data: SignUpData;
}

export interface MutationskipAssetArgs {
  where: AssetWhere;
}

export interface MutationuncheckTagArgs {
  projectId: Scalars['ID'];
  tagId: Scalars['ID'];
}

export interface MutationupdateAssetResolutionArgs {
  input: UpdateAssetResolutionInput;
}

export interface MutationupdateChatItemArgs {
  data: UpdateChatItemData;
  where: ChatItemWhere;
}

export interface MutationupdateDataConnectionArgs {
  data: UpdateDataConnectionInput;
  id: Scalars['ID'];
}

export interface MutationupdateModelArgs {
  id: Scalars['ID'];
  input: UpdateModelInput;
}

export interface MutationupdateOrganizationSettingsArgs {
  id: Scalars['ID'];
  settings: UpdateOrganizationSettingsInput;
}

export interface MutationupdatePasswordArgs {
  data: UpdatePasswordData;
  where: UserWhere;
}

export interface MutationupdatePluginRunnerArgs {
  data: UpdatePluginRunnerData;
}

export interface MutationupdateProjectAnonymizationArgs {
  input: UpdateProjectAnonymizationInput;
}

export interface MutationupdateProjectModelArgs {
  id: Scalars['ID'];
  input: UpdateProjectModelInput;
}

export interface MutationupdatePropertiesInApiKeyArgs {
  data: UpdateApiKeyPropertiesData;
  where: ApiKeyWhere;
}

export interface MutationupdatePropertiesInAssetArgs {
  data: AssetData;
  where: AssetWhere;
}

export interface MutationupdatePropertiesInAssetsArgs {
  data: Array<AssetData>;
  where: Array<AssetWhere>;
}

export interface MutationupdatePropertiesInCommentArgs {
  data: CommentData;
  where: CommentWhere;
}

export interface MutationupdatePropertiesInDataIntegrationArgs {
  data: DataIntegrationData;
  where: DataIntegrationWhere;
}

export interface MutationupdatePropertiesInIssueArgs {
  data: IssueData;
  where: IssueWhere;
}

export interface MutationupdatePropertiesInLabelArgs {
  data: LabelData;
  where: LabelWhere;
}

export interface MutationupdatePropertiesInNotificationArgs {
  data: NotificationData;
  where: NotificationWhere;
}

export interface MutationupdatePropertiesInOrganizationArgs {
  data: OrganizationData;
  where: OrganizationWhere;
}

export interface MutationupdatePropertiesInOrganizationWithoutLicenseArgs {
  data: OrganizationProperties;
  where: OrganizationWhere;
}

export interface MutationupdatePropertiesInProjectArgs {
  data: ProjectData;
  where: ProjectWhere;
}

export interface MutationupdatePropertiesInProjectUserArgs {
  data: ProjectUserData;
  where: ProjectUserWhere;
}

export interface MutationupdatePropertiesInProjectVersionArgs {
  data: ProjectVersionData;
  where: ProjectVersionWhere;
}

export interface MutationupdatePropertiesInRoleArgs {
  data: RoleData;
  where: ProjectUserWhere;
}

export interface MutationupdatePropertiesInUserArgs {
  data: UserData;
  where: UserWhere;
}

export interface MutationupdateTagArgs {
  data: UpdateTagData;
  tagId: Scalars['ID'];
}

export interface MutationupdateWebhookArgs {
  data: UpdateWebhookData;
}

export interface MutationvalidateDataDifferencesArgs {
  processingParameters?: InputMaybe<Scalars['String']>;
  where: ValidateDataDifferencesWhere;
}

/** Web notifications can be pushed to users to notify them from actions in Kili. */
export interface Notification {
  __typename: 'Notification';
  _: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  hasBeenSeen: Scalars['Boolean'];
  id: Scalars['ID'];
  message: Scalars['String'];
  status: NotificationStatus;
  url: Scalars['String'];
  userID: Scalars['String'];
}

export interface NotificationData {
  hasBeenSeen?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<NotificationStatus>;
  url?: InputMaybe<Scalars['String']>;
  userID?: InputMaybe<Scalars['String']>;
}

export enum NotificationStatus {
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export interface NotificationWhere {
  hasBeenSeen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<UserWhere>;
}

export interface NumberOfAnnotationsMetric extends CategoryMetric {
  __typename: 'NumberOfAnnotationsMetric';
  categoryKey: Scalars['String'];
  date: Scalars['DateTime'];
  jobKey: Scalars['String'];
  value: Maybe<Scalars['Int']>;
}

export interface NumberOfAssetsMetric extends CategoryMetric {
  __typename: 'NumberOfAssetsMetric';
  categoryKey: Scalars['String'];
  date: Scalars['DateTime'];
  jobKey: Scalars['String'];
  value: Maybe<Scalars['Int']>;
}

export interface ObjectDetectionAnnotationValue extends AnnotationValue {
  __typename: 'ObjectDetectionAnnotationValue';
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
  vertices: Array<Array<Array<Vertice>>>;
}

export interface OpenQuestionsMetric extends AssetMetric {
  __typename: 'OpenQuestionsMetric';
  date: Scalars['DateTime'];
  /** Number of assets with opened questions after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

/** Organization are the basic unit in for Kili, and can gather different users, that can create projects. */
export interface Organization {
  __typename: 'Organization';
  _: Maybe<Scalars['Boolean']>;
  /** Number of active users in the organization */
  activeUsersCount: Scalars['Int'];
  /** @deprecated This information is not relevant */
  address: Scalars['String'];
  /** @deprecated This information is not relevant */
  city: Scalars['String'];
  /** @deprecated This information is not relevant */
  country: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  dataIntegrations: Maybe<Array<Maybe<DataIntegration>>>;
  id: Scalars['ID'];
  /** Boolean indicating whether a user can still make labels given the organization usage and license config */
  isAllowedToSubmitNewLabels: Scalars['Boolean'];
  license: Maybe<License>;
  matchingDomain: Maybe<Scalars['String']>;
  maximumNumberOfLLMCalls: Scalars['Int'];
  /**
   * Monthly usage
   * @deprecated Not used by future version of clients, but kept to guarantee retro compatibility for new release planned 2023-01-18, can be deleted after this date
   */
  monthlyUsage: OrganizationMonthlyUsage;
  name: Scalars['String'];
  /** @deprecated This field will be removed, use organizationMetrics query or organization_metrics method from the sdk instead */
  numberOfAnnotations: Scalars['Int'];
  /** @deprecated This field will be removed, use organizationMetrics query or organization_metrics method from the sdk instead */
  numberOfHours: Scalars['Float'];
  numberOfLLMCallsRemaining: Scalars['Int'];
  /** @deprecated This field will be removed, use organizationMetrics query or organization_metrics method from the sdk instead */
  numberOfLabeledAssets: Scalars['Int'];
  /** @deprecated Not used by future version of clients, but kept to guarantee retro compatibility for new release planned 2023-01-18, can be deleted after this date */
  organizationMetrics: Maybe<Array<Maybe<OrganizationMetric>>>;
  settings: OrganizationSetting;
  /** Usage metrics, containing current month usage and usage on license period */
  usageMetrics: OrganizationUsageMetrics;
  /** Usage metrics over time */
  usageOverTime: Maybe<OrganizationUsageOverTime>;
  users: Maybe<Array<Maybe<User>>>;
  /** @deprecated This information is not relevant */
  zipCode: Scalars['String'];
}

/** Organization are the basic unit in for Kili, and can gather different users, that can create projects. */
export interface OrganizationorganizationMetricsArgs {
  endDate?: InputMaybe<Scalars['DateTime']>;
  granularity: Granularity;
  metrics?: InputMaybe<Array<OrganizationMetricInput>>;
  startDate: Scalars['DateTime'];
}

/** Organization are the basic unit in for Kili, and can gather different users, that can create projects. */
export interface OrganizationusageOverTimeArgs {
  input?: InputMaybe<GetUsageOverTimeInput>;
}

export interface OrganizationAnnotationsMetric extends OrganizationMetric {
  __typename: 'OrganizationAnnotationsMetric';
  date: Scalars['DateTime'];
  /** Number of assets with opened questions after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface OrganizationData {
  license?: InputMaybe<Scalars['String']>;
  matchingDomain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface OrganizationLabeledAssetsMetric extends OrganizationMetric {
  __typename: 'OrganizationLabeledAssetsMetric';
  date: Scalars['DateTime'];
  /** Number of assets with opened questions after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface OrganizationMatchingDomain {
  __typename: 'OrganizationMatchingDomain';
  id: Scalars['ID'];
  matchingDomain: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface OrganizationMembersMetric extends OrganizationMetric {
  __typename: 'OrganizationMembersMetric';
  date: Scalars['DateTime'];
  /** Number of assets with opened questions after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface OrganizationMetric {
  date: Scalars['DateTime'];
  value: Maybe<Scalars['Int']>;
}

export enum OrganizationMetricInput {
  Annotations = 'Annotations',
  LabeledAssets = 'LabeledAssets',
  Members = 'Members',
  PredictionAndInferenceLabels = 'PredictionAndInferenceLabels',
}

/** Technical field */
export interface OrganizationMetrics {
  __typename: 'OrganizationMetrics';
  _: Maybe<Scalars['Boolean']>;
  /** Technical field */
  maxNumberOfLabeledAssets: Scalars['Int'];
  /** Technical field */
  numberOfAnnotations: Scalars['Int'];
  /** Technical field */
  numberOfHours: Scalars['Float'];
  /** Technical field */
  numberOfLabeledAssets: Scalars['Int'];
}

export interface OrganizationMetricsWhere {
  endDate?: InputMaybe<Scalars['DateTime']>;
  organizationId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
}

export interface OrganizationMonthlyUsage {
  __typename: 'OrganizationMonthlyUsage';
  /** Number of label annotations counted in the current month */
  annotations: Scalars['Int'];
  /** Number of labeled assets counted in the current month */
  labeledAssets: Scalars['Int'];
}

export interface OrganizationPredictionAndInferenceLabelsMetric extends OrganizationMetric {
  __typename: 'OrganizationPredictionAndInferenceLabelsMetric';
  date: Scalars['DateTime'];
  /** Number of assets with opened questions after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface OrganizationProperties {
  matchingDomain?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}

export enum OrganizationRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

/** Organization settings define custom settings for an organization. */
export interface OrganizationSetting {
  __typename: 'OrganizationSetting';
  _: Maybe<Scalars['Boolean']>;
  /** Determines whether the performance tab should be displayed in the project analytics or not */
  showUserPerformance: Scalars['Boolean'];
}

export interface OrganizationUsageMetrics {
  __typename: 'OrganizationUsageMetrics';
  /** Monthly usage */
  currentMonthUsage: OrganizationMonthlyUsage;
  /** Number of active members */
  numberOfActiveMembers: Scalars['Int'];
  /** Usage metrics on license validity period */
  usageOnLicensePeriod: OrganizationUsageOnLicensePeriod;
}

export enum OrganizationUsageMetricsGranularity {
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export interface OrganizationUsageOnLicensePeriod {
  __typename: 'OrganizationUsageOnLicensePeriod';
  /** Number of label annotations counted in the license validity period */
  numberOfAnnotations: Scalars['Int'];
  /** Number of labeled assets counted in the license validity period */
  numberOfLabeledAssets: Scalars['Int'];
  /** Time spent on labeling during the license validity period hh hours(s) and mm minute(s) */
  timeSpentOnLabeling: Scalars['String'];
}

export interface OrganizationUsageOverTime {
  __typename: 'OrganizationUsageOverTime';
  membersAdded: MembersAddedOverTime;
  usageMetrics: UsageMetricsOverTime;
}

export interface OrganizationWhere {
  id?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<UserWhere>;
}

export interface PageResolution {
  __typename: 'PageResolution';
  height: Scalars['Int'];
  pageNumber: Scalars['Int'];
  rotation: Maybe<Scalars['Int']>;
  width: Scalars['Int'];
}

export interface PageResolutionInput {
  height: Scalars['Int'];
  pageNumber: Scalars['Int'];
  rotation?: InputMaybe<Scalars['Int']>;
  width: Scalars['Int'];
}

export interface PendingRequestCreatedOrUpdatedInput {
  organization: Scalars['ID'];
  userID: Scalars['ID'];
}

export interface Period {
  __typename: 'Period';
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
}

export interface Plugin {
  __typename: 'Plugin';
  _: Maybe<Scalars['Boolean']>;
  /** Technical field */
  createdAt: Scalars['DateTime'];
  /** Actions for which the plugin should be executed */
  handlerTypes: Array<PluginHandlerType>;
  id: Maybe<Scalars['ID']>;
  /** Plugin's name */
  name: Scalars['String'];
  /** Your organization's id */
  organizationId: Scalars['String'];
  /** List of projectId where the plugin is activated */
  projectIds: Maybe<Array<Scalars['String']>>;
  /** Technical field */
  updatedAt: Scalars['DateTime'];
}

/** Plugin build error is a log of a build error on plugin */
export interface PluginBuildError {
  __typename: 'PluginBuildError';
  _: Maybe<Scalars['Boolean']>;
  /** Information about the log */
  content: Scalars['String'];
  /** Technical field */
  createdAt: Scalars['DateTime'];
  id: Maybe<Scalars['ID']>;
  logType: LogType;
  /** The name of the plugin that produced the log */
  pluginName: Scalars['String'];
}

export enum PluginHandlerType {
  onCustomInterfaceClick = 'onCustomInterfaceClick',
  onProjectUpdated = 'onProjectUpdated',
  onReview = 'onReview',
  onSendBackToQueue = 'onSendBackToQueue',
  onSubmit = 'onSubmit',
}

/** Plugin log is a log of a plugin that has been run */
export interface PluginLog {
  __typename: 'PluginLog';
  _: Maybe<Scalars['Boolean']>;
  /** Information about the log */
  content: Scalars['String'];
  /** Technical field */
  createdAt: Scalars['DateTime'];
  id: Maybe<Scalars['ID']>;
  logType: LogType;
  /** Additional information of the log */
  metadata: PluginLogMetadata;
  /** The name of the plugin that produced the log */
  pluginName: Scalars['String'];
  projectId: Scalars['ID'];
  /** Id to be able to identify the logs of the same run */
  runId: Scalars['String'];
}

export interface PluginLogMetadata {
  __typename: 'PluginLogMetadata';
  /** ID of the asset */
  assetId: Maybe<Scalars['ID']>;
  /** Technical field */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** ID of the label */
  labelId: Maybe<Scalars['ID']>;
  /** Technical field */
  updatedAt: Scalars['DateTime'];
}

/** It is the combination of an asset type, a dataset to be annotated, an interface adapted to the annotation task, of one or more participants, specific quality parameters, and potentially a specific machine learning model. */
export interface Project {
  __typename: 'Project';
  _: Maybe<Scalars['Boolean']>;
  archivedAt: Maybe<Scalars['DateTime']>;
  /**
   * Technical field
   *
   * AssetMetadata groups all different metadata keys present in the assets of the project
   */
  assetMetadata: Scalars['JSON'];
  /**
   * Technical field
   *
   * AssetMetadata groups all different metadata keys present in the assets of the project
   */
  assetMetadataCompute: Maybe<Scalars['JSON']>;
  assetsMetrics: Maybe<Array<AssetMetric>>;
  author: Maybe<User>;
  /** Allow user to use next and previous buttons in labeling interface */
  canNavigateBetweenAssets: Maybe<Scalars['Boolean']>;
  /** Allow user to use skip button in labeling interface */
  canSkipAsset: Maybe<Scalars['Boolean']>;
  /** Allow labelers and reviewers to use automatically generated shortcuts based on the category name to select an annotation task */
  canUseGeneratedShortcut: Maybe<Scalars['Boolean']>;
  categoryMetrics: Maybe<Array<CategoryMetric>>;
  /**
   * Compliance tags are used to categorize projects based on the sensitivity of the data being handled and the legal constraints associated with it
   *
   * List of compliance tags
   */
  complianceTags: Maybe<Array<Maybe<ComplianceTag>>>;
  /**
   * Consensus is quality parameter to measure the agreement between several annotations of the same asset, made by different annotators
   *
   * The global mark for the project depends on the
   */
  consensusMark: Maybe<Scalars['Float']>;
  /** Consensus mark per category, averaged over all assets labeled on the project */
  consensusMarkPerCategory: Maybe<Scalars['JSON']>;
  /**
   * Consensus is quality parameter to measure the agreement between several annotations of the same asset, made by different annotators
   *
   * Its coverage is the percentage (between 0 and 100) of the dataset that will be annotated several times.
   */
  consensusTotCoverage: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  dataConnections: Maybe<Array<Maybe<DataConnection>>>;
  /** The Dataset is all the assets related to this project */
  dataset: Maybe<Array<Asset>>;
  deletedAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  /** True if there is an activated on_custom_interface_click plugin on project */
  hasCustomPlugins: Scalars['Boolean'];
  /**
   * Honeypot is a quality parameter to measure the agreement between a pre-annotated asset (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * At the project level, it is the mean accross all anotators and all assets selected for honeypot.
   */
  honeypotMark: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /**
   * The type of asset for the project :
   *
   * AUDIO
   * IMAGE
   * PDF
   * TEXT
   * TIME_SERIES
   * VIDEO
   * LLM_RLHF
   * LLM_INSTR_FOLLOWING
   */
  inputType: InputType;
  instructions: Maybe<Scalars['String']>;
  /** A JSON file containing all the informations regarding the graphical user interface configured at the beginning of a project, made available to users, to enable them to perform the annotation task. Duplicate of jsonInterface formatted as as JSON instead of a string. */
  interface: Scalars['JSON'];
  /** Technical field. */
  interfaceCompute: Maybe<Scalars['JSON']>;
  /** If a project is anonymized for labelers and reviewers */
  isAnonymized: Scalars['Boolean'];
  /** A JSON file containing all the informations regarding the graphical user interface configured at the beginning of a project, made available to users, to enable them to perform the annotation task. */
  jsonInterface: Maybe<Scalars['String']>;
  labelerMetrics: Maybe<Array<LabelerMetric>>;
  /** The status of the labeling queue. */
  labelingQueueStatus: ProjectQueueStatus;
  /** Get the maxDatasetSize of the project */
  maxDatasetSize: Scalars['Int'];
  /** Custom types for metadata */
  metadataTypes: Maybe<Scalars['JSON']>;
  /** Min and max priority of the assets of the project */
  minAndMaxPriority: Maybe<Array<Scalars['Int']>>;
  /**
   * Consensus is quality parameter to measure the agreement between several annotations of the same asset, made by different annotators
   *
   * Its minimum size is the number of annotators that will have to annotate the same asset. It must be set to a number less than or equal to the total number of annotators.
   * For now, it is limited to at most 10 labelers, for performance reasons.
   */
  minConsensusSize: Maybe<Scalars['Int']>;
  /** Technical field, the unique types of tasks found in the project interface. */
  mlTasks: Maybe<Scalars['String']>;
  /** Technical field. */
  mlTasksCompute: Maybe<Scalars['String']>;
  myRole: Maybe<ProjectUser>;
  numberOfAssets: Maybe<Scalars['Int']>;
  /**
   * [Deprecated] The number of assets one annotator at least skipped in the project.
   * @deprecated This field has been replaced by numberOfSkippedAssets. It will be removed in the near future
   */
  numberOfAssetsWithSkippedLabels: Maybe<Scalars['Int']>;
  /** The total number of open issues in the project. */
  numberOfOpenIssues: Maybe<Scalars['Int']>;
  /** The total number of open questions in the project. */
  numberOfOpenQuestions: Maybe<Scalars['Int']>;
  /** The number of unlabeled assets in the project */
  numberOfRemainingAssets: Maybe<Scalars['Int']>;
  /** The number of assets reviewed in the project */
  numberOfReviewedAssets: Maybe<Scalars['Int']>;
  /** The number of assets one annotator at least skipped in the project. */
  numberOfSkippedAssets: Maybe<Scalars['Int']>;
  /** The total number of solved issues in the project. */
  numberOfSolvedIssues: Maybe<Scalars['Int']>;
  /** The total number of solved questions in the project. */
  numberOfSolvedQuestions: Maybe<Scalars['Int']>;
  /**
   * Review is a quality assessment procedure in which the reviewer validate or not the label created on asset. He can create issues on the labels or on several annotations, or send back to annotation.
   *
   * Its coverage is the percentage (between 0 and 100) of the dataset that will be up for review once labeled
   */
  reviewCoverage: Maybe<Scalars['Int']>;
  /** The list of project rights. */
  rights: Array<Right>;
  /** One or more projectUsers inivited to the project. */
  roles: Maybe<Array<ProjectUser>>;
  /**
   * [Deprecated] Constraints to be applied to the annotation jobs for rule-based annotation.
   * @deprecated This field is not used anymore. Please upgrade kili by typing pip install kili --upgrade
   */
  rules: Maybe<Scalars['JSON']>;
  /**
   * Number of seconds before an asset is automatically assigned to a labeler on new label.
   * If set to null, auto-assign is disabled.
   */
  secondsToLabelBeforeAutoAssign: Maybe<Scalars['Int']>;
  /** Technical field. */
  shouldRelaunchKpiComputation: Maybe<Scalars['Boolean']>;
  steps: Maybe<Array<ProjectWorkflowStep>>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /**
   * Honeypot is a quality parameter to measure the agreement between a pre-annotated asset (a golden standard) and the annotation made by an annotator. Quality is measured against a predefined truth.
   *
   * True or false depending on if Honeypot is activated for this project.
   */
  useHoneyPot: Maybe<Scalars['Boolean']>;
  /** True if review scores are computed for the project */
  useReviewScore: Scalars['Boolean'];
  /** List of project versions. */
  versions: Maybe<Array<ProjectVersion>>;
}

/** It is the combination of an asset type, a dataset to be annotated, an interface adapted to the annotation task, of one or more participants, specific quality parameters, and potentially a specific machine learning model. */
export interface ProjectassetsMetricsArgs {
  endDate?: InputMaybe<Scalars['DateTime']>;
  granularity: Granularity;
  metrics?: InputMaybe<Array<AssetMetricInput>>;
  startDate: Scalars['DateTime'];
}

/** It is the combination of an asset type, a dataset to be annotated, an interface adapted to the annotation task, of one or more participants, specific quality parameters, and potentially a specific machine learning model. */
export interface ProjectcategoryMetricsArgs {
  endDate?: InputMaybe<Scalars['DateTime']>;
  granularity: Granularity;
  metrics?: InputMaybe<Array<CategoryMetricInput>>;
  startDate: Scalars['DateTime'];
}

/** It is the combination of an asset type, a dataset to be annotated, an interface adapted to the annotation task, of one or more participants, specific quality parameters, and potentially a specific machine learning model. */
export interface ProjectlabelerMetricsArgs {
  endDate?: InputMaybe<Scalars['DateTime']>;
  granularity: Granularity;
  metrics?: InputMaybe<Array<LabelerMetricInput>>;
  resolveAll?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['DateTime'];
  timeZone?: InputMaybe<Scalars['String']>;
}

export interface ProjectBillingMetrics {
  __typename: 'ProjectBillingMetrics';
  /** Technical field */
  numberOfAnnotations: Scalars['Int'];
  /** Technical field */
  numberOfHours: Scalars['Float'];
  /** Technical field */
  numberOfLabeledAssets: Scalars['Int'];
}

export interface ProjectBillingWhere {
  endDate?: InputMaybe<Scalars['DateTime']>;
  projectId: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
}

export interface ProjectData {
  archived?: InputMaybe<Scalars['Boolean']>;
  author?: InputMaybe<Scalars['String']>;
  canNavigateBetweenAssets?: InputMaybe<Scalars['Boolean']>;
  canSkipAsset?: InputMaybe<Scalars['Boolean']>;
  canUseGeneratedShortcut?: InputMaybe<Scalars['Boolean']>;
  complianceTags?: InputMaybe<Array<InputMaybe<ComplianceTag>>>;
  consensusMark?: InputMaybe<Scalars['Float']>;
  consensusTotCoverage?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  honeypotMark?: InputMaybe<Scalars['Float']>;
  inputType?: InputMaybe<InputType>;
  instructions?: InputMaybe<Scalars['String']>;
  jsonInterface?: InputMaybe<Scalars['String']>;
  metadataTypes?: InputMaybe<Scalars['JSON']>;
  minConsensusSize?: InputMaybe<Scalars['Int']>;
  numberOfAssets?: InputMaybe<Scalars['Int']>;
  numberOfRemainingAssets?: InputMaybe<Scalars['Int']>;
  numberOfReviewedAssets?: InputMaybe<Scalars['Int']>;
  numberOfSkippedAssets?: InputMaybe<Scalars['Int']>;
  reviewCoverage?: InputMaybe<Scalars['Int']>;
  rules?: InputMaybe<Scalars['String']>;
  secondsToLabelBeforeAutoAssign?: InputMaybe<Scalars['Int']>;
  shouldRelaunchKpiComputation?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  useHoneyPot?: InputMaybe<Scalars['Boolean']>;
  useReviewScore?: InputMaybe<Scalars['Boolean']>;
}

export interface ProjectModel {
  __typename: 'ProjectModel';
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  model: Model;
  modelId: Scalars['ID'];
  project: Project;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
}

export interface ProjectModelWhere {
  modelId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
}

export enum ProjectQueueStatus {
  AVAILABLE = 'AVAILABLE',
  CLOSED = 'CLOSED',
  REBUILDING = 'REBUILDING',
}

export enum ProjectRole {
  ADMIN = 'ADMIN',
  LABELER = 'LABELER',
  REVIEWER = 'REVIEWER',
  TEAM_MANAGER = 'TEAM_MANAGER',
}

export enum ProjectType {
  DEMO_ANIMALS = 'DEMO_ANIMALS',
  DEMO_CHATBOT = 'DEMO_CHATBOT',
  DEMO_COMPUTER_VISION_TUTORIAL = 'DEMO_COMPUTER_VISION_TUTORIAL',
  DEMO_ID_OCR = 'DEMO_ID_OCR',
  DEMO_LLM = 'DEMO_LLM',
  DEMO_LLM_INSTR_FOLLOWING = 'DEMO_LLM_INSTR_FOLLOWING',
  DEMO_NER = 'DEMO_NER',
  DEMO_NER_TWEETS = 'DEMO_NER_TWEETS',
  DEMO_OCR = 'DEMO_OCR',
  DEMO_PDF = 'DEMO_PDF',
  DEMO_PDF_TUTORIAL = 'DEMO_PDF_TUTORIAL',
  DEMO_PLASTIC = 'DEMO_PLASTIC',
  DEMO_REVIEWS = 'DEMO_REVIEWS',
  DEMO_SATELLITE = 'DEMO_SATELLITE',
  DEMO_SEGMENTATION = 'DEMO_SEGMENTATION',
  DEMO_SEGMENTATION_COCO = 'DEMO_SEGMENTATION_COCO',
  DEMO_TEXT_TUTORIAL = 'DEMO_TEXT_TUTORIAL',
  IMAGE_CLASSIFICATION_MULTI = 'IMAGE_CLASSIFICATION_MULTI',
  IMAGE_CLASSIFICATION_SINGLE = 'IMAGE_CLASSIFICATION_SINGLE',
  IMAGE_OBJECT_DETECTION_POLYGON = 'IMAGE_OBJECT_DETECTION_POLYGON',
  IMAGE_OBJECT_DETECTION_RECTANGLE = 'IMAGE_OBJECT_DETECTION_RECTANGLE',
  IMAGE_OBJECT_DETECTION_SEMANTIC = 'IMAGE_OBJECT_DETECTION_SEMANTIC',
  IMAGE_POSE_ESTIMATION = 'IMAGE_POSE_ESTIMATION',
  OCR = 'OCR',
  PDF_CLASSIFICATION_MULTI = 'PDF_CLASSIFICATION_MULTI',
  PDF_CLASSIFICATION_SINGLE = 'PDF_CLASSIFICATION_SINGLE',
  PDF_NAMED_ENTITY_RECOGNITION = 'PDF_NAMED_ENTITY_RECOGNITION',
  PDF_OBJECT_DETECTION_RECTANGLE = 'PDF_OBJECT_DETECTION_RECTANGLE',
  SPEECH_TO_TEXT = 'SPEECH_TO_TEXT',
  TEXT_CLASSIFICATION_MULTI = 'TEXT_CLASSIFICATION_MULTI',
  TEXT_CLASSIFICATION_SINGLE = 'TEXT_CLASSIFICATION_SINGLE',
  TEXT_NER = 'TEXT_NER',
  TEXT_TRANSCRIPTION = 'TEXT_TRANSCRIPTION',
  TIME_SERIES = 'TIME_SERIES',
  VIDEO_FRAME_CLASSIFICATION = 'VIDEO_FRAME_CLASSIFICATION',
  VIDEO_FRAME_OBJECT_TRACKING = 'VIDEO_FRAME_OBJECT_TRACKING',
}

/**
 * ProjectUser is the instance of a user within a project.
 *
 * A projectUser has a role (ADMIN, TEAM_MANAGER, REVIEWER, LABELER) which grants him specific rights within this project in terms of read/write operations.
 */
export interface ProjectUser {
  __typename: 'ProjectUser';
  _: Maybe<Scalars['Boolean']>;
  /** Activated projectUsers can access their project. Deactivated projectUsers cannot access their project. */
  activated: Scalars['Boolean'];
  /** Consensus mark per category, averaged over all assets labeled with type DEFAULT by the projectUser */
  consensusMarkPerCategory: Maybe<Scalars['JSON']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** isAuthor indicates whether a project user is author of its project or not. */
  isAuthor: Maybe<Scalars['Boolean']>;
  /** @deprecated This field will be removed, use labels instead */
  lastLabelingAt: Maybe<Scalars['String']>;
  /** Technical field. */
  lastLabelingAtCompute: Maybe<Scalars['String']>;
  /**
   * Number of annotations of a projectUser is the total count of all number of annotations on all assets labeled with type DEFAULT by the projectUser.
   *
   * This is a calculated field, so querying it may slow down the query.
   * @deprecated This field will be removed, use projects resolver with labelerMetrics relation instead
   */
  numberOfAnnotations: Maybe<Scalars['Int']>;
  /** Technical field. */
  numberOfAnnotationsCompute: Maybe<Scalars['Int']>;
  /**
   * Number of labeled assets of a projectUser is the number of assets labeled with type DEFAULT by the projectUser.
   * @deprecated This field will be removed, use projects resolver with labelerMetrics relation instead
   */
  numberOfLabeledAssets: Maybe<Scalars['Int']>;
  /** Technical field. */
  numberOfLabeledAssetsCompute: Maybe<Scalars['Int']>;
  /**
   * Number of labels of type "DEFAULT" and "REVIEW" made by the projectUser.
   * @deprecated This field will be removed, use labels instead
   */
  numberOfLabels: Maybe<Scalars['Int']>;
  /** Technical field. */
  numberOfLabelsCompute: Maybe<Scalars['Int']>;
  /** Project of projectUser */
  project: Project;
  /**
   * Role of projectUser within the project. It can either be:
   *
   * ADMIN
   * TEAM_MANAGER
   * REVIEWER
   * LABELER
   */
  role: ProjectRole;
  /** Did user from projectUser star project in the list of projects on front page? */
  starred: Scalars['Boolean'];
  /**
   * status indicates the current projectuser status.
   *   ORG_ADMIN : Is an Organization Admin. Is automatically added to projects.
   *   ACTIVATED : Has been invited to the project. Is not an Organization Admin
   *   ORG_SUSPENDED : Has been suspended at the organization level. Can no longer access any projects.
   */
  status: ProjectUserStatus;
  /**
   * Total time (in seconds) spent labelling assets labeled with type DEFAULT or REVIEW by the projectUser.
   *
   * This is a calculated field, so querying it may slow down the query.
   * @deprecated This field will be removed, use projects resolver with labelerMetrics relation instead
   */
  totalDuration: Maybe<Scalars['Int']>;
  /** Technical field. */
  totalDurationCompute: Maybe<Scalars['Int']>;
  /** User of projectUser */
  user: User;
}

export interface ProjectUserData {
  activated?: InputMaybe<Scalars['Boolean']>;
  numberOfAnnotations?: InputMaybe<Scalars['Int']>;
  numberOfLabeledAssets?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<ProjectRole>;
  starred?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ProjectUserStatus>;
  totalDuration?: InputMaybe<Scalars['Int']>;
}

export enum ProjectUserStatus {
  ACTIVATED = 'ACTIVATED',
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_SUSPENDED = 'ORG_SUSPENDED',
}

export interface ProjectUserWhere {
  activeInProject?: InputMaybe<Scalars['Boolean']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isAuthor?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<ProjectWhere>;
  status?: InputMaybe<ProjectUserStatus>;
  user?: InputMaybe<UserWhere>;
}

/**
 * ProjectVersion is a snapshot of the list of labels of a project at a given time.
 *
 * A projectVersion has a name and a download link to the snapshot.
 */
export interface ProjectVersion {
  __typename: 'ProjectVersion';
  _: Maybe<Scalars['Boolean']>;
  /** Link to download the project version. */
  content: Maybe<Scalars['String']>;
  /** Date of which the project version was created. */
  createdAt: Scalars['DateTime'];
  /** ID of the project version. */
  id: Scalars['ID'];
  /** Name of the project version. */
  name: Scalars['String'];
  /** Project from which the version was created. */
  project: Maybe<Project>;
  /** ProjectId from which the version was created. */
  projectId: Scalars['String'];
}

export interface ProjectVersionData {
  content?: InputMaybe<Scalars['String']>;
}

export interface ProjectVersionWhere {
  id?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['String']>;
}

export interface ProjectWhere {
  archived?: InputMaybe<Scalars['Boolean']>;
  asset?: InputMaybe<AssetWhere>;
  assetTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  complianceTags?: InputMaybe<Array<InputMaybe<ComplianceTag>>>;
  createdAtGte?: InputMaybe<Scalars['String']>;
  createdAtLte?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  idNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  organizationId?: InputMaybe<Scalars['String']>;
  progressGte?: InputMaybe<Scalars['Float']>;
  progressLte?: InputMaybe<Scalars['Float']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  shouldRelaunchKpiComputation?: InputMaybe<Scalars['Boolean']>;
  starred?: InputMaybe<Scalars['Boolean']>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAtGte?: InputMaybe<Scalars['String']>;
  updatedAtLte?: InputMaybe<Scalars['String']>;
}

export interface ProjectWorkflowStep {
  __typename: 'ProjectWorkflowStep';
  assignees: Array<User>;
  consensusCoverage: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActivated: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfExpectedLabelsForConsensus: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  projectId: Scalars['ID'];
  stepCoverage: Maybe<Scalars['Int']>;
  type: ProjectWorkflowStepType;
  updatedAt: Scalars['DateTime'];
}

export enum ProjectWorkflowStepType {
  DEFAULT = 'DEFAULT',
  REVIEW = 'REVIEW',
}

export enum Quality {
  Consensus = 'Consensus',
  Honeypot = 'Honeypot',
  Inference = 'Inference',
}

export interface QualityLabelerMetric extends LabelerMetric {
  __typename: 'QualityLabelerMetric';
  date: Scalars['DateTime'];
  /** Number of elements used to calculate the average quality */
  elementsCount: Maybe<Scalars['Int']>;
  type: Quality;
  userId: Scalars['ID'];
  /** Average quality for user on given granularity */
  value: Maybe<Scalars['Float']>;
}

export interface QualityMetric extends AssetMetric {
  __typename: 'QualityMetric';
  date: Scalars['DateTime'];
  /** Quality parameter */
  type: Quality;
  /** Number of assets evaluated with given quality parameter created after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface Query {
  __typename: 'Query';
  _: Maybe<Scalars['Boolean']>;
  /** Technical query, used to request the email of an admin of a user */
  adminOfUser: Scalars['String'];
  /** Retrieves page by page all annotations who verify where input parameters. */
  annotations: Maybe<Array<Annotation>>;
  /** Retrieves page by page all api keys who verify where input parameters. */
  apiKeys: Maybe<Array<ApiKey>>;
  /** Retrieves one asset by its ID. */
  asset: Asset;
  /** Retrieves page by page all assets who verify where input parameters. */
  assets: Maybe<Array<Asset>>;
  /** List Chat items */
  chatItems: Array<ChatItem>;
  /** Tests a data integration to check whether Kili can communicate with the cloud provider which has been set up. */
  checkDataIntegrationConnection: Maybe<CheckDataIntegrationResult>;
  /** Count all api keys who verify where input parameters. */
  countApiKeys: Scalars['Int'];
  /** Count all assets who verify where input parameters. */
  countAssets: Scalars['Int'];
  /** Count all dataIntegrations who verify where input parameters. */
  countDataIntegrations: Scalars['Int'];
  /** Count all issues who verify where input parameters. */
  countIssues: Scalars['Int'];
  /** Count all labels who verify where input parameters. */
  countLabels: Scalars['Int'];
  /** Count notifications that verify input parameters. */
  countNotifications: Scalars['Int'];
  /** Count all organizations who verify where input parameters. */
  countOrganizations: Scalars['Int'];
  /** Count all project users who verify where input parameters. */
  countProjectUsers: Scalars['Int'];
  /** Count all projects versions depending on the where object */
  countProjectVersions: Scalars['Int'];
  /** Count all projects for a specific userID, */
  countProjects: Scalars['Int'];
  /** Count all users who verify where input parameters. */
  countUsers: Scalars['Int'];
  /**
   * Count all users according to speific search parameters (searchValue and showSuspendedUsers).
   * Used to count users from the query usersOfMyOrganization.
   */
  countUsersOfMyOrganization: Scalars['Int'];
  /** Create an upload url */
  createUploadBucketSignedUrls: Maybe<Array<Scalars['String']>>;
  /** Get information of a specific data connection */
  dataConnection: DataConnection;
  /** Get data connections related with specific project */
  dataConnections: Maybe<Array<DataConnection>>;
  /** Get the dataIntegration's data */
  dataIntegration: Maybe<DataIntegration>;
  /** Retrieves page by page all dataIntegrations who verify where input parameters. */
  dataIntegrations: Maybe<Array<DataIntegration>>;
  /** Export data from project. */
  exportData: Scalars['String'];
  /** Returns all exsiting externalIDs that match the input parameters. */
  filterExistingAssets: Maybe<Array<Scalars['String']>>;
  /** Get all values associated to a given metadata key */
  getAssetsMetadataValues: Maybe<Array<Scalars['String']>>;
  getLabelsOrPredictionsForUser: Maybe<Array<Label>>;
  getLabelsToBeReviewed: Maybe<Array<Label>>;
  /** Return the next asset */
  getNextAssetForReviewFromLabel: Maybe<AssetIdOnly>;
  /** Return the next asset available to label. */
  getNextAssetForReviewFromProject: Maybe<AssetIdOnly>;
  /** Return the next asset */
  getNextAssetFromLabel: Maybe<AssetIdOnly>;
  /** Return the next asset available to label. */
  getNextAssetFromProject: Maybe<AssetIdOnly>;
  /** Get plugin build errors */
  getPluginBuildErrors: Array<PluginBuildError>;
  /** Get plugin logs */
  getPluginLogs: Maybe<Array<Maybe<PluginLog>>>;
  /** Get the status of the plugin */
  getPluginRunnerStatus: Scalars['String'];
  /** Retrieves page by page all issues who verify where input parameters. */
  issues: Maybe<Array<Issue>>;
  /** Retrieves page by page all labels who verify where input parameters. */
  labels: Maybe<Array<Label>>;
  /** Retrieves page by page all projects ordered by updatedAt for the current user */
  latestUpdatedProjects: Maybe<Array<Project>>;
  /** List emails of users in an organization in alphabetical order. */
  listEmailsInOrganization: Maybe<Array<Scalars['String']>>;
  /** List plugins */
  listPlugins: Maybe<Array<Maybe<Plugin>>>;
  /** List all the different input types for the projects the connected user has access to */
  listProjectInputTypes: Array<InputType>;
  /** Returns the list of tags for the given organization. */
  listTagsByOrg: Maybe<Array<Maybe<Tag>>>;
  /** Returns the list of tags for the given project. */
  listTagsByProject: Maybe<Array<Maybe<Tag>>>;
  /** @deprecated Viewer query created */
  me: Maybe<User>;
  model: Maybe<Model>;
  models: Array<Model>;
  /** Retrieves page by page all notifications that verify input parameters. */
  notifications: Maybe<Array<Notification>>;
  /** Count number of labeled assets, annotations and hours on a specific period of time */
  organizationMetrics: OrganizationMetrics;
  /** Retrieves page by page all organizations who verify where input parameters. */
  organizations: Maybe<Array<Organization>>;
  /** Get billing informations about a given project */
  projectBillingMetrics: ProjectBillingMetrics;
  projectModel: Maybe<ProjectModel>;
  projectModels: Array<ProjectModel>;
  /** Retrieves page by page all projectUsers who verify where input parameters. */
  projectUsers: Maybe<Array<ProjectUser>>;
  /** Retrieves project versions depending on the where object */
  projectVersions: Maybe<Array<ProjectVersion>>;
  /** Retrieves page by page all projects ordered by createdAt for the current user (starred projects come first then unstarred projects) */
  projects: Maybe<Array<Project>>;
  /** Force splitting a video label response into multiple annotations */
  splitResponse: Maybe<Scalars['ID']>;
  user: Maybe<User>;
  /** Retrieves page by page all users who verify where input parameters. */
  users: Maybe<Array<User>>;
  /**
   * Retrieves users of my organization
   * @deprecated You can now get the users of your organization directly with the users query
   */
  usersOfMyOrganization: Maybe<Array<User>>;
  /** Retrieves the user */
  viewer: Maybe<User>;
}

export interface QueryannotationsArgs {
  where: AnnotationWhere;
}

export interface QueryapiKeysArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ApiKeyWhere;
}

export interface QueryassetArgs {
  id: Scalars['ID'];
}

export interface QueryassetsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: AssetWhere;
}

export interface QuerychatItemsArgs {
  where: ChatItemsWhere;
}

export interface QuerycheckDataIntegrationConnectionArgs {
  dataIntegrationId: Scalars['ID'];
}

export interface QuerycountApiKeysArgs {
  where: ApiKeyWhere;
}

export interface QuerycountAssetsArgs {
  where: AssetWhere;
}

export interface QuerycountDataIntegrationsArgs {
  where: DataIntegrationWhere;
}

export interface QuerycountIssuesArgs {
  where: IssueWhere;
}

export interface QuerycountLabelsArgs {
  where: LabelWhere;
}

export interface QuerycountNotificationsArgs {
  where: NotificationWhere;
}

export interface QuerycountOrganizationsArgs {
  where: OrganizationWhere;
}

export interface QuerycountProjectUsersArgs {
  where: ProjectUserWhere;
}

export interface QuerycountProjectVersionsArgs {
  where: ProjectVersionWhere;
}

export interface QuerycountProjectsArgs {
  where: ProjectWhere;
}

export interface QuerycountUsersArgs {
  where: UserWhere;
}

export interface QuerycountUsersOfMyOrganizationArgs {
  where: SearchUserWhere;
}

export interface QuerycreateUploadBucketSignedUrlsArgs {
  filePaths?: InputMaybe<Array<Scalars['String']>>;
}

export interface QuerydataConnectionArgs {
  where: DataConnectionIdWhere;
}

export interface QuerydataConnectionsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: DataConnectionsWhere;
}

export interface QuerydataIntegrationArgs {
  where: DataIntegrationWhere;
}

export interface QuerydataIntegrationsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: DataIntegrationWhere;
}

export interface QueryexportDataArgs {
  exportType?: InputMaybe<ExportType>;
  includeSentBackLabels?: InputMaybe<Scalars['Boolean']>;
  labelFormat?: InputMaybe<LabelFormat>;
  splitOption?: InputMaybe<SplitOption>;
  versionName?: InputMaybe<Scalars['String']>;
  where: ProjectWhere;
}

export interface QueryfilterExistingAssetsArgs {
  externalIDs: Array<Scalars['String']>;
  projectID: Scalars['ID'];
}

export interface QuerygetAssetsMetadataValuesArgs {
  metadataKey: Scalars['String'];
  projectID: Scalars['ID'];
}

export interface QuerygetLabelsOrPredictionsForUserArgs {
  assetID: Scalars['ID'];
  labelID?: InputMaybe<Scalars['ID']>;
  userID: Scalars['ID'];
}

export interface QuerygetLabelsToBeReviewedArgs {
  assetID: Scalars['ID'];
  labelID?: InputMaybe<Scalars['ID']>;
}

export interface QuerygetNextAssetForReviewFromLabelArgs {
  labelAssetIDs?: InputMaybe<Array<Scalars['ID']>>;
  projectId: Scalars['ID'];
}

export interface QuerygetNextAssetForReviewFromProjectArgs {
  doLock?: InputMaybe<Scalars['Boolean']>;
  projectID: Scalars['ID'];
}

export interface QuerygetNextAssetFromLabelArgs {
  labelAssetIDs?: InputMaybe<Array<Scalars['ID']>>;
  nonSubmittedViewedAssetIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
}

export interface QuerygetNextAssetFromProjectArgs {
  doLock?: InputMaybe<Scalars['Boolean']>;
  projectID: Scalars['ID'];
}

export interface QuerygetPluginBuildErrorsArgs {
  data: GetPluginBuildErrorsData;
}

export interface QuerygetPluginLogsArgs {
  data: GetPluginLogsData;
}

export interface QuerygetPluginRunnerStatusArgs {
  name: Scalars['String'];
}

export interface QueryissuesArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: IssueWhere;
}

export interface QuerylabelsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: LabelWhere;
}

export interface QuerylatestUpdatedProjectsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ProjectWhere;
}

export interface QuerylistEmailsInOrganizationArgs {
  where: OrganizationWhere;
}

export interface QuerylistTagsByProjectArgs {
  projectId: Scalars['ID'];
}

export interface QuerymodelArgs {
  id: Scalars['ID'];
}

export interface QuerymodelsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ModelWhere;
}

export interface QuerynotificationsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: NotificationWhere;
}

export interface QueryorganizationMetricsArgs {
  where: OrganizationMetricsWhere;
}

export interface QueryorganizationsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: OrganizationWhere;
}

export interface QueryprojectBillingMetricsArgs {
  where: ProjectBillingWhere;
}

export interface QueryprojectModelArgs {
  id: Scalars['ID'];
}

export interface QueryprojectModelsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ProjectModelWhere;
}

export interface QueryprojectUsersArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ProjectUserWhere;
}

export interface QueryprojectVersionsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ProjectVersionWhere;
}

export interface QueryprojectsArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: ProjectWhere;
}

export interface QuerysplitResponseArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: AnnotationWhere;
}

export interface QueryuserArgs {
  id: Scalars['ID'];
}

export interface QueryusersArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: UserWhere;
}

export interface QueryusersOfMyOrganizationArgs {
  first: Scalars['PageSize'];
  skip: Scalars['Int'];
  where: SearchUserWhere;
}

/** @deprecated - use ComparisonAnnotation instead */
export interface RankingAnnotation extends Annotation {
  __typename: 'RankingAnnotation';
  /** @deprecated RankingAnnotation is deprecated */
  annotationValue: Maybe<RankingAnnotationValue>;
  chatItemId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  job: Scalars['String'];
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

/** @deprecated - RankingAnnotation is deprecated */
export interface RankingAnnotationValue extends AnnotationValue {
  __typename: 'RankingAnnotationValue';
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
  /** @deprecated RankingAnnotationValue is deprecated */
  orders: Array<RankingOrderValue>;
}

/** @deprecated - RankingAnnotationValue is deprecated */
export interface RankingOrderValue {
  __typename: 'RankingOrderValue';
  elements: Array<Scalars['String']>;
  rank: Scalars['Int'];
}

/** @deprecated */
export interface RankingOrderValueInput {
  elements: Array<Scalars['String']>;
  rank: Scalars['Int'];
}

export interface RenewLockOutput {
  __typename: 'RenewLockOutput';
  lockSucceeded: Scalars['Boolean'];
  reason: Maybe<LockRejectReason>;
}

export interface Resolution {
  __typename: 'Resolution';
  height: Scalars['Int'];
  width: Scalars['Int'];
}

export interface ResolutionInput {
  height: Scalars['Int'];
  width: Scalars['Int'];
}

export interface ReviewMetric extends LabelerMetric {
  __typename: 'ReviewMetric';
  date: Scalars['DateTime'];
  /** Number of elements used to calculate the average quality */
  elementsCount: Scalars['Int'];
  /** the id of the labeler */
  labelerId: Scalars['ID'];
  type: ReviewScore;
  /** the id of the reviewer */
  userId: Scalars['ID'];
  /** Average quality for labeler label versus reviewer label on given granularity */
  value: Scalars['Float'];
}

export enum ReviewScore {
  PairedReviewScore = 'PairedReviewScore',
}

export interface ReviewedLabel {
  id: Scalars['ID'];
}

export enum Right {
  CAN_ACCESS_SMART_TOOLS = 'CAN_ACCESS_SMART_TOOLS',
  CAN_LABEL = 'CAN_LABEL',
  CREATE_AUDIO = 'CREATE_AUDIO',
  CREATE_FRAME = 'CREATE_FRAME',
  CREATE_IMAGE = 'CREATE_IMAGE',
  CREATE_LLM_RLHF = 'CREATE_LLM_RLHF',
  CREATE_PDF = 'CREATE_PDF',
  CREATE_TEXT = 'CREATE_TEXT',
  CREATE_TIMESERIES = 'CREATE_TIMESERIES',
  SEE_LICENSE_BANNER = 'SEE_LICENSE_BANNER',
  SEE_UPGRADE_BUTTON = 'SEE_UPGRADE_BUTTON',
  UPLOAD_CLOUD_DATA = 'UPLOAD_CLOUD_DATA',
  UPLOAD_LOCAL_DATA = 'UPLOAD_LOCAL_DATA',
  USE_API = 'USE_API',
  USE_API_PRIORITY = 'USE_API_PRIORITY',
}

export interface RoleData {
  projectID: Scalars['ID'];
  role: ProjectRole;
  userID: Scalars['ID'];
}

export interface RunPluginData {
  assetId: Scalars['ID'];
  label: RunPluginLabelData;
  projectId: Scalars['ID'];
}

export interface RunPluginLabelData {
  jsonResponse: Scalars['String'];
}

export interface SearchUserWhere {
  searchValue?: InputMaybe<Scalars['String']>;
  showSuspendedUsers?: InputMaybe<Scalars['Boolean']>;
}

export interface SendEmailToSupportData {
  content: Scalars['String'];
  subject: Scalars['String'];
}

export interface SetHoneypotData {
  honeypotValue: Scalars['Boolean'];
}

export interface SignUpData {
  email: Scalars['String'];
}

export interface SkippedMetric extends AssetMetric {
  __typename: 'SkippedMetric';
  date: Scalars['DateTime'];
  /** Number of assets skipped after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export enum SplitOption {
  MERGED_FOLDER = 'MERGED_FOLDER',
  SPLITTED_FOLDER = 'SPLITTED_FOLDER',
}

export enum Status {
  LABELED = 'LABELED',
  ONGOING = 'ONGOING',
  REVIEWED = 'REVIEWED',
  TODO = 'TODO',
  TO_REVIEW = 'TO_REVIEW',
}

export interface StatusMetric extends AssetMetric {
  __typename: 'StatusMetric';
  date: Scalars['DateTime'];
  status: Maybe<Status>;
  /** Number of assets with given status created after after date d on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface Subscription {
  __typename: 'Subscription';
  _: Maybe<Scalars['Boolean']>;
  /** Subscription allowing to receive updates on every action updating or creating a label. */
  dataConnectionUpdated: Maybe<DataConnection>;
  /** Subscription allowing to receive updates on every action updating or creating a label. */
  dataIntegrationUpdated: Maybe<DataIntegrationUpdatedSubscriptionData>;
  /** Subscription allowing to receive updates on every action updating or creating a label. */
  labelCreatedOrUpdated: Maybe<Label>;
  /**
   * Notify the client that a partial chat item content has been received using streaming mechanism
   * for a displayed asset/label couple.
   */
  llmChatItemPartialContentReceived: LLMChatItemPartialContentReceivedSubscriptionData;
  /**
   * Notify the client that a temporary chat item has been created to receive content progressively
   * using streaming mechanism.
   */
  llmTemporaryChatItemCreated: LLMTemporaryChatItemCreatedSubscriptionData;
  /** Subscription allowing to receive updates on every action updating or creating a notification. */
  notificationCreatedOrUpdated: Maybe<Notification>;
  /** Subscription allowing to receive updates on every action updating or creating a pendingRequest. */
  pendingRequestCreatedOrUpdated: Scalars['Int'];
}

export interface SubscriptiondataConnectionUpdatedArgs {
  projectID: Scalars['ID'];
}

export interface SubscriptiondataIntegrationUpdatedArgs {
  dataIntegrationID: Scalars['ID'];
}

export interface SubscriptionlabelCreatedOrUpdatedArgs {
  projectID: Scalars['ID'];
}

export interface SubscriptionllmChatItemPartialContentReceivedArgs {
  assetId: Scalars['ID'];
  labelId: Scalars['ID'];
}

export interface SubscriptionllmTemporaryChatItemCreatedArgs {
  assetId: Scalars['ID'];
  labelId: Scalars['ID'];
}

export interface SubscriptionnotificationCreatedOrUpdatedArgs {
  userID: Scalars['ID'];
}

export interface SubscriptionpendingRequestCreatedOrUpdatedArgs {
  input?: InputMaybe<PendingRequestCreatedOrUpdatedInput>;
}

/**
 * Tag is a tag, i.e., some small descriptive text enclosed within a coloured chip, which can be created organisation-wide and can then be checked for one or more projects.
 *
 * Tags are meant to help the users, especially administrators, sort and search for projects.
 */
export interface Tag {
  __typename: 'Tag';
  _: Maybe<Scalars['Boolean']>;
  /** Optional list of project identifiers in the organisation for which this tag is checked */
  checkedForProjects: Maybe<Array<Scalars['ID']>>;
  /** Optional colour of the tag, format: # plus six hexadecimal case insensitive digits, e.g. `#ffffff` */
  color: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Required label of the tag */
  label: Scalars['String'];
  /** Identifier of the organization for which this tag exists */
  organizationId: Scalars['ID'];
}

export interface TagData {
  color?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
}

export interface TagWhere {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['ID']>;
}

export interface TestDataIntegrationData {
  awsAccessPointARN?: InputMaybe<Scalars['String']>;
  awsRoleARN?: InputMaybe<Scalars['String']>;
  awsRoleExternalId?: InputMaybe<Scalars['String']>;
  azureConnectionURL?: InputMaybe<Scalars['String']>;
  azureTenantId?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<DataIntegrationPlatform>;
}

export interface TimeLabelerMetric extends LabelerMetric {
  __typename: 'TimeLabelerMetric';
  action: LabelerAction;
  date: Scalars['DateTime'];
  userId: Scalars['ID'];
  /** Total duration on action for user on given granularity */
  value: Maybe<Scalars['Int']>;
}

export interface TranscriptionAnnotation extends Annotation {
  __typename: 'TranscriptionAnnotation';
  annotationValue: Maybe<TranscriptionAnnotationValue>;
  chatItemId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  job: Scalars['String'];
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface TranscriptionAnnotationValue extends AnnotationValue {
  __typename: 'TranscriptionAnnotationValue';
  id: Scalars['ID'];
  isPrediction: Scalars['Boolean'];
  text: Scalars['String'];
}

export interface UpdateApiKeyPropertiesData {
  revoked: Scalars['Boolean'];
}

export interface UpdateAssetResolutionInput {
  assetId: Scalars['ID'];
  pageResolutions?: InputMaybe<Array<PageResolutionInput>>;
  resolution?: InputMaybe<ResolutionInput>;
}

export interface UpdateAssetResolutionOutput {
  __typename: 'UpdateAssetResolutionOutput';
  id: Scalars['ID'];
}

export interface UpdateChatItemData {
  content?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
}

export interface UpdateDataConnectionInput {
  /** Exclude filter on assets */
  exclude?: InputMaybe<Array<Scalars['String']>>;
  /** Include filter on assets */
  include?: InputMaybe<Array<Scalars['String']>>;
  /** Prefix filter on assets */
  prefix?: InputMaybe<Scalars['String']>;
  /** List of selected folders in scope of connection */
  selectedFolders?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface UpdateModelInput {
  credentials?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
}

export interface UpdateOrganizationSettingsInput {
  showUserPerformance?: InputMaybe<Scalars['Boolean']>;
}

export interface UpdateOrganizationSettingsPayload {
  __typename: 'UpdateOrganizationSettingsPayload';
  organizationId: Scalars['ID'];
  settings: OrganizationSetting;
}

export interface UpdatePasswordData {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  oldPassword: Scalars['String'];
}

export interface UpdatePluginRunnerData {
  /** Actions for which the plugin should be executed */
  handlerTypes?: InputMaybe<Array<PluginHandlerType>>;
  pluginName: Scalars['String'];
}

export interface UpdateProjectAnonymizationInput {
  id: Scalars['ID'];
  shouldAnonymize: Scalars['Boolean'];
}

export interface UpdateProjectModelInput {
  configuration?: InputMaybe<Scalars['JSON']>;
}

export interface UpdateProjectWorkflowStepInput {
  assignees?: InputMaybe<Array<Scalars['ID']>>;
  consensusCoverage?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  numberOfExpectedLabelsForConsensus?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  stepCoverage?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProjectWorkflowStepType>;
}

export interface UpdateTagData {
  color?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
}

export interface UpdateTagReturn {
  __typename: 'UpdateTagReturn';
  affectedRows: Scalars['Int'];
  updatedTag: Tag;
}

export interface UpdateWebhookData {
  /** Actions for which the webhook should be called */
  handlerTypes?: InputMaybe<Array<PluginHandlerType>>;
  /** Security Authorization header for calls made to the webhook */
  header?: InputMaybe<Scalars['String']>;
  pluginName: Scalars['String'];
  /** Url of the webhook */
  webhookUrl: Scalars['String'];
}

export interface UploadAssetsWarnings {
  __typename: 'UploadAssetsWarnings';
  numberOfUploadedAssets: Maybe<Scalars['Int']>;
  warnings: Maybe<Array<Maybe<Warning>>>;
}

export enum UploadType {
  FRAME_VIDEO = 'FRAME_VIDEO',
  GEO_SATELLITE = 'GEO_SATELLITE',
  NATIVE_VIDEO = 'NATIVE_VIDEO',
  /** @deprecated The VIDEO upload type is deprecated, please use NATIVE_VIDEO or FRAME_VIDEO. */
  VIDEO = 'VIDEO',
}

export interface UsageMetrics {
  __typename: 'UsageMetrics';
  /** Number of label annotations */
  numberOfAnnotations: Scalars['Int'];
  /** Number of labeled assets */
  numberOfLabeledAssets: Scalars['Int'];
  /** Seconds spent on labeling during the license validity period */
  secondsSpentOnLabeling: Scalars['Int'];
}

export interface UsageMetricsAt {
  __typename: 'UsageMetricsAt';
  /** Date ISO 8601 representing a month or a day, depending on granularity */
  date: Scalars['String'];
  metrics: UsageMetrics;
}

export interface UsageMetricsOnPeriod {
  __typename: 'UsageMetricsOnPeriod';
  metrics: UsageMetrics;
  /** Period on which metrics are aggregated */
  period: Period;
}

export interface UsageMetricsOverTime {
  __typename: 'UsageMetricsOverTime';
  details: Array<UsageMetricsAt>;
  granularity: OrganizationUsageMetricsGranularity;
  total: UsageMetricsOnPeriod;
}

/** Users belong to an organization. They can create projects within that organization. */
export interface User {
  __typename: 'User';
  _: Maybe<Scalars['Boolean']>;
  /** Activated users can log in to Kili Technology. Deactivated users cannot log in to Kili Technology. */
  activated: Scalars['Boolean'];
  anonymizedEmail: Scalars['String'];
  apiKeys: Maybe<Array<ApiKey>>;
  /** User ID in Auth0 database */
  auth0Id: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Email works as a unique identifier for a user. */
  email: Scalars['String'];
  /** Firstname of the user */
  firstname: Maybe<Scalars['String']>;
  /** The user has completed the Labeling Product Tour */
  hasCompletedLabelingTour: Scalars['Boolean'];
  /** The highest role in a project of the user is the role with the most rights ('Admin' > 'Team Manager' > 'Reviewer' > 'Labeler' > 'NA' (not a member of any projects)) attained by the user as a project user. */
  highestRoleInProject: Scalars['String'];
  /** Technical field. */
  highestRoleInProjectCompute: Maybe<Scalars['String']>;
  /** The user accepts to be contacted by Kili Technology. */
  hubspotSubscriptionStatus: Maybe<HubspotSubscriptionStatus>;
  id: Scalars['ID'];
  /** Defines if the user is a service account */
  isServiceAccount: Scalars['Boolean'];
  /** Lastname of the user */
  lastname: Maybe<Scalars['String']>;
  /** matchingDomainOrganizations that match domain of the user's email */
  matchingDomainOrganizations: Array<OrganizationMatchingDomain>;
  /**
   * Each user can belong to one organization.
   * @deprecated Users will be able to belong to multiple organizations
   */
  organization: Maybe<Organization>;
  organizationId: Scalars['String'];
  /**
   * Within an organization, a user can either be:
   *
   * - organization managers (ADMIN) can add/delete/update users within an organization
   *
   * - users (USER) have no role within the organization
   */
  organizationRole: Maybe<OrganizationRole>;
  organizations: Maybe<Array<Organization>>;
  /** The role of a User within a project are defined by a ProjectUser. */
  projectUsers: Array<ProjectUser>;
  /** The list of user rights. */
  rights: Array<Right>;
  /** The status of a User */
  status: Maybe<UserStatus>;
  updatedAt: Scalars['DateTime'];
}

export interface UserData {
  activated?: InputMaybe<Scalars['Boolean']>;
  apiKey?: InputMaybe<Scalars['String']>;
  auth0Id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  hasCompletedLabelingTour?: InputMaybe<Scalars['Boolean']>;
  hubspotSubscriptionStatus?: InputMaybe<HubspotSubscriptionStatus>;
  lastname?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  organizationRole?: InputMaybe<OrganizationRole>;
  status?: InputMaybe<UserStatus>;
}

export enum UserStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
}

export interface UserWhere {
  activated?: InputMaybe<Scalars['Boolean']>;
  apiKey?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  idIn?: InputMaybe<Array<Scalars['ID']>>;
  lastname?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationWhere>;
  status?: InputMaybe<UserStatus>;
}

export interface ValidateDataDifferencesWhere {
  connectionId: Scalars['ID'];
  type: DataDifferenceType;
}

export interface Vertice {
  __typename: 'Vertice';
  x: Scalars['Float'];
  y: Scalars['Float'];
}

export interface VerticeInput {
  x: Scalars['Float'];
  y: Scalars['Float'];
}

export interface VideoAnnotation {
  /** List of intervals where the annotation is present */
  frames: Array<FrameInterval>;
  id: Scalars['ID'];
  job: Scalars['String'];
  /** List of manually set values for the annotation */
  keyAnnotations: Maybe<Array<VideoKeyAnnotation>>;
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface VideoClassificationAnnotation extends Annotation, VideoAnnotation {
  __typename: 'VideoClassificationAnnotation';
  frames: Array<FrameInterval>;
  id: Scalars['ID'];
  job: Scalars['String'];
  keyAnnotations: Maybe<Array<VideoClassificationKeyAnnotation>>;
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface VideoClassificationKeyAnnotation extends VideoKeyAnnotation {
  __typename: 'VideoClassificationKeyAnnotation';
  annotationValue: ClassificationAnnotationValue;
  frame: Scalars['Int'];
  id: Scalars['ID'];
}

export interface VideoKeyAnnotation {
  annotationValue: AnnotationValue;
  frame: Scalars['Int'];
  id: Scalars['ID'];
}

export interface VideoObjectDetectionAnnotation extends Annotation, VideoAnnotation {
  __typename: 'VideoObjectDetectionAnnotation';
  /** Category of the object */
  category: Scalars['String'];
  frames: Array<FrameInterval>;
  id: Scalars['ID'];
  job: Scalars['String'];
  keyAnnotations: Maybe<Array<VideoObjectDetectionKeyAnnotation>>;
  labelId: Scalars['ID'];
  /** Object id (Necessary to keep old mids) */
  mid: Scalars['String'];
  /** Name of the object */
  name: Maybe<Scalars['String']>;
  path: Array<Array<Scalars['String']>>;
}

export interface VideoObjectDetectionKeyAnnotation extends VideoKeyAnnotation {
  __typename: 'VideoObjectDetectionKeyAnnotation';
  annotationValue: ObjectDetectionAnnotationValue;
  frame: Scalars['Int'];
  id: Scalars['ID'];
}

export interface VideoTranscriptionAnnotation extends Annotation, VideoAnnotation {
  __typename: 'VideoTranscriptionAnnotation';
  frames: Array<FrameInterval>;
  id: Scalars['ID'];
  job: Scalars['String'];
  keyAnnotations: Maybe<Array<VideoTranscriptionKeyAnnotation>>;
  labelId: Scalars['ID'];
  path: Array<Array<Scalars['String']>>;
}

export interface VideoTranscriptionKeyAnnotation extends VideoKeyAnnotation {
  __typename: 'VideoTranscriptionKeyAnnotation';
  annotationValue: TranscriptionAnnotationValue;
  frame: Scalars['Int'];
  id: Scalars['ID'];
}

export interface Warning {
  __typename: 'Warning';
  details: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['String']>;
  lineNumber: Maybe<Scalars['Int']>;
  type: Maybe<WarningTypes>;
}

export enum WarningTypes {
  DUPLICATED_ID = 'DUPLICATED_ID',
  EMPTY_ARRAY = 'EMPTY_ARRAY',
  FAILED_ASSET_CREATION = 'FAILED_ASSET_CREATION',
  INPUT_UNDEFINED = 'INPUT_UNDEFINED',
  MAX_INPUT_SIZE = 'MAX_INPUT_SIZE',
  MAX_PROJECT_SIZE = 'MAX_PROJECT_SIZE',
  MISSING_ID = 'MISSING_ID',
  UNSPECIFIED_ERROR = 'UNSPECIFIED_ERROR',
  UNSUPPORTED_TYPE = 'UNSUPPORTED_TYPE',
  WRONG_JSON_FORMAT = 'WRONG_JSON_FORMAT',
}
