import { type ProjectQueueState } from './types';

import { type ZustandStore } from '../index';

export const projectQueue = (state: ZustandStore): ProjectQueueState => state.projectQueue;

export type ProjectQueueSelection = { idNotIn: string[] } | { idIn: string[] };

export const projectQueueSelection = (state: ZustandStore): ProjectQueueSelection => {
  const { areAllAssetsSelected, selectedAssetIds, unselectedAssetIds } = state.projectQueue;
  return areAllAssetsSelected ? { idNotIn: unselectedAssetIds } : { idIn: selectedAssetIds };
};
