import { type ObjectAnnotationMarker, Tool } from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import { isEmpty } from 'lodash';
import { isEqual as _isEqual } from 'lodash/fp';

import {
  type ObjectDetectionAnnotationValue,
  type VideoObjectDetectionKeyAnnotation,
  type VideoObjectDetectionAnnotation,
  type VideoClassificationAnnotation,
  type FrameInterval,
  type VideoClassificationKeyAnnotation,
  type VideoTranscriptionKeyAnnotation,
  type ClassificationAnnotation,
} from '@/__generated__/globalTypes';
import { interpolatePoint, interpolateRectangle } from '@/components/asset-ui/Frame/helpers';
import {
  type Annotation,
  type VideoAnnotationValue,
  type VideoKeyAnnotation,
  type BlankKeyAnnotation,
} from '@/graphql/annotations/types';
import { getLabelId } from '@/redux/asset-label/helpers/getLabelId';
import { projectJobs } from '@/redux/selectors';
import { type KiliAnnotation } from '@/services/jobs/setResponse';
import { store } from '@/store';
import { useStore } from '@/zustand';

import { findAnnotationInCache } from './cache/findAnnotationInCache';
import { findAnnotationsInCache } from './cache/findAnnotationsInCache';
import {
  createObjectDetectionAnnotationValue,
  createVertice,
} from './data/factories/createVideoObjectDetectionKeyAnnotation';
import { isBlankKeyAnnotation } from './data/validators/isBlankKeyAnnotation';
import { isClassificationAnnotation } from './data/validators/isClassificationAnnotation';
import { isVideoClassificationAnnotation } from './data/validators/isVideoClassificationAnnotation';
import { isVideoObjectDetectionAnnotation } from './data/validators/isVideoObjectDetectionAnnotation';
import { isVideoObjectDetectionKeyAnnotation } from './data/validators/isVideoObjectDetectionKeyAnnotation';

import { deleteAnnotationsInCache } from '../actions';

const interpolateAnnotationBetweenTwoKeyFramesSplit = (
  previousKeyAnnotation: VideoObjectDetectionKeyAnnotation,
  nextKeyFrameAnnotation: VideoObjectDetectionKeyAnnotation,
  frame: number,
  jobName: string,
): ObjectDetectionAnnotationValue => {
  const weight =
    (frame - previousKeyAnnotation.frame) /
    (nextKeyFrameAnnotation.frame - previousKeyAnnotation.frame);
  const tool = projectJobs(store.getState())[jobName].tools?.[0];
  if (tool === Tool.RECTANGLE) {
    const { imageHeight, imageWidth } = useStore.getState().labelInterface;
    const interpolatedVertices = interpolateRectangle({
      height: imageHeight,
      nextVertices: nextKeyFrameAnnotation.annotationValue.vertices[0][0],
      previousVertices: previousKeyAnnotation.annotationValue.vertices[0][0],
      weight,
      width: imageWidth,
    });
    return createObjectDetectionAnnotationValue({
      id: '', // No key annotation associated
      isPrediction: false,
      vertices: [[interpolatedVertices]],
    });
  }
  if (tool === Tool.MARKER) {
    const interpolatedPoint = interpolatePoint(
      previousKeyAnnotation.annotationValue.vertices[0][0][0],
      nextKeyFrameAnnotation.annotationValue.vertices[0][0][0],
      weight,
    );
    return createObjectDetectionAnnotationValue({
      id: '', // No key annotation associated
      isPrediction: false,
      vertices: [[[interpolatedPoint]]],
    });
  }
  return previousKeyAnnotation.annotationValue;
};

export const getAnnotationValueForFrame = ({
  frame,
  keyAnnotations,
  jobName,
}: {
  frame: number;
  jobName: string;
  keyAnnotations: VideoKeyAnnotation[];
}): VideoAnnotationValue => {
  const keyAnnotationAtFrame = keyAnnotations.find(keyAnnotation => keyAnnotation.frame === frame);

  if (keyAnnotationAtFrame) {
    return keyAnnotationAtFrame.annotationValue;
  }

  const previousKeyAnnotation = keyAnnotations
    .filter(keyAnnotation => keyAnnotation.frame <= frame)
    .reduce((acc, curr) => {
      if (curr.frame > acc.frame) {
        return curr;
      }
      return acc;
    });

  if (isVideoObjectDetectionKeyAnnotation(previousKeyAnnotation)) {
    const allNextKeyAnnotations = keyAnnotations.filter(
      keyAnnotation =>
        isVideoObjectDetectionKeyAnnotation(keyAnnotation) && keyAnnotation.frame > frame,
    ) as VideoObjectDetectionKeyAnnotation[];

    if (allNextKeyAnnotations.length) {
      const nextKeyAnnotation = allNextKeyAnnotations.reduce((acc, curr) => {
        if (curr.frame < acc.frame) {
          return curr;
        }
        return acc;
      });
      return interpolateAnnotationBetweenTwoKeyFramesSplit(
        previousKeyAnnotation,
        nextKeyAnnotation,
        frame,
        jobName,
      );
    }
  }

  return previousKeyAnnotation.annotationValue;
};

export const getIdForNewAnnotation = generateHash;

export const getLabelIdAndIdForNewAnnotation = () => {
  const id = getIdForNewAnnotation();
  const labelId = getLabelId();
  return { id, labelId };
};

export const computeVerticesFromKiliAnnotations = (annotations: KiliAnnotation[]) => {
  if (annotations.length === 0) {
    throw new Error('No annotations to convert');
  }

  if (annotations[0]?.boundingPoly) {
    return annotations.map(annotation => {
      const { boundingPoly } = annotation;
      if (!boundingPoly) throw new Error('Bounding poly is undefined');
      return boundingPoly.map(poly =>
        poly.normalizedVertices.map(({ x, y }) => ({ __typename: 'Vertice' as const, x, y })),
      );
    });
  }

  if (annotations.length > 1) {
    throw new Error('A point annotation should have only one annotation');
  }

  const point = (annotations[0] as ObjectAnnotationMarker)?.point;
  if (point) {
    return [[[createVertice({ x: point.x, y: point.y })]]];
  }
  throw new Error(
    'For video object detection, annotation should be a bounding box or a point, else we cannot compute vertices',
  );
};

export const filterExistingRemaining = <T extends unknown[]>(
  elements: T,
  criteria: (element: typeof elements[number]) => boolean,
) => {
  return (
    elements.reduce(
      (
        cum: {
          deletedElements: typeof elements[number][];
          remainingElements: typeof elements[number][];
        },
        element,
      ): {
        deletedElements: typeof elements[number][];
        remainingElements: typeof elements[number][];
      } => {
        const shouldKeep = criteria(element);
        if (shouldKeep) {
          return {
            deletedElements: cum.deletedElements,
            remainingElements: [...cum.remainingElements, element],
          };
        }
        return {
          deletedElements: [...cum.deletedElements, element],
          remainingElements: cum.remainingElements,
        };
      },
      { deletedElements: [], remainingElements: [] },
    ) || { deletedElements: [], remainingElements: [] }
  );
};

const splitArrayByDelimiter = (arr: string[], delimiter: string): string[][] => {
  const result: string[][] = [];
  let currentSubarray: string[] = [];

  arr.forEach(item => {
    if (item === delimiter) {
      /* If the current item is the delimiter, push the current subarray to the result */
      result.push(currentSubarray);
      currentSubarray = [];
    } else {
      currentSubarray.push(item);
    }
  });

  /* Push the last subarray, even if it doesn't end with a delimiter */
  if (currentSubarray.length > 0) result.push(currentSubarray);

  return result;
};

const convertPathToSplitStructurePath = (
  delimitedPath: string[][],
  path: string[][],
): {
  convertedPath: string[][];
} => {
  const splitPath = delimitedPath.shift();

  if (splitPath) {
    const filteredPath = splitPath.filter(
      p => p !== 'children' && p !== 'categories' && p !== 'objects',
    );

    if (filteredPath.length === 3) {
      /* Format : ['OBJECT_DETECTION_JOB', 'BBOX_...', '1231231232132-12312'] */
      const [jobName, category, mid] = filteredPath;
      const annotation = findAnnotationInCache(
        (videoAnnotation): videoAnnotation is VideoObjectDetectionAnnotation =>
          isVideoObjectDetectionAnnotation(videoAnnotation) &&
          videoAnnotation.job === jobName &&
          videoAnnotation.mid === mid &&
          videoAnnotation.category === category &&
          _isEqual(videoAnnotation.path, path),
      );
      if (!annotation) throw new Error('Annotation not found');

      const convertedPath = [...annotation.path, [annotation.id, annotation.category]];
      if (delimitedPath.length === 1)
        return {
          convertedPath,
        };
      return convertPathToSplitStructurePath(delimitedPath, convertedPath);
    }
    if (filteredPath.length === 2) {
      /* Format : ['CLASSIFICATION_JOB', 'CHOICE_1_...'] */
      const [jobName, category] = filteredPath;
      const annotation = findAnnotationInCache(
        (a): a is ClassificationAnnotation | VideoClassificationAnnotation =>
          (isVideoClassificationAnnotation(a) || isClassificationAnnotation(a)) &&
          a.job === jobName &&
          _isEqual(a.path, path),
      );
      if (!annotation) throw new Error('Annotation not found');

      const convertedPath = [...annotation.path, [annotation.id, category]];
      if (delimitedPath.length === 1)
        return {
          convertedPath,
        };
      return convertPathToSplitStructurePath(delimitedPath, convertedPath);
    }
  }

  throw new Error('Could not get first element of path');
};

export const getChildrenPath = (path: string[]) => {
  const delimitedPath = splitArrayByDelimiter(path, 'children');
  const { convertedPath } = convertPathToSplitStructurePath(delimitedPath, []);
  return convertedPath;
};

export const getParentAnnotation = (path: string[][]) => {
  const parentId = [...path].reverse()?.[0]?.[0];
  if (!parentId) throw new Error('Child annotation must have a parent id in their path');
  return findAnnotationInCache(
    annotation => annotation.id === parentId && isClassificationAnnotation(annotation),
  );
};

export const getParentAnnotationVideo = (path: string[][], currentFrame: number) => {
  const parentId = [...path].reverse()?.[0]?.[0];
  if (!parentId) throw new Error('Child annotation must have a parent id in their path');
  const parentAnnotation = findAnnotationInCache(annotation => annotation.id === parentId) as
    | VideoClassificationAnnotation
    | VideoObjectDetectionAnnotation
    | undefined;

  if (
    parentAnnotation &&
    !parentAnnotation.frames.some(frame => frame.start <= currentFrame && currentFrame <= frame.end)
  )
    throw new Error(
      'Cannot change an annotation whose parent is not present on the modified frame',
    );
  return parentAnnotation;
};

/**
 * Intersect 2 lists of sorted frame intervals. For this we use a 2 pointers algorithm.
 * @param list1 The first list of sorted frame intervals
 * @param list2 The second list of sorted frame intervals
 * @returns A list of sorted frame intervals which are the intersection of the 2 lists
 */
export const intersectListOfSortedFrameIntervals = (
  list1: FrameInterval[],
  list2: FrameInterval[],
) => {
  const intersections = [];
  let i = 0;
  let j = 0;

  while (i < list1.length && j < list2.length) {
    const start = Math.max(list1[i].start, list2[j].start);
    const end = Math.min(list1[i].end, list2[j].end);

    if (start <= end) {
      intersections.push({ __typename: 'FrameInterval' as const, end, start });
    }

    if (list1[i].end < list2[j].end) {
      i += 1;
    } else if (list1[i].end > list2[j].end) {
      j += 1;
    } else {
      // if the ends are equal
      i += 1;
      j += 1;
    }
  }

  return intersections;
};

/**
 * Make the union of 2 lists of sorted frame intervals. For this we use a 2 pointers algorithm.
 * @param list1 The first list of sorted frame intervals
 * @param list2 The second list of sorted frame intervals
 * @returns A list of sorted frame intervals which are the union of the 2 lists
 */
export const unionListOfSortedFrameIntervals = (list1: FrameInterval[], list2: FrameInterval[]) => {
  const union = [];
  let i = 0;
  let j = 0;

  let currentInterval = null;

  while (i < list1.length && j < list2.length) {
    if (list1[i].end < list2[j].start) {
      if (currentInterval) {
        union.push(currentInterval);
      }
      currentInterval = list1[i];
      i += 1;
    } else if (list2[j].end < list1[i].start) {
      if (currentInterval) {
        union.push(currentInterval);
      }
      currentInterval = list2[j];
      j += 1;
    } else {
      const start = Math.min(list1[i].start, list2[j].start);
      const end = Math.max(list1[i].end, list2[j].end);
      currentInterval = { __typename: 'FrameInterval' as const, end, start };

      if (list1[i].end < list2[j].end) {
        i += 1;
      } else if (list1[i].end > list2[j].end) {
        j += 1;
      } else {
        // if the ends are equal
        i += 1;
        j += 1;
      }
    }
  }

  if (currentInterval) {
    union.push(currentInterval);
  }

  while (i < list1.length) {
    if (list1[i].start > union[union.length - 1].end) {
      union.push(list1[i]);
    }
    i += 1;
  }

  while (j < list2.length) {
    if (list2[j].start > union[union.length - 1].end) {
      union.push(list2[j]);
    }
    j += 1;
  }

  return union;
};

/**
 * Make the union of 2 lists of sorted frame intervals. For this we use a 2 pointers algorithm.
 * @param parentList The parent list (the one that entirely contains the child list)
 * @param childList The list for which we want the complement in the parent list
 * @returns The complement of the child list in the parent list
 * @throws An error if the child list is not contained in the parent list
 *
 * Example: If we have a parentList = [{start: 0, end: 4}, {start: 9, end: 9}, {start: 12, end: 20}]
 * and a childList = [{start: 2, end: 3}, {start: 12, end: 14}] it will return
 * [{start: 0, end: 1}, {start: 4, end: 4}, {start: 9, end: 9}, {start: 15, end: 20}]
 */
export const complementListOfSortedFrameIntervals = (
  parentList: FrameInterval[],
  childList: FrameInterval[],
) => {
  const complement = [];
  let i = 0;
  let j = 0;

  while (i < parentList.length) {
    // If there's no more intervals in childList, or the current interval in parentList is before the current interval in childList
    if (j >= childList.length || parentList[i].end < childList[j].start) {
      complement.push(parentList[i]);
      i += 1;
    } else {
      let { start } = parentList[i];
      // While the current interval in parentList contains intervals from childList
      while (j < childList.length && parentList[i].end >= childList[j].start) {
        if (start < childList[j].start) {
          complement.push({
            __typename: 'FrameInterval' as const,
            end: childList[j].start - 1,
            start,
          });
        }
        if (parentList[i].end < childList[j].end) {
          throw new Error('Child interval is not fully contained in a parent interval');
        }
        start = childList[j].end + 1;
        j += 1;
      }
      // If there's remaining part in the current interval in parentList after excluding all intervals from childList
      if (start <= parentList[i].end) {
        complement.push({
          __typename: 'FrameInterval' as const,
          end: parentList[i].end,
          start,
        });
      }
      i += 1;
    }
  }

  // If there are still intervals in childList that are not checked
  if (j < childList.length) {
    throw new Error('Some child intervals are not fully contained in any parent interval');
  }

  return complement;
};

/**
 * For a given category it computes the frame presence from keyAnnotations as if we did not have any limitation of frame presence.
 * For instance if we have keyframes at 0 A, 5 B, 9 A, 10 B
 * it will return for category B [{start: 5, end: 8}, {start: 10, end: Number.MAX_SAFE_INTEGER}]
 * @param keyAnnotations A list of key annotations for a given annotation
 * @param category The category for which we want to compute the frame presence
 * @returns A list of frame intervals for the given category
 */
const getFrameIntervalsForCategoryFromKeyAnnotations = (
  keyAnnotations: VideoClassificationKeyAnnotation[],
  category: string,
) => {
  const frameIntervalsForCategoryFromKeyAnnotations: FrameInterval[] = [];
  [...keyAnnotations]
    .sort((a, b) => a.frame - b.frame)
    .forEach(keyAnnotation => {
      if (keyAnnotation.annotationValue.categories.includes(category)) {
        if (
          isEmpty(frameIntervalsForCategoryFromKeyAnnotations) ||
          frameIntervalsForCategoryFromKeyAnnotations[
            frameIntervalsForCategoryFromKeyAnnotations.length - 1
          ].end !== Number.MAX_SAFE_INTEGER
        ) {
          frameIntervalsForCategoryFromKeyAnnotations.push({
            __typename: 'FrameInterval' as const,
            end: Number.MAX_SAFE_INTEGER,
            start: keyAnnotation.frame,
          });
        }
      } else if (!isEmpty(frameIntervalsForCategoryFromKeyAnnotations)) {
        if (
          frameIntervalsForCategoryFromKeyAnnotations[
            frameIntervalsForCategoryFromKeyAnnotations.length - 1
          ].end === Number.MAX_SAFE_INTEGER
        ) {
          frameIntervalsForCategoryFromKeyAnnotations[
            frameIntervalsForCategoryFromKeyAnnotations.length - 1
          ].end = keyAnnotation.frame - 1;
        }
      }
    });
  return frameIntervalsForCategoryFromKeyAnnotations;
};

/**
 * It gives the default frame presence for an annotation which corresponds to the length of the video.
 * @returns A list containing one frame interval which starts at 0 and ends at the last frame of the video
 */
export const getDefaultBaseFramePresence = () => {
  return [
    {
      __typename: 'FrameInterval' as const,
      end: useStore.getState().labelFrame.videoParams.numberOfFrames - 1,
      start: 0,
    },
  ];
};

/**
 * Adapt the base frame presence of a child annotation to its parent.
 * If the parent is an object detection, it will have the exact same frame presence.
 * If the parent is a classification, we need to check keyframes of it, to see when the parent category
 * is present, to adapt the child frame presence.
 * @param parentAnnotation A VideoObjectDetectionAnnotation or a VideoClassificationAnnotation which is the parent of the childAnnotation
 * @param parentCategoryName The name of the category to which the childAnnotation belongs
 * @returns A list of frame intervals which represent the base frame presence of the childAnnotation from the parent frame presence
 */
export const getBaseFramePresenceFromParent = (
  parentAnnotation: VideoObjectDetectionAnnotation | VideoClassificationAnnotation,
  parentCategoryName: string,
) => {
  if (isVideoObjectDetectionAnnotation(parentAnnotation)) {
    return parentAnnotation.frames;
  }

  // For classification we want to keep only frames where parentCategoryName is present
  // for this array, if we have
  // keyframe at 0 A, 5 B, 9 A, 10 B for A we get :
  // [{start: 0, end: 20}] -> [{start: 0, end: 4}, {start: 9, end: 9}]

  // To compute this, we could just first make a list of segments as if we did not know the frame intervals
  // (so just from key frames). And then we make the intersection of these segments with the known frame presence.

  const parentKeyAnnotations = parentAnnotation.keyAnnotations;
  if (!parentKeyAnnotations) return [];

  const frameIntervalsFromKeyAnnotations = getFrameIntervalsForCategoryFromKeyAnnotations(
    parentKeyAnnotations,
    parentCategoryName,
  );

  return intersectListOfSortedFrameIntervals(
    frameIntervalsFromKeyAnnotations,
    parentAnnotation.frames,
  );
};

/**
 * It computes the updated frame presence of a classification or transcription annotation from its base frame presence, its key annotations
 * and the already known blanks.
 * For this, it just compute an intersection between the base frame presence and the frame presence from key annotations and blanks.
 * @param baseFramePresence The base frame presence of the annotation (video length or presence from parent)
 * @param keyAnnotations The key annotations of the annotation
 * @param blankKeyAnnotations The list of frames where we know there is a blank for the annotation (represented as faked key annotations)
 * @returns The updated frame presence of the annotation
 */
export const getUpdatedFramePresenceForClassificationOrTranscription = (
  baseFramePresence: FrameInterval[],
  keyAnnotations: VideoClassificationKeyAnnotation[] | VideoTranscriptionKeyAnnotation[],
  blankKeyAnnotations: BlankKeyAnnotation[],
) => {
  const keyAnnotationsWithBlank = [...keyAnnotations, ...blankKeyAnnotations].sort(
    (a, b) => a.frame - b.frame,
  );

  const framePresenceFromKeyAnnotations = keyAnnotationsWithBlank.slice().reduce(
    (acc, curr, index, array) => {
      if (isBlankKeyAnnotation(curr)) {
        // If two blank key annotations are consecutive, we take into account only the first one
        if (!index || !isBlankKeyAnnotation(array[index - 1])) {
          acc[acc.length - 1].end = curr.frame - 1;
        }
      } else if (acc[acc.length - 1].end !== Number.MAX_SAFE_INTEGER) {
        acc.push({
          __typename: 'FrameInterval' as const,
          end: Number.MAX_SAFE_INTEGER,
          start: curr.frame,
        });
      }
      return acc;
    },
    [
      {
        __typename: 'FrameInterval' as const,
        end: Number.MAX_SAFE_INTEGER,
        start: keyAnnotationsWithBlank[0].frame,
      },
    ],
  );

  return intersectListOfSortedFrameIntervals(baseFramePresence, framePresenceFromKeyAnnotations);
};

/**
 * It computes the "fake" key annotations that represents a blank in the frame intervals.
 * The idea is to compare the real frames and the expected one if we had no blank
 * For instance if we have realFrames = [0, 3], [7, 9], [10, 11] and expectedFramePresence = [0, 5], [7, 9], [10, 12]
 * we will get blanks = [4, 12].
 * @param realFrames The real frames of the video annotation
 * @param baseFramePresence The base frame presence of the video annotation (video length or presence from parent)
 * @param keyAnnotations The key annotations of the video annotation
 * @returns A list of "fake" blank key annotations
 */
export const getBlankKeyAnnotations = (
  realFrames: FrameInterval[],
  baseFramePresence: FrameInterval[],
  keyAnnotations: VideoClassificationKeyAnnotation[] | VideoTranscriptionKeyAnnotation[],
): BlankKeyAnnotation[] => {
  const expectedFramePresence = getUpdatedFramePresenceForClassificationOrTranscription(
    baseFramePresence,
    keyAnnotations,
    [],
  );

  if (expectedFramePresence[0].start !== realFrames[0].start) {
    throw new Error("The beginning of real frames and expected frames don't match");
  }

  const blanks: BlankKeyAnnotation[] = [];
  let i = 0;
  let j = 0;

  while (i < realFrames.length && j < expectedFramePresence.length) {
    if (
      realFrames[i].start >= expectedFramePresence[j].start &&
      realFrames[i].end <= expectedFramePresence[j].end
    ) {
      // If the current segment of the expected frames includes the segment of real frames :
      // - we check if the start frame is the same. If not we add a blank annotation at the start of the expected segment
      // - then we check the end frames of all real segments that are included in the expected segment. For each of them
      // if the end frame is not the same we add a blank annotation after this end frame

      if (realFrames[i].start > expectedFramePresence[j].start) {
        // If the first segment of
        blanks.push({
          __typename: 'BlankKeyAnnotation' as const,
          frame: expectedFramePresence[j].start,
        });
      }

      while (i < realFrames.length && realFrames[i].end <= expectedFramePresence[j].end) {
        if (realFrames[i].end < expectedFramePresence[j].end) {
          blanks.push({
            __typename: 'BlankKeyAnnotation' as const,
            frame: realFrames[i].end + 1,
          });
        }
        i += 1;
      }

      j += 1;
    } else if (realFrames[i].start > expectedFramePresence[j].end) {
      // If the current segment of the expected frames is before the segment of real frames, we add a blank annotation at
      // the start frame of the expected segment and then we move until we find again an expected segment that includes
      // the real segment

      blanks.push({
        __typename: 'BlankKeyAnnotation' as const,
        frame: expectedFramePresence[j].start,
      });
      while (realFrames[i].start > expectedFramePresence[j].end) {
        j += 1;
      }
    } else {
      throw new Error('The real frames are supposed to be included in the expected frames');
    }
  }

  // If there are still expected segments that have not been processed, this is that there are expected segments after the
  // last real segment. So in this case we add a blank annotation at the start of the first expected segment that has not
  // been processed. We need to do this only if we have not already added a blank annotation at the end of the last real segment
  if (
    j < expectedFramePresence.length &&
    !(blanks.length && blanks[blanks.length - 1].frame === realFrames[i - 1].end + 1)
  ) {
    blanks.push({
      __typename: 'BlankKeyAnnotation' as const,
      frame: expectedFramePresence[j].start,
    });
  }

  return blanks;
};

export const deleteAnnotationChildren = (annotation: Annotation, category?: string) => {
  const children = findAnnotationsInCache(a =>
    a.path.some(
      p => p[0] === annotation.id && (typeof category === 'string' ? p[1] === category : true),
    ),
  );
  return deleteAnnotationsInCache(children.map(child => child.id));
};
