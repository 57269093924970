import { type Reference } from '@apollo/client';
import { type InMemoryCache } from '@apollo/client/cache';

import {
  type FrameInterval,
  type VideoObjectDetectionAnnotation,
} from '@/__generated__/globalTypes';
import { getLightAnnotationsFromCache } from '@/graphql/annotations/helpers/cache/getLightAnnotationsFromCache';
import { DEFAULT_LABEL_ID, getLabelId } from '@/redux/asset-label/helpers/getLabelId';
import { useStore } from '@/zustand';

import {
  type CategoryAndJobFragment,
  type FramesFragment,
  type JobFragment,
  type KeyAnnotationFragment,
  type KeyAnnotationsFragment,
  type VideoKeyAnnotationValueFragment,
} from './__generated__/fragments.graphql';
import {
  GQL_FRAGMENT_VIDEOANNOTATION_FRAMES,
  GQL_FRAGMENT_VIDEOANNOTATION_JOB,
  GQL_FRAGMENT_VIDEOANNOTATION_KEY_ANNOTATIONS,
  GQL_FRAGMENT_VIDEOANNOTATION_PATH,
  GQL_FRAGMENT_VIDEOKEYANNOTATION,
  GQL_FRAGMENT_VIDEOKEYANNOTATION_ANNOTATIONVALUE,
  GQL_FRAGMENT_VIDEOKEYANNOTATION_ID,
  GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_CATEGORY_AND_JOB,
  GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_NAME,
} from './fragments';
import { findAnnotationInCache } from './helpers/cache/findAnnotationInCache';
import { getAnnotationFromCache } from './helpers/cache/getAnnotationFromCache';
import { getAnnotationsFromCache } from './helpers/cache/getAnnotationsFromCache';
import { isVideoAnnotation } from './helpers/data/validators/isVideoAnnotation';
import { isVideoObjectDetectionAnnotation } from './helpers/data/validators/isVideoObjectDetectionAnnotation';
import { type VideoAnnotation, type VideoAnnotationValue, type VideoKeyAnnotation } from './types';

import { getApolloClient, getCacheIdFromGraphQLObject } from '../helpers';

export const isVideoKeyAnnotationExistingInCache = (cacheKeyAnnotationId: string) => {
  // Here this is a hack to check for existence using a small fragment, as Apollo is not giving any method to check for existence of a cache Id
  const client = getApolloClient();
  const fragment = client.readFragment({
    fragment: GQL_FRAGMENT_VIDEOKEYANNOTATION_ID,
    id: cacheKeyAnnotationId,
  });
  return !!fragment;
};

const getCacheIdsFromAnnotationId = (annotationId: string) => {
  const possibleTypes = [
    'ClassificationAnnotation',
    'TranscriptionAnnotation',
    'VideoClassificationAnnotation',
    'VideoTranscriptionAnnotation',
    'VideoObjectDetectionAnnotation',
  ];
  return possibleTypes.map(d => getCacheIdFromGraphQLObject({ __typename: d, id: annotationId }));
};

export const getVideoAnnotationsFromCache = (): VideoAnnotation[] => {
  return getAnnotationsFromCache().filter(isVideoAnnotation);
};

export const isAnnotationOfMidExisting = (mid: string) => {
  return (
    findAnnotationInCache(
      annotation => isVideoObjectDetectionAnnotation(annotation) && annotation.mid === mid,
    ) !== undefined
  );
};

export const getKeyAnnotationsFromCache = (cacheId: string): VideoKeyAnnotation[] => {
  const client = getApolloClient();
  const { keyAnnotations } =
    client.readFragment<KeyAnnotationsFragment>({
      fragment: GQL_FRAGMENT_VIDEOANNOTATION_KEY_ANNOTATIONS,
      id: `${cacheId}`,
    }) || {};

  if (!keyAnnotations) throw new Error(`No key annotation in cache for cache id ${cacheId}`);

  return keyAnnotations;
};

export const getKeyAnnotationFromCache = (keyAnnotationCacheId: string): VideoKeyAnnotation => {
  const client = getApolloClient();
  const keyAnnotation = client.readFragment<KeyAnnotationFragment>({
    fragment: GQL_FRAGMENT_VIDEOKEYANNOTATION,
    id: `${keyAnnotationCacheId}`,
  });

  if (!keyAnnotation)
    throw new Error(`No key annotation in cache for cache id ${keyAnnotationCacheId}`);

  return keyAnnotation;
};

export const getJobOfVideoAnnotationFromCache = (cacheId: string) => {
  const client = getApolloClient();
  const { job } =
    client.readFragment<JobFragment>({
      fragment: GQL_FRAGMENT_VIDEOANNOTATION_JOB,
      id: `${cacheId}`,
    }) || {};

  if (!job) throw new Error(`No job name in cache for cache id ${cacheId}`);
  return job;
};

export const getFrameIntervalsFromCache = (cacheId: string) => {
  const client = getApolloClient();
  const { frames } =
    client.readFragment<FramesFragment>({
      fragment: GQL_FRAGMENT_VIDEOANNOTATION_FRAMES,
      id: `${cacheId}`,
    }) || {};
  if (!frames) throw new Error(`No frames in cache for cache id ${cacheId}`);
  return frames;
};

// ****************************************************************************************************
// Key Annotations

export const updateKeyAnnotationValueInCache = (
  cacheIdAnnotation: string,
  cacheIdKeyAnnotationValue: string,
  annotationValue: VideoAnnotationValue,
) => {
  const client = getApolloClient();
  const keyAnnotation = getKeyAnnotationFromCache(cacheIdKeyAnnotationValue);

  client.writeFragment<VideoKeyAnnotationValueFragment>({
    data: {
      __typename: keyAnnotation.__typename,
      annotationValue,
    } as VideoKeyAnnotation,
    fragment: GQL_FRAGMENT_VIDEOKEYANNOTATION_ANNOTATIONVALUE,
    id: cacheIdKeyAnnotationValue,
  });
  (client.cache as InMemoryCache).release(cacheIdKeyAnnotationValue);
  useStore
    .getState()
    .splitLabel.addAnnotationValueModification(
      cacheIdAnnotation,
      cacheIdKeyAnnotationValue,
      'update',
    );
};

export const deleteKeyAnnotationsInCache = (
  annotationCacheId: string,
  keyAnnotations: VideoKeyAnnotation[],
) => {
  const client = getApolloClient();
  const keyAnnotationsCacheIds = keyAnnotations.map(keyAnnotation =>
    getCacheIdFromGraphQLObject(keyAnnotation),
  );
  keyAnnotations.forEach(keyAnnotation =>
    client.cache.evict({ broadcast: false, id: getCacheIdFromGraphQLObject(keyAnnotation) }),
  );
  client.cache.modify({
    fields: {
      keyAnnotations: (cachedKeyAnnotations: readonly Reference[]) => {
        return [...cachedKeyAnnotations.filter(d => !keyAnnotationsCacheIds.includes(d.__ref))];
      },
    },
    id: annotationCacheId,
  });
  client.cache.gc();
  (client.cache as InMemoryCache).release(annotationCacheId);
  keyAnnotationsCacheIds.forEach(keyAnnotationCacheId => {
    useStore
      .getState()
      .splitLabel.addAnnotationValueModification(annotationCacheId, keyAnnotationCacheId, 'delete');
  });
};

export const addKeyAnnotationsInCache = (
  annotationCacheId: string,
  keyAnnotations: VideoKeyAnnotation[],
) => {
  const client = getApolloClient();
  const existingKeyAnnotation = getKeyAnnotationsFromCache(annotationCacheId);
  client.cache.writeFragment({
    data: { keyAnnotations: [...existingKeyAnnotation, ...keyAnnotations] },
    fragment: GQL_FRAGMENT_VIDEOANNOTATION_KEY_ANNOTATIONS,
    id: annotationCacheId,
  });
  useStore
    .getState()
    .splitLabel.addAnnotationValueModifications(
      annotationCacheId,
      keyAnnotations.map(getCacheIdFromGraphQLObject),
      'add',
    );
  (client.cache as InMemoryCache).release(annotationCacheId);
};

// ****************************************************************************************************
// Annotations
// Update Annotation

export const updateFramesInCache = (
  annotationOrCacheId: VideoAnnotation | string,
  frames: FrameInterval[],
) => {
  const annotation = isVideoAnnotation(annotationOrCacheId)
    ? annotationOrCacheId
    : getAnnotationFromCache(annotationOrCacheId);

  if (!isVideoAnnotation(annotation)) {
    throw new Error('Unable to update frames on a non video annotation');
  }

  const client = getApolloClient();
  const cacheId = getCacheIdFromGraphQLObject(annotation);

  client.writeFragment<FramesFragment>({
    data: {
      __typename: annotation.__typename,
      frames,
    },
    fragment: GQL_FRAGMENT_VIDEOANNOTATION_FRAMES,
    id: `${cacheId}`,
  });
  (client.cache as InMemoryCache).release(cacheId);
  useStore.getState().splitLabel.addAnnotationModification(cacheId, 'update');
};

export const updateCategoryNameAndJobForVideoObjectDetectionAnnotationInCache = (
  cacheId: string,
  categoryCode: string,
  name: string,
  jobName: string,
) => {
  const client = getApolloClient();
  client.writeFragment<CategoryAndJobFragment>({
    data: {
      __typename: 'VideoObjectDetectionAnnotation',
      category: categoryCode,
      job: jobName,
      name,
    },
    fragment: GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_CATEGORY_AND_JOB,
    id: cacheId,
  });
  (client.cache as InMemoryCache).release(cacheId);
  useStore.getState().splitLabel.addAnnotationModification(cacheId, 'update');
};

export const updateNameForVideoObjectDetectionAnnotationInCache = (
  annotation: VideoObjectDetectionAnnotation,
  name: string,
) => {
  const client = getApolloClient();
  const cacheId = getCacheIdFromGraphQLObject(annotation);
  client.writeFragment({
    data: {
      __typename: 'VideoObjectDetectionAnnotation',
      name,
    },
    fragment: GQL_FRAGMENT_VIDEOOBJECTDETECTIONANNOTATION_NAME,
    id: cacheId,
  });
  (client.cache as InMemoryCache).release(cacheId);
  useStore.getState().splitLabel.addAnnotationModification(cacheId, 'update');
};

export const updatePathForAnnotationInCache = (cacheId: string, path: string[][]) => {
  const client = getApolloClient();
  client.writeFragment({
    data: { path },
    fragment: GQL_FRAGMENT_VIDEOANNOTATION_PATH,
    id: cacheId,
  });
  (client.cache as InMemoryCache).release(cacheId);
  useStore.getState().splitLabel.addAnnotationModification(cacheId, 'update');
};

// Delete Annotation
export const purgeAnnotationsInCache = () => {
  const client = getApolloClient();
  const labelId = getLabelId();
  const existingAnnotations = getLightAnnotationsFromCache();
  if (!existingAnnotations || existingAnnotations.length === 0) return;

  useStore.getState().splitLabel.addAnnotationModifications(
    existingAnnotations.map(annotation => getCacheIdFromGraphQLObject(annotation)),
    'delete',
  );

  client.cache.modify({
    fields: {
      [`annotations(${JSON.stringify({ where: { labelId } })})`]: () => [] as Reference[],
    },
    id: 'ROOT_QUERY',
  });
  client.cache.gc();
};

export const deleteAnnotationsInCache = (
  annotationIdsToDeleteParam: string[],
  areCacheId = false,
) => {
  const client = getApolloClient();
  const labelId = getLabelId();
  const annotationIdsToDelete = areCacheId
    ? annotationIdsToDeleteParam
    : annotationIdsToDeleteParam.flatMap(id => getCacheIdsFromAnnotationId(id));
  client.cache.modify({
    fields: {
      [`annotations(${JSON.stringify({ where: { labelId } })})`]: (
        existing: readonly Reference[],
      ) => {
        const newValues = existing.filter(annotation => {
          const shouldDelete = annotationIdsToDelete.includes(annotation.__ref);
          if (shouldDelete) {
            useStore.getState().splitLabel.addAnnotationModification(annotation.__ref, 'delete');
          }
          return !shouldDelete;
        });
        return newValues;
      },
    },
    id: 'ROOT_QUERY',
  });
  client.cache.gc();
};

// Query object

export const purgeAnnotationQueryInCache = (labelIdProp?: string | null) => {
  const client = getApolloClient();
  const labelId = labelIdProp ?? DEFAULT_LABEL_ID;
  client.cache.evict({ args: { where: { labelId } }, fieldName: 'annotations', id: 'ROOT_QUERY' });
  client.cache.gc();
};
