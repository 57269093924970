/* eslint-disable @typescript-eslint/naming-convention */

import { type EvaluationContext } from '@openfeature/react-sdk';

import { type BoolFlagName } from './defaultFlags';

type FlagConfiguration = {
  contextEvaluator?: (context: EvaluationContext) => string;
  defaultVariant: string;
  disabled: boolean;
  variants?: Record<string, boolean | string>;
};

const BOOLEAN_VARIANT = {
  off: false,
  on: true,
};

export const DEVELOPMENT_FLAGS: Record<BoolFlagName, FlagConfiguration> = {
  com_project_creation_limits: {
    defaultVariant: 'off',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  config_event_segment: {
    defaultVariant: 'off',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_block_multi_review: {
    defaultVariant: 'off',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_multi_tabs: {
    defaultVariant: 'on',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_prevent_right_click: {
    defaultVariant: 'off',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_splitted_json_response: {
    defaultVariant: 'on',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_synchronize_video_from_bucket: {
    defaultVariant: 'on',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lab_video_konvas_v1: {
    contextEvaluator: (context: EvaluationContext) => {
      if (context.email === 'test+admin+konvas@kili-technology.com') {
        return 'on';
      }
      return 'off';
    },
    defaultVariant: 'off',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
  lops_auth_email_verification: {
    defaultVariant: 'on',
    disabled: false,
    variants: BOOLEAN_VARIANT,
  },
};
