import { isObjectTask } from '@kili-technology/types';
import { t } from 'i18next';

import { InputType } from '@/__generated__/globalTypes';
import { clearBadgeLayers } from '@/components/asset-ui/Image/LeafletMapv2/ImageAnnotations/badgeLayers';
import { areObjectsLocked } from '@/components/asset-ui/Image/LeafletMapv2/KiliLeafletLayers/helpers/areObjectsLocked';
import {
  deleteObjectsFromFrameOnwardsSplitWithHistory,
  deleteObjectSplitWithHistory,
} from '@/graphql/annotations/helpers/delete';
import type { KiliAnnotation } from '@/services/jobs/setResponse';
import { store } from '@/store';
import { generateNewCreatingOrEditingObjectId, removeSelectedObjectIds, useStore } from '@/zustand';

import { addErrorNotification } from '../../application/actions';
import { projectInputType } from '../../selectors';
import { removeAnnotations } from '../actions';
import { getAnnotationFromMidAndAnnotations } from '../helpers';
import { jobsAnnotations } from '../selectors';

export type DeleteOptions = {
  /** Applies a partial deletion when applicable, ie for video assets. */
  partially?: boolean;
};

export function deleteObjects(mids: string[], options: DeleteOptions = {}) {
  const state = store.getState();

  if (areObjectsLocked()) {
    const annotations = jobsAnnotations(state) as KiliAnnotation[];
    const annotationsToDelete = mids
      .map(mid => getAnnotationFromMidAndAnnotations(mid, annotations))
      .filter(Boolean) as KiliAnnotation[];

    if (annotationsToDelete.some(({ mlTask }) => mlTask && isObjectTask(mlTask))) {
      store.dispatch(addErrorNotification(t('routes.label.delete.lockedAnnotationsError')));
      return;
    }
  }

  const { partially = false } = options;
  const inputType = projectInputType(state);

  if (inputType === InputType.VIDEO) {
    if (partially) {
      const currentFrame = useStore.getState().labelFrame.frame;

      deleteObjectsFromFrameOnwardsSplitWithHistory({
        frameToDeleteFrom: currentFrame,
        mids,
      });
    } else {
      deleteObjectSplitWithHistory(mids);
    }
  } else {
    store.dispatch(removeAnnotations(mids));
  }

  const { creatingOrEditingObjectId, selectedObjectIds } = useStore.getState().labelInterface;

  if (creatingOrEditingObjectId && mids.includes(creatingOrEditingObjectId)) {
    generateNewCreatingOrEditingObjectId();
  }

  const deletedSelectedObjectIds = mids.filter(mid => selectedObjectIds.includes(mid));

  if (deletedSelectedObjectIds.length > 0) {
    removeSelectedObjectIds({ mids: deletedSelectedObjectIds });
  }

  if ([InputType.IMAGE, InputType.VIDEO].includes(inputType)) {
    mids.forEach(clearBadgeLayers);
  }
}
