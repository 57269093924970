/* eslint-disable no-underscore-dangle */

import { PointToolClass } from '@kili-technology/cursors';
import L, { DomUtil } from 'leaflet';

import { LEAFLET_GPS_COPY_ZINDEX } from '../../../../../constants/zIndexes';

L.GpsCoordinatesLayer = L.Layer.extend({
  _reset() {
    const container = this._container;

    const size = this._map.getSize();

    const mapPosition = this._map._getMapPanePos();

    DomUtil.setPosition(
      container,
      mapPosition ? new L.Point(0, 0).subtract(mapPosition) : new L.Point(0, 0),
    );

    container.style.width = `${size.x}px`;
    container.style.height = `${size.y}px`;
  },

  getEvents() {
    const events = {
      moveend: this._reset,
      resize: this._reset,
      viewreset: this._reset,
      zoom: this._reset,
    };

    return events;
  },

  onAdd() {
    const existingPane = this._map.getPane('gps-coordinates-copy');
    if (existingPane) {
      existingPane.remove();
      delete this._map._panes['gps-coordinates-copy'];
      delete this._map._paneRenderers['gps-coordinates-copy'];
    }

    this._pane = this._map.createPane('gps-coordinates-copy');
    this._pane.style.zIndex = `${LEAFLET_GPS_COPY_ZINDEX}`;

    if (!this._container) {
      this._container = L.DomUtil.create('div');
    }

    this._container.classList.add('leaflet-interactive');
    this._container.classList.add(PointToolClass);
    this.addInteractiveTarget(this._container);

    this._pane.appendChild(this._container);
    this._reset();
  },

  onRemove() {
    this._container.remove();
    this.removeInteractiveTarget(this._container);
    this._pane.remove();
    delete this._map._panes['gps-coordinates-copy'];
    delete this._map._paneRenderers['gps-coordinates-copy'];
  },
});
