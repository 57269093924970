/* eslint-disable no-underscore-dangle  */
import L from 'leaflet';

L.DrawToolbar = L.DrawToolbar.extend({
  getModeHandlers(map: L.DrawMap) {
    return [
      {
        enabled: this.options.polyline,
        handler: new L.Draw.Polyline(map, this.options.polyline),
        title: L.drawLocal.draw.toolbar.buttons.polyline,
      },
      {
        enabled: this.options.polygon,
        handler: new L.Draw.Polygon(map, this.options.polygon),
        title: L.drawLocal.draw.toolbar.buttons.polygon,
      },
      {
        enabled: this.options.rectangle,
        handler: new L.Draw.Rectangle(map, this.options.rectangle),
        title: L.drawLocal.draw.toolbar.buttons.rectangle,
      },
      {
        enabled: this.options.circle,
        handler: new L.Draw.Circle(map, this.options.circle),
        title: L.drawLocal.draw.toolbar.buttons.circle,
      },
      {
        enabled: this.options.marker,
        handler: new L.Draw.Marker(map, this.options.marker),
        title: L.drawLocal.draw.toolbar.buttons.marker,
      },
      {
        enabled: this.options.circlemarker,
        handler: new L.Draw.CircleMarker(map, this.options.circlemarker),
        title: L.drawLocal.draw.toolbar.buttons.circlemarker,
      },
      {
        enabled: this.options.semantic,
        handler: new L.Draw.Semantic(map, this.options.semantic),
        title: 'Draw a semantic instance',
        type: 'semantic',
      },
      {
        enabled: this.options.pose,
        handler: new L.Draw.PoseEstimation(map, this.options.pose),
        title: 'Draw a pose estimation instance',
      },
    ];
  },

  options: {
    circle: {},
    circlemarker: {},
    marker: {},
    polygon: {},
    polyline: {},
    pose: {},
    rectangle: {},
    semantic: {},
  },

  setOptions(options: Record<string, { handler: { disable: () => void; enable: () => void } }>) {
    L.setOptions(this, options);
    // eslint-disable-next-line no-restricted-syntax
    for (const type in this._modes) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._modes.hasOwnProperty(type) && options.hasOwnProperty(type)) {
        this._modes[type].handler.setOptions(options[type]);
        if (options[type]) {
          this._modes[type].handler.enable();
        } else {
          this._modes[type]?.handler?.disable();
        }
      }
    }
  },
});
