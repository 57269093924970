import {
  Labeled as LabeledIcon,
  Ongoing as OngoingIcon,
  Review as ToReviewIcon,
  Reviewed as ReviewedIcon,
  Todo as TodoIcon,
  Unresolve as Skipped,
} from '@kili-technology/icons';
import { t } from 'i18next';

import {
  AssetWorkflowStepStatus,
  type ProjectWorkflowStepType,
  type AssetWorkflowStep,
} from '@/__generated__/globalTypes';
import IconLabel from '@/pages/project/explore/components/AssetAccordion/IconLabel';

type StepStatusProps = {
  currentStep: AssetWorkflowStep;
};

export const REVIEW_STATUS_STEP_TO_DISPLAY = {
  [AssetWorkflowStepStatus.DONE]: t('domain.asset.statuses.review.done'),
  [AssetWorkflowStepStatus.IN_PROGRESS]: t('domain.asset.statuses.review.inProgress'),
  [AssetWorkflowStepStatus.REWORK]: t('domain.asset.statuses.review.rework'),
  [AssetWorkflowStepStatus.PARTIALLY_DONE]: t('domain.asset.statuses.review.partiallyDone'),
  [AssetWorkflowStepStatus.SKIPPED]: t('domain.asset.statuses.review.skipped'),
  [AssetWorkflowStepStatus.TO_DO]: t('domain.asset.statuses.review.toDo'),
};

export const DEFAULT_STATUS_STEP_TO_DISPLAY = {
  [AssetWorkflowStepStatus.DONE]: t('domain.asset.statuses.label.done'),
  [AssetWorkflowStepStatus.IN_PROGRESS]: t('domain.asset.statuses.label.inProgress'),
  [AssetWorkflowStepStatus.REWORK]: t('domain.asset.statuses.label.rework'),
  [AssetWorkflowStepStatus.PARTIALLY_DONE]: t('domain.asset.statuses.label.partiallyDone'),
  [AssetWorkflowStepStatus.SKIPPED]: t('domain.asset.statuses.label.skipped'),
  [AssetWorkflowStepStatus.TO_DO]: t('domain.asset.statuses.label.toDo'),
};

export const STATUS_STEP_ICON = (type: ProjectWorkflowStepType) => {
  const isDefault = type === 'DEFAULT';
  if (isDefault) {
    return {
      [AssetWorkflowStepStatus.DONE]: () => <LabeledIcon fill="var(--color-epsilon-accent-5)" />,
      [AssetWorkflowStepStatus.IN_PROGRESS]: () => (
        <OngoingIcon fill="var(--color-gamma-accent-3)" />
      ),
      [AssetWorkflowStepStatus.REWORK]: () => <OngoingIcon fill="var(--color-gamma-accent-3)" />,
      [AssetWorkflowStepStatus.PARTIALLY_DONE]: () => (
        <OngoingIcon fill="var(--color-gamma-accent-3)" />
      ),
      [AssetWorkflowStepStatus.SKIPPED]: () => <Skipped fill="var(--color-omega-accent-5)" />,
      [AssetWorkflowStepStatus.TO_DO]: () => <TodoIcon fill="var(--color-omega-accent-5)" />,
    };
  }
  return {
    [AssetWorkflowStepStatus.DONE]: () => (
      <ReviewedIcon
        fill={isDefault ? 'var(--color-epsilon-accent-5)' : 'var(--color-alpha-accent-9)'}
      />
    ),
    [AssetWorkflowStepStatus.IN_PROGRESS]: () => <ToReviewIcon fill="var(--color-zeta-accent-3)" />,
    [AssetWorkflowStepStatus.REWORK]: () => <ToReviewIcon fill="var(--color-zeta-accent-3)" />,
    [AssetWorkflowStepStatus.PARTIALLY_DONE]: () => (
      <ToReviewIcon fill="var(--color-zeta-accent-3)" />
    ),
    [AssetWorkflowStepStatus.SKIPPED]: () => <Skipped fill="var(--color-omega-accent-5)" />,
    [AssetWorkflowStepStatus.TO_DO]: () => <LabeledIcon fill="var(--color-omega-accent-5)" />,
  };
};

export const StepStatus = ({ currentStep }: StepStatusProps) => {
  const { type, status, name } = currentStep;
  let title: string;
  if (type === 'REVIEW') {
    title = name.replace('Reviewing', REVIEW_STATUS_STEP_TO_DISPLAY[status]);
  } else {
    title = name.replace('Labeling', DEFAULT_STATUS_STEP_TO_DISPLAY[status]);
  }

  return (
    <div data-cy="status" data-value={title}>
      <IconLabel icon={STATUS_STEP_ICON(type)[status]} text={title} />
    </div>
  );
};
