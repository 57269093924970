import { type LlmAsset, parseLlm } from '@kili-technology/llm-rlhf';
import {
  type JsonCategories as Categories,
  type Job,
  type Jobs,
  MachineLearningTask,
} from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import _get from 'lodash/get';

import { InputType, type Label } from '../../../../../__generated__/globalTypes';
import { getColorFromSeed } from '../../../../../components/helpers';
import { COLORS } from '../../../../../constants/jobsColors';
import { type VisibilityTree } from '../../../../../redux/application/types';
import { downloadAssetContent } from '../../../../../services/assets/download';
import { type KiliAnnotation } from '../../../../../services/jobs/setResponse';
import { sortByOrderInRichText } from '../../../../../services/jobs/sortAnnotations';

export const ASSET = 'asset';
export const JOBS = 'jobs';

export const getFirstColorNotInColors = (colors: string[]): string => {
  let returnColor;
  COLORS.forEach(color => {
    if (!colors.includes(color)) {
      returnColor = color;
    }
  });
  if (returnColor) {
    return returnColor;
  }
  return getColorFromSeed(generateHash());
};

export const getJobFromName = (jobName: string, jobs: Jobs, responses: unknown): Job => {
  const jobFromSettings = _get(jobs, jobName);
  if (jobFromSettings) return jobFromSettings;

  const originalJobName = _get(responses, [jobName, 'jobName']);
  return _get(jobs, originalJobName);
};

export const categoryIsHidden = (
  visibility: VisibilityTree | undefined,
  categoryCode: string,
  jobName: string,
  parentMid: string,
): boolean => {
  const categoryCodeWithParentMid = parentMid
    ? `${parentMid}.${categoryCode}`
    : `${jobName}.${categoryCode}`;
  if (!visibility) return false;
  return !_get(visibility, ['Classes', categoryCodeWithParentMid], true);
};

export const pointIsHidden = (
  visibility: VisibilityTree | undefined,
  pointCode: string,
): boolean => {
  if (!visibility) return false;
  return !_get(visibility, ['Points', pointCode], true);
};

export const isObjectHiddenBySearch = (
  visibility: VisibilityTree | undefined,
  objectMid: string,
): boolean => {
  if (!visibility) return false;
  return !(visibility?.Objects?.[objectMid] ?? true);
};

export const isJobHidden = (visibility: VisibilityTree | undefined, jobName: string): boolean => {
  if (!visibility) return false;
  return !(visibility?.Jobs?.[jobName] ?? true);
};

export const filterCategoriesByVisibility = (
  visibility: VisibilityTree | undefined,
  categories: Categories,
  jobName: string,
  parentMid: string,
): Categories => {
  if (!visibility) return categories;
  return Object.keys(categories)
    .filter(categoryCode => !categoryIsHidden(visibility, categoryCode, jobName, parentMid))
    .reduce((object: Categories, key: string) => {
      // eslint-disable-next-line no-param-reassign
      object[key] = categories[key];
      return object;
    }, {});
};

export const computeSecondsToLabel = (
  endTime: Date,
  previousLabelType: Label['labelType'] | null,
  previousTimeToLabel: Label['secondsToLabel'] | null,
  startTime: Date | undefined,
): number => {
  const previousLabelTime = previousTimeToLabel ?? 0;
  if (!startTime || !endTime) {
    return 0;
  }
  const newLabelTime = Math.round((endTime.valueOf() - startTime.valueOf()) / 1000);
  if (previousLabelType === 'AUTOSAVE') {
    return previousLabelTime + newLabelTime;
  }
  return newLabelTime;
};

export const COMPLETIONS_TYPES = {
  html: 'html',
  markdown: 'markdown',
  text: 'text',
} as const;

export type CompletionsType = typeof COMPLETIONS_TYPES[keyof typeof COMPLETIONS_TYPES];

const correctPart = (text: string) => {
  const codeParts = text.split('```');
  const newContentParts = codeParts.map((part, index) => {
    if (index % 2 === 1) return part;
    return part.replaceAll('•', '*');
  });
  return codeParts.length > 1 ? newContentParts.join('```') : newContentParts[0];
};

export async function downloadAndParseLlmJsonFile(
  token: string,
  dataIntegrationId: string | null,
  content: string,
  abortSignal?: AbortSignal,
): Promise<LlmAsset> {
  const response = await downloadAssetContent(content, token, abortSignal, !!dataIntegrationId);
  const text = await response.text();

  try {
    const llmAsset = parseLlm(JSON.parse(text));

    const newPrompts = llmAsset.prompts.map(prompt => {
      return {
        ...prompt,
        completions: prompt.completions.map(completion => {
          if (!completion) return completion;
          const newContent = correctPart(completion.content);
          return { ...completion, content: newContent };
        }),
        prompt: correctPart(prompt.prompt),
      };
    });

    const correctedLlmAsset = { ...llmAsset, prompts: newPrompts };

    return correctedLlmAsset;
  } catch (e) {
    throw new Error('An error occurred with the JSON file');
  }
}

export const JOB_VIEWER_CUSTOM_SIZE = 'jobViewerSize';

export function saveNavigationSize(size: number) {
  localStorage.setItem(JOB_VIEWER_CUSTOM_SIZE, `${size}`);
}

export function getStoredNavigationSize(): number {
  const rawSize = localStorage.getItem(JOB_VIEWER_CUSTOM_SIZE) ?? '';
  const size = parseFloat(rawSize);
  if (Number.isNaN(size)) return 0;
  return size;
}

export const getOrderedMids = (inputType: InputType, sortedAnnotations: KiliAnnotation[]) => {
  const nextSortedAnnotations = sortedAnnotations.filter(
    ({ mlTask }) =>
      mlTask &&
      ![MachineLearningTask.OBJECT_RELATION, MachineLearningTask.NAMED_ENTITIES_RELATION].includes(
        mlTask,
      ),
  );
  if (inputType === InputType.TEXT) {
    const divAnnotations = Array.from(document.querySelectorAll('[data-mid]'));
    return sortByOrderInRichText(divAnnotations, nextSortedAnnotations);
  }
  return nextSortedAnnotations.map(({ mid }) => mid);
};

export const shouldAutoSelect = (source?: 'annotation-wizard' | 'leaflet' | 'job-viewer') =>
  source === 'annotation-wizard';
