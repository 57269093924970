import { type Jobs, type JsonInterface, Tool } from '@kili-technology/types';

import { InputType, LabelFormat } from '../../__generated__/globalTypes';
import { JOBS, TOOLS } from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';

export const KILI_FORMAT_LABEL = 'Kili API format';
export const KILI_LLM_LABEL = 'Kili LLM format';
export const LATEST_LABEL_FORMAT = 'Latest label (for each asset)';
export const PASCAL_VOC_FORMAT_LABEL = 'Pascal VOC';
export const YOLO_V4_FORMAT_LABEL = 'YOLO v4';
export const YOLO_V5_FORMAT_LABEL = 'YOLO v5 to v8';

const isYoloVocExportFormatPossible = (inputType: InputType, jobs: Jobs): boolean => {
  if (inputType !== InputType.IMAGE && inputType !== InputType.VIDEO) {
    return false;
  }
  return Object.entries(jobs).some(([_, job]) => {
    const tools = job?.[TOOLS] ?? ([] as Tool[]);
    return tools.includes(Tool.RECTANGLE);
  });
};

export const computePossibleLabelFormats =
  (hasDataConnections = false) =>
  (inputType: InputType, jsonInterface: JsonInterface): [string, LabelFormat][] => {
    const isLlm = inputType === InputType.LLM_RLHF || inputType === InputType.LLM_INSTR_FOLLOWING;

    if (isLlm) return [[KILI_LLM_LABEL, LabelFormat.LLM]];

    const possibleExportFormats: [string, LabelFormat][] = [[KILI_FORMAT_LABEL, LabelFormat.RAW]];
    const jobs = jsonInterface?.[JOBS];
    if (!jobs) {
      return possibleExportFormats;
    }
    if (isYoloVocExportFormatPossible(inputType, jobs)) {
      possibleExportFormats.push(
        ...(!hasDataConnections
          ? [[PASCAL_VOC_FORMAT_LABEL, LabelFormat.PASCAL_VOC] as [string, LabelFormat]]
          : []),
        [YOLO_V4_FORMAT_LABEL, LabelFormat.YOLO_V4],
        [YOLO_V5_FORMAT_LABEL, LabelFormat.YOLO_V5],
      );
    }
    return possibleExportFormats;
  };

export const computeIsProjectUsingOrientedTools = (jsonInterface: JsonInterface): boolean => {
  const jobs = jsonInterface?.[JOBS];
  if (!jobs) {
    return false;
  }
  return Object.entries(jobs).some(([_, job]) => {
    return (job as { isOriented?: boolean }).isOriented;
  });
};
