import { generateHash } from '@kili-technology/utilities';
import { type Draft } from 'immer';

import {
  defaultPoseEstimationSettings,
  defaultPropagationSettings,
  defaultRelationSettings,
  defaultTagSettings,
  defaultTimelineSettings,
  defaultToolThenClassSettings,
  defaultInteractiveSegmentationSettings,
  findElementInAccordion,
  getInitialGrantedTools,
  getLabelInterfaceSettingsKey,
} from './helpers';
import { type LabelInterfaceSlice, type LabelInterfaceSliceValues } from './types';

import { type ZustandSlice } from '..';
import { TOOL_SETTING } from '../../components/asset-ui/Image/LeafletMapv2/constant';
import { type ToolName } from '../../components/asset-ui/Image/LeafletMapv2/type';
import createLogger from '../helpers';

const initialState: LabelInterfaceSliceValues = {
  accordionTree: {},
  annotationCopy: null,
  bearing: 0,
  canDisplayLabels: true,
  creatingOrEditingObjectId: null,
  displayedAnnotationMids: [],
  grantedTools: {},
  hiddenObjectIds: [],
  imageHeight: 0,
  imageWidth: 0,
  isAssetPanelExpanded: true,
  isHoveringWizard: false,
  isJobViewerExpanded: true,
  lastSelectedCategories: [],
  queryString: '',
  selectedObjectIds: [],
  settings: {},
  showOnlyMissingJobs: false,
  startTime: Date.now(),
  temporaryAnnotations: [],
  zoomLevel: 1,
  zoomLevelMax: undefined,
  zoomLevelMin: undefined,
  zoomToFitTrigger: undefined,
};

const ZOOM_STEP = 0.1;

const log = createLogger('labelInterface');

export const createLabelInterfaceSlice: ZustandSlice<LabelInterfaceSlice> = set => ({
  ...initialState,
  addSelectedObjectId: payload =>
    set(
      state => {
        const { mid } = payload;
        if (state.labelInterface.selectedObjectIds.includes(mid)) return;

        state.labelInterface.selectedObjectIds.push(mid);
      },
      false,
      log('addSelectedObjectId', payload),
    ),
  addSelectedObjectIds: payload =>
    set(
      state => {
        const { mids } = payload;
        mids.forEach(mid => {
          if (state.labelInterface.selectedObjectIds.includes(mid)) return;

          state.labelInterface.selectedObjectIds.push(mid);
        });
      },
      false,
      log('addSelectedObjectId', payload),
    ),
  cancelTemporaryAnnotation: payload =>
    set(
      state => {
        const { mid } = payload;
        state.labelInterface.temporaryAnnotations =
          state.labelInterface.temporaryAnnotations.filter(d => d.mid !== mid);
      },
      false,
      log('cancelTemporaryAnnotation', payload),
    ),
  clearTools: () =>
    set(
      state => {
        state.labelInterface.grantedTools = {};
      },
      false,
      log('clearTools'),
    ),
  closeCategoriesFromJobsInAccordion: payload =>
    set(
      state => {
        payload.forEach(jobName => {
          const categoryNames = Object.keys(
            state.labelInterface.accordionTree?.[jobName]?.categories ?? {},
          );

          categoryNames.forEach(categoryName => {
            (state.labelInterface.accordionTree[jobName].categories ?? {})[categoryName].value =
              false;
          });
        });
      },
      false,
      log('closeCategoriesFromJobsInAccordion', payload),
    ),
  decrementZoomLevel: () =>
    set(
      state => {
        const { zoomLevelMax, zoomLevelMin, zoomLevel } = state.labelInterface;

        let newZoomLevel = zoomLevel - ZOOM_STEP;

        if (zoomLevelMax !== undefined && newZoomLevel > zoomLevelMax) {
          newZoomLevel = zoomLevelMax;
        }

        if (zoomLevelMin !== undefined && newZoomLevel < zoomLevelMin) {
          newZoomLevel = zoomLevelMin;
        }

        state.labelInterface.zoomLevel = newZoomLevel;
      },
      false,
      log('incrementZoomLevel'),
    ),
  disableTool: payload =>
    set(
      state => {
        const toolList = state.labelInterface.grantedTools;

        if (state.labelInterface.temporaryAnnotations.length) {
          window.getSelection()?.empty();
          state.labelInterface.temporaryAnnotations = [];
        }

        const toolOptions = toolList[payload.name];
        if (!toolOptions) return;

        toolList[payload.name] = { ...toolList[payload.name], isSelected: false };
      },
      false,
      log('disableTool', payload),
    ),
  generateNewCreatingOrEditingObjectId: () =>
    set(
      state => {
        state.labelInterface.creatingOrEditingObjectId = generateHash();
      },
      false,
      log('generateNewCreatingOrEditingObjectId'),
    ),
  hideObjects: payload =>
    set(
      state => {
        state.labelInterface.hiddenObjectIds = state.labelInterface.hiddenObjectIds.concat(payload);
      },
      false,
      log('hideObjects', payload),
    ),
  incrementZoomLevel: () =>
    set(
      state => {
        const { zoomLevelMax, zoomLevelMin, zoomLevel } = state.labelInterface;

        let newZoomLevel = zoomLevel + ZOOM_STEP;

        if (zoomLevelMax !== undefined && newZoomLevel > zoomLevelMax) {
          newZoomLevel = zoomLevelMax;
        }

        if (zoomLevelMin !== undefined && newZoomLevel < zoomLevelMin) {
          newZoomLevel = zoomLevelMin;
        }

        state.labelInterface.zoomLevel = newZoomLevel;
      },
      false,
      log('incrementZoomLevel'),
    ),
  initialize: payload =>
    set(
      state => {
        Object.entries(initialState).forEach(([key, value]) => {
          const keyInitialState = key as keyof typeof initialState;
          if (keyInitialState === 'settings') {
            return;
          }

          if (keyInitialState === 'startTime') {
            state.labelInterface[keyInitialState] = Date.now();
            return;
          }

          if (keyInitialState === 'grantedTools') {
            state.labelInterface[keyInitialState] = getInitialGrantedTools(payload);
            return;
          }

          (state.labelInterface[keyInitialState] as unknown) = value;
        });
      },
      false,
      log('initialize', payload),
    ),
  openCategoryInAccordion: payload =>
    set(
      state => {
        const elementToModify = findElementInAccordion(
          payload.mid,
          payload.parent.categoryCode,
          payload.parent.jobcode,
          state.labelInterface.accordionTree,
        );

        if (elementToModify) {
          state.labelInterface.accordionTree = { ...state.labelInterface.accordionTree };
          elementToModify[payload.parent.categoryCode] = {
            children: { [payload.children.jobcode]: { value: true } },
            value: true,
          };
        }
      },
      false,
      log('closeCategoriesFromJobsInAccordion', payload),
    ),
  removeAllSelectedObjectIds: () =>
    set(
      state => {
        if (state.labelInterface.selectedObjectIds.length > 0) {
          state.labelInterface.selectedObjectIds = [];
        }
      },
      false,
      log('removeAllSelectedObjectIds'),
    ),
  removeSelectedObjectId: payload =>
    set(
      state => {
        const { mid } = payload;
        state.labelInterface.selectedObjectIds = state.labelInterface.selectedObjectIds.filter(
          objectId => objectId !== mid,
        );
      },
      false,
      log('removeSelectedObjectId', payload),
    ),
  removeSelectedObjectIds: payload =>
    set(
      state => {
        const { mids } = payload;
        state.labelInterface.selectedObjectIds = state.labelInterface.selectedObjectIds.filter(
          objectId => !mids.includes(objectId),
        );
      },
      false,
      log('removeSelectedObjectId', payload),
    ),
  replaceAllWithSelectedObjectId: payload =>
    set(
      state => {
        const { mid } = payload;
        state.labelInterface.selectedObjectIds = [mid];
      },
      false,
      log('replaceAllWithSelectedObjectId', payload),
    ),
  resetSettings: payload =>
    set(
      state => {
        const { projectId, userId } = payload;
        const settingsKey = getLabelInterfaceSettingsKey({ projectId, userId });
        state.labelInterface.settings[settingsKey] = {
          interactiveSegmentation: defaultInteractiveSegmentationSettings,
          poseEstimation: defaultPoseEstimationSettings,
          propagation: defaultPropagationSettings,
          relation: defaultRelationSettings,
          tag: defaultTagSettings,
          timeline: defaultTimelineSettings,
          toolThenClass: defaultToolThenClassSettings,
        };
      },
      false,
      log('resetSettings', payload),
    ),
  resetTools: payload =>
    set(
      state => {
        const initialGrantedTools = getInitialGrantedTools(payload);
        state.labelInterface.grantedTools = initialGrantedTools;
      },
      false,
      log('resetTools', payload),
    ),
  setImageSize: payload =>
    set(
      state => {
        const { height, width } = payload;
        state.labelInterface.imageWidth = width;
        state.labelInterface.imageHeight = height;
      },
      false,
      log('setImageSize', payload),
    ),
  setTool: payload =>
    set(
      state => {
        const toolList = state.labelInterface.grantedTools;

        if (state.labelInterface.temporaryAnnotations.length) {
          window.getSelection()?.empty();

          if (state.labelInterface.temporaryAnnotations.length > 0) {
            state.labelInterface.temporaryAnnotations = [];
          }
        }

        const toolOptions = toolList[payload.name];
        if (!toolOptions) return;

        const { isSelected } = toolOptions;

        const isToggleable = TOOL_SETTING.toggleable.includes(payload.name);

        /* If we are in a creation or edition state for Relations or Semantic/Segmentation we
        should reset the state. */
        if (state.labelInterface.creatingOrEditingObjectId) {
          const isSwitchingBetweenSemanticOrSegmentation =
            (!!toolList.Segmentation?.isSelected || !!toolList.Semantic?.isSelected) &&
            (payload.name === 'Semantic' || payload.name === 'Segmentation');

          if (!isSwitchingBetweenSemanticOrSegmentation)
            state.labelInterface.creatingOrEditingObjectId = null;
          if (toolList.Relation?.isSelected) state.labelInterface.hiddenObjectIds = [];
        }

        if (isToggleable) {
          toolList[payload.name] = { ...toolOptions, isSelected: !isSelected };
          return;
        }

        if (!isSelected) {
          Object.entries(toolList).forEach(([key, options]) => {
            const keyCasted = key as ToolName;
            if (TOOL_SETTING.toggleable.includes(keyCasted)) return;
            if (options.isSelected) {
              toolList[keyCasted] = { ...options, isSelected: false };
            }
          });

          toolList[payload.name] = { ...toolOptions, isSelected: true };
        }
      },
      false,
      log('setTool', payload),
    ),
  setZoomLevel: payload =>
    set(
      state => {
        let boundingZoomLevel = payload;

        const { zoomLevelMax, zoomLevelMin } = state.labelInterface;

        if (zoomLevelMax !== undefined && boundingZoomLevel > zoomLevelMax) {
          boundingZoomLevel = zoomLevelMax;
        }

        if (zoomLevelMin !== undefined && boundingZoomLevel < zoomLevelMin) {
          boundingZoomLevel = zoomLevelMin;
        }

        state.labelInterface.zoomLevel = boundingZoomLevel;
      },
      false,
      log('setZoomLevel', payload),
    ),
  setZoomLevelSettings: payload =>
    set(
      state => {
        const { zoomLevelMax, zoomLevelMin } = payload;

        if (zoomLevelMax !== undefined) {
          state.labelInterface.zoomLevelMax = zoomLevelMax;
        }

        if (zoomLevelMin !== undefined) {
          state.labelInterface.zoomLevelMin = zoomLevelMin;
        }
      },
      false,
      log('setZoomLevelSettings', payload),
    ),
  showObjects: payload =>
    set(
      state => {
        state.labelInterface.hiddenObjectIds = state.labelInterface.hiddenObjectIds.filter(
          objectId => {
            if (Array.isArray(payload)) {
              return !payload.includes(objectId);
            }
            return payload !== objectId;
          },
        );
      },
      false,
      log('showObjects', payload),
    ),
  toggleSelectedObjectId: payload =>
    set(
      state => {
        const { selectedObjectIds } = state.labelInterface;
        const { mid } = payload;

        if (selectedObjectIds.includes(mid)) {
          selectedObjectIds.splice(selectedObjectIds.indexOf(mid), 1);
        } else {
          selectedObjectIds.push(mid);
        }
      },
      false,
      log('toggleSelectedObjectId', payload),
    ),
  triggerZoomToFit: () =>
    set(
      state => {
        state.labelInterface.zoomToFitTrigger = Date.now();
      },
      false,
      log('triggerZoomToFit'),
    ),
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.labelInterface as Draft<LabelInterfaceSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
  updateSettings: payload =>
    set(
      state => {
        const { projectId, userId, settings, settingName } = payload;
        const settingsKey = getLabelInterfaceSettingsKey({ projectId, userId });
        state.labelInterface.settings[settingsKey][settingName] = {
          ...state.labelInterface.settings[settingsKey][settingName],
          ...settings,
        };
      },
      false,
      log('updateSettings', payload),
    ),
  updateTemporaryAnnotation: payload =>
    set(
      state => {
        const { mid, newAnnotation } = payload;
        const otherAnnotations = state.labelInterface.temporaryAnnotations.filter(
          d => d.mid !== mid,
        );
        state.labelInterface.temporaryAnnotations = [newAnnotation, ...otherAnnotations];
      },
      false,
      log('updateTemporaryAnnotation', payload),
    ),
});

export * from './types';
