import {
  type Job,
  MachineLearningTask,
  Tool,
  type EntityRelation,
  type ObjectRelation,
  type Jobs,
} from '@kili-technology/types';

import { type KiliAnnotation } from './setResponse';
import { type AvailableClassesToChangePayload } from './type';

import { ANY_RELATION_VALUE } from '../../components/InterfaceBuilder/FormInterfaceBuilder/JobCategory/JobCategoryRelation';
import { getAnnotationFromMid } from '../../redux/jobs/helpers';
import { jobsResponses } from '../../redux/jobs/selectors';
import { store } from '../../store';

export type CategoryResponse = {
  categoryCode: string;
  categoryName: string;
  jobName: string;
};

const canJobChangeClass = (job: Job, changedLayerTool: Tool | undefined): boolean => {
  if (job.isVisible !== undefined && !job.isVisible) {
    return false;
  }
  if (job.mlTask === MachineLearningTask.POSE_ESTIMATION) {
    return false;
  }
  if (job.mlTask === MachineLearningTask.OBJECT_DETECTION && changedLayerTool) {
    return (
      !!job.tools &&
      (!changedLayerTool ||
        job.tools.includes(changedLayerTool) ||
        (changedLayerTool === Tool.POLYGON && job.tools.includes(Tool.SEMANTIC)))
    );
  }
  return true;
};

export const canChangeClass = (
  jobName: string | undefined,
  jobs: Jobs,
  changedLayerTool: Tool | undefined,
): boolean => {
  if (!jobName) {
    return true;
  }
  const job = jobs[jobName];

  if (!job) {
    return false;
  }

  return canJobChangeClass(job, changedLayerTool);
};

const getAllowedCategoryResponses = (
  annotation: KiliAnnotation,
  jobs: Jobs,
  allowCurrentCategory: boolean,
): CategoryResponse[] => {
  const changedLayerTool = annotation?.type;
  const mlTask = annotation?.mlTask;
  let startRelationCategory: string | undefined;

  if (mlTask === MachineLearningTask.OBJECT_RELATION) {
    startRelationCategory = getAnnotationFromMid(
      (annotation as ObjectRelation)?.startObjects?.[0].mid,
      jobsResponses(store.getState()),
    )?.categories?.[0]?.name;
  } else if (mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION) {
    startRelationCategory = getAnnotationFromMid(
      (annotation as EntityRelation)?.startEntities?.[0].mid,
      jobsResponses(store.getState()),
    )?.categories?.[0]?.name;
  }

  const allowedJobAndCategoryCodes = Object.entries(jobs)
    .map(([jobName, job]) => {
      const isValidMlTask = !mlTask || mlTask === job.mlTask;
      if (isValidMlTask && canChangeClass(jobName, jobs, changedLayerTool)) {
        const allowedCategories = jobs?.[jobName]?.content?.categories ?? {};
        const currentAllowedJobAndCategoryCodes = Object.entries(allowedCategories).map(
          ([categoryCode, category]) => {
            if (
              !allowCurrentCategory &&
              jobName === annotation.jobName &&
              categoryCode === annotation.categories[0].name
            ) {
              return null;
            }
            if (
              mlTask === MachineLearningTask.OBJECT_RELATION &&
              startRelationCategory &&
              !category.startObjects?.includes(ANY_RELATION_VALUE) &&
              !category.startObjects?.includes(startRelationCategory)
            ) {
              return null;
            }
            if (
              mlTask === MachineLearningTask.NAMED_ENTITIES_RELATION &&
              startRelationCategory &&
              !category.startEntities?.includes(ANY_RELATION_VALUE) &&
              !category.startEntities?.includes(startRelationCategory)
            ) {
              return null;
            }
            return {
              categoryCode,
              categoryName: category.name,
              jobName,
            };
          },
        );
        return currentAllowedJobAndCategoryCodes;
      }
      return [];
    })
    .flat()
    .filter((obj: CategoryResponse | null): obj is CategoryResponse => {
      return !!obj;
    });
  return allowedJobAndCategoryCodes;
};

export const availableClassesToChange = (
  payload: AvailableClassesToChangePayload,
): CategoryResponse[] => {
  const { annotations, jsonInterface } = payload;
  const allowCurrentCategory = payload.allowCurrentCategory ?? annotations.length > 1;
  const jobs = jsonInterface?.jobs;

  if (!annotations.length || !jobs) {
    return [];
  }

  return annotations.reduce((commonCategoryResponses, annotation, index) => {
    const annotationCategoryResponses = getAllowedCategoryResponses(
      annotation,
      jobs,
      allowCurrentCategory,
    );

    if (commonCategoryResponses.length === 0 && index === 0) {
      return annotationCategoryResponses;
    }

    return commonCategoryResponses.filter(commonCategoryResponse =>
      annotationCategoryResponses.some(
        ({ categoryCode, categoryName, jobName }) =>
          commonCategoryResponse.categoryCode === categoryCode &&
          commonCategoryResponse.categoryName === categoryName &&
          commonCategoryResponse.jobName === jobName,
      ),
    );
  }, [] as CategoryResponse[]);
};
