// Have to disable eslint for this file that override some Leaflet methods
/* eslint-disable */
import L from 'leaflet';
import './KiliSVGRenderer';

const RADIUS = 5;

const computeCenter = (points: L.Point[]) => {
  const totalPoint = points.reduce((pointTotal, point) => {
    return point.add(pointTotal);
  }, new L.Point(0, 0));
  return totalPoint.divideBy(points.length);
};

const drawCircle = (center: L.Point | undefined, radius = RADIUS) => {
  if (!center || !center.x) {
    return '';
  }
  return `M ${center.x - radius}, ${center.y} a ${radius},${radius} 0 1, 0 ${
    radius * 2
  },0 a ${radius},${radius} 0 1, 0 ${-radius * 2},0`;
};

const computeOrientationMark = (rings: L.Point[][]): L.Point | undefined => {
  const currentRing = rings[0];
  if (currentRing?.length !== 4) {
    return undefined;
  }
  const center = computeCenter([currentRing[0], currentRing[1]]);

  return center;
};

const doublePointsToPath = (rings: L.Point[][], closed: boolean) => {
  // @ts-ignore
  const basePath = L.SVG.pointsToPath(rings, closed);
  const orientationMarkerCoord = computeOrientationMark(rings);
  // @ts-ignore
  const orientationMarkerPath =
    drawCircle(orientationMarkerCoord) + drawCircle(orientationMarkerCoord, 2);
  const res = `${basePath}${orientationMarkerPath}`;
  return res;
};

class KiliOrientedSVGRenderer extends L.KiliSVGRenderer {
  _updatePoly(layer: L.Layer, closed: boolean) {
    // @ts-ignore
    this._setPath(layer, doublePointsToPath(layer._parts, closed));
  }
}

L.KiliOrientedSVGRenderer = KiliOrientedSVGRenderer;
