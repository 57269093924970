/* eslint-disable no-underscore-dangle */

import L from 'leaflet';

import { APPLICATION_UPDATE_FIELD } from '../../../../../redux/application/slice';
import { store } from '../../../../../store';
import { UNDO_POINT_EVENT } from '../../../Image/LeafletMapv2/KiliLeafletLayers/KiliPoseEstimation/events';

const originalMethods = {
  addHooks: L.Draw.Polygon.prototype.addHooks,
  initialize: L.Draw.Polygon.prototype.initialize,
  removeHooks: L.Draw.Polygon.prototype.removeHooks,
};

L.Draw.Polygon.include({
  addHooks(this: L.Draw.Polygon) {
    originalMethods.addHooks?.call(this);
    if (this._map) {
      this._map.on('click', this.resetSavedLastPoint, this);
      this._map.doubleClickZoom.disable();
    }
    this._map.on(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);
    this._map.on('mousedown touchstart', this.mouseDown, this);
  },

  initialize(this: L.Draw.Polygon, map: L.DrawMap, options: L.DrawOptions.PolygonOptions) {
    if (!options) return;
    this.savedLastPoint = null;
    originalMethods.initialize.call(this, map, options);

    this.setCanUndo = (canUndo: boolean) => {
      store.dispatch(APPLICATION_UPDATE_FIELD({ path: 'canUndo', value: canUndo }));
    };
  },

  mouseDown(this: L.Draw.Polygon) {
    this.setCanUndo(false);
  },

  removeHooks(this: L.Draw.Polygon) {
    this.setCanUndo(true);
    originalMethods.removeHooks?.call(this);
    if (this._map) {
      this._map.off('click', this.resetSavedLastPoint, this);
      this._map.doubleClickZoom.enable();
    }
    this._map.off(UNDO_POINT_EVENT, this.undoOrRedoPoint, this);
    this._map.off('mousedown touchstart', this.mouseDown, this);
  },

  resetSavedLastPoint(this: L.Draw.Polygon) {
    this.savedLastPoint = null;
  },

  undoOrRedoPoint(this: L.Draw.Polygon, event: { shift: boolean }) {
    const { shift } = event;
    if (shift) {
      if (!this.savedLastPoint) return;
      this._poly.addLatLng(this.savedLastPoint._latlng);
      this._map.addLayer(this.savedLastPoint);
      this._markers.push(this.savedLastPoint);
      this.savedLastPoint = null;
      return;
    }

    const lastMarker = this._markers.pop();
    if (lastMarker) {
      this.savedLastPoint = lastMarker;
      this._map.removeLayer(lastMarker);
    }
    const latlngs = this._poly.getLatLngs();
    latlngs.pop();
    this._poly.setLatLngs(latlngs);
  },
});
