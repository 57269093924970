import { Input, MachineLearningTask } from '@kili-technology/types';
import { generateHash } from '@kili-technology/utilities';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import { shallowEqual, useSelector } from 'react-redux';

import { type State } from '@/redux/types';

import { type JobCategoryPropsType } from './types';

import { jobsResponses } from '../../../../../../../../redux/jobs/selectors';
import { type Responses, type ResponsesTasks } from '../../../../../../../../redux/jobs/types';
import { type KiliAnnotation } from '../../../../../../../../services/jobs/setResponse';
import { VALUE } from '../../../../../../../../services/jsonInterface/accordion';
import { useStore } from '../../../../../../../../zustand';

export const computeNewClassificationCategoryCodes = (
  clickedCategoryCode: string,
  input: Input,
  previousCategoryCodes: string[],
  required: boolean,
) => {
  if (input === Input.RADIO || input === Input.SINGLE_DROPDOWN) {
    if (required) {
      return [clickedCategoryCode];
    }

    if (previousCategoryCodes.includes(clickedCategoryCode)) {
      return [];
    }

    return [clickedCategoryCode];
  }
  return previousCategoryCodes.includes(clickedCategoryCode)
    ? previousCategoryCodes.filter((categoryCode: string) => categoryCode !== clickedCategoryCode)
    : [...previousCategoryCodes, clickedCategoryCode];
};

export const getCategoryAnnotations = (
  categoryCode: string,
  jobAnnotations: KiliAnnotation[],
): KiliAnnotation[] =>
  jobAnnotations
    .filter(annotation => !!annotation)
    .filter(
      annotation =>
        annotation.categories.filter(category => category.name === categoryCode).length > 0,
    );

export const getObjects = (annotations: KiliAnnotation[]): KiliAnnotation[] => {
  // The following statement attributes MID to objects that were inserted without MID (for retrocompatibility)
  const annotationsWithHash = annotations.map((annotation: KiliAnnotation) => ({
    ...annotation,
    mid: annotation.mid ?? generateHash(),
  }));
  return _uniqBy(annotationsWithHash, (annotation: KiliAnnotation) => annotation.mid);
};

export const useIsCategoryExpanded = (path: string[]) => {
  const accordionTree = useStore(state => state.labelInterface.accordionTree, shallowEqual);
  return _get(accordionTree, path.concat(VALUE), false);
};

const getAnnotationsCategoryEmptyOfVisibleObjects = ({
  jobName,
  mlTask,
  responses,
}: {
  jobName: string;
  mlTask: MachineLearningTask;
  responses: Responses;
}) => {
  if (
    mlTask === MachineLearningTask.ASSET_ANNOTATION ||
    mlTask === MachineLearningTask.CLASSIFICATION ||
    mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
    mlTask === MachineLearningTask.TRANSCRIPTION ||
    mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION ||
    mlTask === MachineLearningTask.COMPARISON
  ) {
    return [];
  }
  const mlTaskResponse = (responses as ResponsesTasks)?.[mlTask];
  return (mlTaskResponse?.[jobName]?.annotations || []) as KiliAnnotation[];
};

export const useIsCategoryEmptyOfVisibleObjects = ({
  jobName,
  mlTask,
  categoryCode,
}: {
  categoryCode: string;
  jobName: string;
  mlTask: MachineLearningTask;
}) => {
  const mids = useSelector((state: State) => {
    const responses = jobsResponses(state);
    const annotations = getAnnotationsCategoryEmptyOfVisibleObjects({ jobName, mlTask, responses });
    const catAnnotations = getCategoryAnnotations(categoryCode, annotations);
    return catAnnotations.map(annotation => annotation.mid);
  }, shallowEqual);
  const isCategoryEmptyOfVisibleObjects = useStore(
    state =>
      mids.length === 0 || !mids.every(mid => state.labelInterface.hiddenObjectIds.includes(mid)),
  );
  return isCategoryEmptyOfVisibleObjects;
};

export const getCategoryIdentity = (props: JobCategoryPropsType) => {
  return {
    categoryCode: props.category[0],
    categoryName: props.category[1].name,
    jobName: props.job[0],
    mlTask: props.job[1].mlTask,
    parentMid: props.parentMid,
    path: props.path,
  };
};
