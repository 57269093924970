import {
  type Annotation,
  type FrameJsonResponse,
  type JobAnnotation,
  type JobResponse,
  type Jobs,
  type JsonResponse,
  LabelVersion,
} from '@kili-technology/types';
import { set as setFp } from 'lodash/fp';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import fromEntries from 'object.fromentries';

import { LabelType } from '@/__generated__/globalTypes';
import {
  ANNOTATIONS,
  MID,
  ML_TASK,
} from '@/components/InterfaceBuilder/FormInterfaceBuilder/constants';

import { buildJsonResponse } from '../jobs/helpers';
import { type Responses } from '../jobs/types';
import {
  type ChildVideoJob,
  type FrameResponses,
  type VideoJob,
  type VideoJobs,
} from '../label-frames/types';

// used to convert data before sending it to backend
export const convertFrameResponsesToJsonResponse = (
  jobs: Jobs,
  responsesFrame: VideoJobs,
): JsonResponse | undefined => {
  const fatherJobsList = Object.keys(jobs).filter(jobName => !_get(jobs, [jobName, 'isChild']));
  let mlResponsesFrame: Responses = {};
  Object.entries(responsesFrame).forEach(([jobName, responseJob]) => {
    const mlTask = _get(jobs, [jobName, ML_TASK]);
    if (mlTask) {
      _set(mlResponsesFrame, `${mlTask}.${jobName}`, responseJob);
    }
  });
  Object.entries(responsesFrame).forEach(([jobNameOrMid, responseJob]) => {
    const mlTask = _get(jobs, [jobNameOrMid, ML_TASK]);
    const isResponseChild = !mlTask;
    if (isResponseChild) {
      Object.entries(mlResponsesFrame).forEach(([childMlTask, childJobs]) => {
        Object.entries(childJobs ?? {}).forEach(([childJobName, childJob]) => {
          const annotations = _get(childJob, 'annotations', []);
          annotations.forEach((annotation: Annotation, index: number) => {
            if (_get(annotation, MID) === jobNameOrMid) {
              mlResponsesFrame = setFp(
                [childMlTask, childJobName, ANNOTATIONS, index, 'children'],
                responseJob,
                mlResponsesFrame,
              );
            }
          });
        });
      });
    }
  });
  return buildJsonResponse(jobs, mlResponsesFrame, fatherJobsList);
};

const isJobWithAnnotations = (jobResponse: JobResponse): jobResponse is JobAnnotation =>
  'annotations' in jobResponse;

type VideoJsonResponse = { [jobName: string]: ChildVideoJob };

// Used to initially load data from backend, and convert it to something usable by the frontend
export const convertJsonResponseToFrameResponses = (
  jsonResponse: FrameJsonResponse,
  labelType: LabelType,
): FrameResponses => {
  return fromEntries(
    Object.entries(jsonResponse).map(([frame, response]) => {
      const childrenResponses: { [annotationMid: string]: VideoJsonResponse } = {};
      const processedResponse = fromEntries(
        Object.entries(response).map<[string, VideoJob]>(([jobName, jobResponse]) => {
          // convert children properties of each annotation
          if (!isJobWithAnnotations(jobResponse) || !Array.isArray(jobResponse?.annotations)) {
            return [jobName, jobResponse as VideoJob];
          }

          const labelVersion =
            labelType === LabelType.PREDICTION ? LabelVersion.PREDICTION : LabelVersion.DEFAULT;

          const annotations = jobResponse.annotations.map(({ children, ...newAnnotation }) => {
            if (!_isEmpty(children)) {
              childrenResponses[newAnnotation.mid] = children as VideoJsonResponse;
            }
            // We are missing the mlTask here, but to have it we need to provide a jsonInterfaceJobs
            return { ...newAnnotation, jobName, labelVersion };
          });
          return [jobName, { ...jobResponse, annotations } as VideoJob];
        }),
      );
      return [frame, { ...processedResponse, ...childrenResponses }];
    }),
  );
};
