import {
  type ClassificationAnnotation,
  type ClassificationAtPageLevelAnnotation,
  type JsonCategories as Categories,
  MachineLearningTask,
  type Tool,
} from '@kili-technology/types';
import { createSelector } from 'reselect';

import { generateDefaultJobInstruction, getJobIdentity } from './helpers';
import { type JobRendererPropsType } from './types';

import { CATEGORIES } from '../../../../../../../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import usePagination from '../../../../../../../../components/asset-ui/Pdf/PaginationToolbar/PaginationStore';
import { applicationSearchVisibility } from '../../../../../../../../redux/application/selectors';
import { jobsResponses } from '../../../../../../../../redux/jobs/selectors';
import {
  type PageLevelWrapper,
  type ResponsesTasks,
} from '../../../../../../../../redux/jobs/types';
import { jobsNameWithoutInstructionGroupedByMlTask } from '../../../../../../../../redux/project/selectors';
import { createDeepSelectorFunction } from '../../../../../../../../redux/selectors';
import { type State } from '../../../../../../../../redux/types';
import { type KiliAnnotation } from '../../../../../../../../services/jobs/setResponse';
import { isJobHidden } from '../../../helpers';

export const getJobNameWithParentMid = (state: State, ownProps: JobRendererPropsType): string => {
  return ownProps?.parentMid ? `${ownProps?.parentMid}.${ownProps.job[0]}` : ownProps.job[0];
};

const getJobInstruction = (state: State, ownProps: JobRendererPropsType): string | undefined => {
  return ownProps.job[1]?.instruction;
};

const getJobFirstTool = (state: State, ownProps: JobRendererPropsType): Tool | undefined => {
  return ownProps.job?.[1]?.tools?.[0];
};

const getJobIdentityFromOwnProps = (state: State, ownProps: JobRendererPropsType) =>
  getJobIdentity(ownProps);

const getPageNumberFromOwnProps = (
  state: State,
  { pageNumber }: { pageNumber: number },
): number => {
  return pageNumber;
};

export const isJobHiddenBySearchSelector = createSelector(
  [getJobNameWithParentMid, applicationSearchVisibility],
  (jobName, visibility) => isJobHidden(visibility, jobName),
);

const jobClassificationResponse = createSelector(
  [getJobIdentityFromOwnProps, jobsResponses],
  (identity, responses): ClassificationAnnotation => {
    const responseToTakeResponseFrom = (
      identity?.parentMid ? responses?.[identity.parentMid] : responses
    ) as ResponsesTasks;

    const { mlTask, jobName } = identity;
    if (mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION) {
      const page = usePagination.getState().currentPage;
      return (responseToTakeResponseFrom?.[mlTask]?.[jobName] || {}).byId?.[
        page
      ] as ClassificationAtPageLevelAnnotation;
    }

    if (mlTask === MachineLearningTask.CLASSIFICATION) {
      return (responseToTakeResponseFrom?.[mlTask]?.[jobName] || {}) as ClassificationAnnotation;
    }
    return {} as ClassificationAnnotation;
  },
);
const jobClassificationAtPageLevelResponse = createSelector(
  [getJobIdentityFromOwnProps, jobsResponses],
  (identity, responses): PageLevelWrapper<ClassificationAtPageLevelAnnotation> => {
    const { mlTask, jobName } = identity;
    if (mlTask !== MachineLearningTask.PAGE_LEVEL_CLASSIFICATION) return { allIds: [], byId: {} };

    return responses?.[mlTask]?.[jobName] || { allIds: [], byId: {} };
  },
);

export const jobCategories = (state: State, ownProps: JobRendererPropsType): Categories =>
  ownProps?.job?.[1]?.content?.categories ?? {};

export const categoryCodesSelectedInJobAtPageLevel = createSelector(
  [getPageNumberFromOwnProps, jobClassificationAtPageLevelResponse],
  (pageNumber, classificationAtPageLevelAnnotations) =>
    (classificationAtPageLevelAnnotations?.byId?.[pageNumber]?.[CATEGORIES] || []).map(
      category => category.name,
    ),
);

export const categoryCodesSelectedInJob = createSelector(
  [jobClassificationResponse],
  classificationAnnotations =>
    (classificationAnnotations?.[CATEGORIES] || []).map(category => category.name),
);

export const jobAnnotations = createDeepSelectorFunction()(
  [getJobIdentityFromOwnProps, jobsResponses],
  (jobIdentity, responses) => {
    const { mlTask, jobName } = jobIdentity;
    if (
      mlTask === MachineLearningTask.ASSET_ANNOTATION ||
      mlTask === MachineLearningTask.CLASSIFICATION ||
      mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
      mlTask === MachineLearningTask.TRANSCRIPTION ||
      mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION ||
      mlTask === MachineLearningTask.COMPARISON
    ) {
      return [];
    }
    const mlTaskResponse = (responses as ResponsesTasks)?.[mlTask];
    return (mlTaskResponse?.[jobName]?.annotations || []) as KiliAnnotation[];
  },
);

export const isJobRequired = (state: State, ownProps: JobRendererPropsType): boolean =>
  !!ownProps?.job[1]?.required;

export const jobInstructionSelector = createSelector(
  [
    getJobIdentityFromOwnProps,
    getJobInstruction,
    getJobFirstTool,
    jobsNameWithoutInstructionGroupedByMlTask,
  ],
  (jobIdentity, jsonInstruction, firstTool, jobsNameWithoutInstructionGroupedByMlTaskValue) => {
    const { jobName, mlTask } = jobIdentity;
    return (
      jsonInstruction ||
      generateDefaultJobInstruction(
        jobName,
        mlTask,
        firstTool,
        jobsNameWithoutInstructionGroupedByMlTaskValue,
      )
    );
  },
);
