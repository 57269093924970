/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

L.DivOverlay.include({
  _updatePosition() {
    if (!this._map) {
      return;
    }

    let pos = this._map.latLngToLayerPoint(this._latlng);
    let offset = L.point(this.options.offset);
    const anchor = this._getAnchor();

    if (this._map._rotate) {
      pos = this._map.rotatedPointToMapPanePoint(pos);
    }

    if (this._zoomAnimated) {
      L.DomUtil.setPosition(this._container, pos.add(anchor));
    } else {
      offset = offset.add(pos).add(anchor);
    }

    this._containerBottom = -offset.y;
    this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x;

    // bottom position the popup in case the height of the popup changes (images loading etc)
    this._container.style.bottom = `${this._containerBottom}px`;
    this._container.style.left = `${this._containerLeft}px`;
  },
});
