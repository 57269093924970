import { BboxPartial, Documentation, Support } from '@kili-technology/icons';
import MenuItemsList, { type MenuItemData } from '@kili-technology/menu-items-list';

enum MenuItems {
  API = 'API Documentation',
  DEMO = 'Demo projects',
  DOCS = 'Documentation',
  START = 'Getting Started',
  SUPPORT = 'Contact support',
  US = 'Contact us',
}

interface PropsType {
  handleApiDocs: () => void;
  handleContactSupport: () => void;
  handleDemoProject: () => void;
  handleDocs: () => void;
  handleGettingStarted: () => void;
  isPaidLicense: boolean;
}

const HelpMenu = ({
  handleApiDocs,
  handleContactSupport,
  handleDemoProject,
  handleDocs,
  handleGettingStarted,
  isPaidLicense,
}: PropsType): JSX.Element => {
  const menuItems = [
    {
      label: MenuItems.START,
      startAdornment: <Documentation />,
      value: MenuItems.START,
    },
    {
      label: MenuItems.DOCS,
      startAdornment: <Documentation />,
      value: MenuItems.DOCS,
    },
    {
      label: MenuItems.API,
      startAdornment: <Documentation />,
      value: MenuItems.API,
    },
    {
      label: MenuItems.DEMO,
      startAdornment: <BboxPartial />,
      value: MenuItems.DEMO,
    },
  ];

  menuItems.push({
    label: isPaidLicense ? MenuItems.SUPPORT : MenuItems.US,
    startAdornment: <Support />,
    value: MenuItems.SUPPORT,
  });

  const onSelectedMenuItem = (item: MenuItemData) => {
    switch (item.value) {
      case MenuItems.START:
        handleGettingStarted();
        break;
      case MenuItems.DOCS:
        handleDocs();
        break;
      case MenuItems.API:
        handleApiDocs();
        break;
      case MenuItems.DEMO:
        handleDemoProject();
        break;
      case MenuItems.SUPPORT:
        handleContactSupport();
        break;
      default:
        break;
    }
  };

  return <MenuItemsList handleSelectItem={onSelectedMenuItem} menuItems={menuItems} />;
};

export default HelpMenu;
