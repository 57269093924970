import type * as H from 'history';
import { generatePath } from 'react-router-dom';

import { goToProjectQueue } from './startLabeling';

import { rootRoutes } from '../../pages/RootModule/RootPaths';

export type PushRoute = (path: H.History.Path) => void;

export const goToConversation = ({
  assetId,
  inReview,
  labelID,
  projectId,
  pushRoute,
  userID,
}: {
  assetId: string | undefined;
  inReview: boolean;
  labelID?: string;
  projectId: string;
  pushRoute: PushRoute;
  userID: string;
}): void => {
  if (assetId) {
    const labelRoute = rootRoutes.ROOT_LABEL_INSTR_FOLLOWING_ROUTE;
    const reviewRoute = rootRoutes.ROOT_LABEL_INSTR_FOLLOWING_ROUTE;
    const queryParams =
      labelID && inReview ? `?userID=${userID}&labelID=${labelID}` : `?userID=${userID}`;

    pushRoute(
      `${generatePath(inReview ? reviewRoute.path : labelRoute.path, {
        assetID: assetId,
      })}${queryParams}`,
    );
  } else {
    goToProjectQueue({ projectId, pushRoute });
  }
};
