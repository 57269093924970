import {
  type Annotation,
  type AssetAnnotation,
  type AnnotationCategory as JobCategory,
  type ClassificationAnnotation,
  type ClassificationAtPageLevelAnnotation,
  DetectionTasks,
  type FrameJsonResponse,
  type Job,
  type JobAnnotation,
  type JobAtPageLevelAnnotation,
  type Jobs,
  type JobResponse,
  type JsonResponse,
  MachineLearningTask,
  type PoseEstimationPoint,
  type Tool,
  type ObjectAnnotation,
  type ObjectRelation,
  type PoseEstimationAnnotation,
  type PoseEstimationMarker,
  type TranscriptionAnnotation,
  type TranscriptionAtPageLevelAnnotation,
} from '@kili-technology/types';
import {
  get as _get,
  flatten as _flatten,
  isEmpty as _isEmpty,
  isObject as _isObject,
} from 'lodash';

import {
  type ObjectDetectionAnnotations,
  type ObjectDetectionJobs,
  type ObjectRelationJobs,
  type PageLevelWrapper,
  type PoseEstimationJobs,
  type Responses,
  type ResponsesTasks,
  type ClassificationJobs,
  type RelationJobs,
  type NamedEntitiesRecognitionJobs,
} from './types';

import {
  ANNOTATIONS,
  CATEGORIES,
  CHILDREN,
  CONTENT,
  MID,
  NAME,
  POINTS,
} from '../../components/InterfaceBuilder/FormInterfaceBuilder/constants';
import { memoizedGetNameForCategory } from '../../components/helpers';
import { type KiliAnnotation, type KiliAnnotationProvider } from '../../services/jobs/setResponse';
import { MID_SPLIT_POINT_STRING } from '../../services/poseEstimation';
import { type HiddenEllipsis } from '../application/types';
import { type VideoJob, type VideoJobs, type ChildVideoJob } from '../label-frames/types';

const buildJobResponseAtPageLevel = (
  jobs: Jobs,
  responses: Responses,
  jobName: string,
  pageNumber: string,
): ClassificationAtPageLevelAnnotation | TranscriptionAtPageLevelAnnotation => {
  const job = jobs?.[jobName];
  const mlTask = job?.mlTask;
  if (mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION) {
    const jobResponse =
      responses?.PAGE_LEVEL_TRANSCRIPTION?.[jobName]?.byId?.[pageNumber] ??
      ({} as TranscriptionAtPageLevelAnnotation);
    return { ...jobResponse };
  }
  const jobResponse = responses?.PAGE_LEVEL_CLASSIFICATION?.[jobName]?.byId?.[pageNumber];
  if (!jobResponse) {
    return {} as ClassificationAtPageLevelAnnotation;
  }
  const categories = jobResponse?.categories ?? [];
  return {
    ...jobResponse,
    [CATEGORIES]: categories.map(category => {
      const categoryName = category?.name;
      const childrenJobsList = job?.content?.categories?.[categoryName]?.children ?? [];
      if (childrenJobsList.length === 0) {
        return category;
      }
      const childrenJsonResponse = childrenJobsList.reduce<JsonResponse>((acc, childrenJobName) => {
        const childrenJobResponse = buildJobResponseAtPageLevel(
          jobs,
          responses,
          childrenJobName,
          pageNumber,
        );
        return { ...acc, [childrenJobName]: childrenJobResponse };
      }, {});

      return {
        ...category,
        [CHILDREN]: childrenJsonResponse,
      };
    }),
  };
};

export const buildJsonResponse = (
  jobs: Jobs,
  responses: Responses,
  requiredJobsList: string[],
): JsonResponse | undefined => {
  if (!_isObject(responses) || _isEmpty(responses)) {
    return undefined;
  }
  const jsonResponse = {} as JsonResponse;
  Object.entries(responses).forEach(([mlTask, jobsForMlTask]) => {
    if (!Object.values(MachineLearningTask).includes(mlTask as MachineLearningTask)) {
      return undefined;
    }
    if (!jobsForMlTask) {
      return undefined;
    }
    Object.entries(jobsForMlTask).forEach(([jobName, response]) => {
      if (
        !jobName ||
        _isEmpty(response) ||
        (!requiredJobsList.includes(jobName) && mlTask !== MachineLearningTask.ASSET_ANNOTATION)
      ) {
        return undefined;
      }
      const job = jobs?.[jobName];
      const jobMlTask = job?.mlTask;
      if (jobMlTask !== mlTask && mlTask !== MachineLearningTask.ASSET_ANNOTATION) {
        return undefined;
      }
      if (jobMlTask === MachineLearningTask.TRANSCRIPTION) {
        const transcriptionResponse = response as TranscriptionAnnotation;
        const transcriptionText = transcriptionResponse?.text || '';
        if (!transcriptionText) {
          return undefined;
        }
        jsonResponse[jobName] = transcriptionResponse;
      } else if (DetectionTasks.includes(mlTask)) {
        const responseAnnotation = response as JobAnnotation;
        const annotations = responseAnnotation?.annotations || [];
        if (annotations.length === 0) {
          return undefined;
        }
        jsonResponse[jobName] = {
          ...responseAnnotation,
          [ANNOTATIONS]: annotations.map(annotation => {
            const {
              // @ts-expect-error score is not in Annotation type
              score,
              // @ts-expect-error allPoints is not in Annotation type
              allPoints,
              jobName: aJobName,
              // @ts-expect-error mlTask is not in Annotation type
              mlTask: aMlTask,
              ...cleanedAnnotation
            } = annotation;
            const { annotation: annotationChildren, points: pointsChildren } =
              computeChildrenJsonResponseForAnnotation(jobs, job, responses, annotation);

            const childrenResponse = {
              [CHILDREN]: annotationChildren,
            };
            const annotationWithChildren = {
              ...cleanedAnnotation,
              ...childrenResponse,
            };
            if (pointsChildren.length === 0) {
              return annotationWithChildren;
            }
            const indicesWithChildren = pointsChildren.map(point => point.index);
            const annotationPoints = _get(
              annotationWithChildren,
              POINTS,
              [],
            ) as PoseEstimationMarker[];
            return {
              ...annotationWithChildren,
              [POINTS]: annotationPoints.map((pointAnnotation, pointIndex) => {
                const indexOfPointIndexInIndices = indicesWithChildren.indexOf(pointIndex);
                const hasPointChildren = indexOfPointIndexInIndices >= 0;
                if (!hasPointChildren) {
                  return pointAnnotation;
                }
                return {
                  ...pointAnnotation,
                  [CHILDREN]: pointsChildren?.[indexOfPointIndexInIndices]?.response,
                };
              }),
            };
          }),
        };
      } else if (
        mlTask === MachineLearningTask.PAGE_LEVEL_CLASSIFICATION ||
        mlTask === MachineLearningTask.PAGE_LEVEL_TRANSCRIPTION
      ) {
        const annotatedPages = (response as PageLevelWrapper<ClassificationAtPageLevelAnnotation>)
          .allIds;

        const jobResponseForEachPage = annotatedPages.reduce<JobAtPageLevelAnnotation>(
          (acc, pageNumber) => {
            const jobResponse = buildJobResponseAtPageLevel(jobs, responses, jobName, pageNumber);
            return { ...acc, [pageNumber]: jobResponse };
          },
          {},
        );

        jsonResponse[jobName] = jobResponseForEachPage;
      } else if (mlTask === MachineLearningTask.ASSET_ANNOTATION) {
        jsonResponse[jobName] = response as AssetAnnotation;
      } else {
        const categories =
          (response as ClassificationAnnotation)?.categories || ([] as JobCategory[]);
        const responseWithChildren = {
          ...(response as ClassificationAnnotation),
          [CATEGORIES]: categories.map(category => {
            const categoryName = category?.name;
            const childrenJobsList = job?.content?.categories?.[categoryName]?.children ?? [];
            if (childrenJobsList.length === 0) {
              return category;
            }
            const childrenJsonResponse = buildJsonResponse(jobs, responses, childrenJobsList);
            return {
              ...category,
              [CHILDREN]: childrenJsonResponse,
            };
          }),
        };
        jsonResponse[jobName] = responseWithChildren;
      }
      return undefined;
    });
    return undefined;
  });
  return jsonResponse;
};

type ChildrenJsonResponseForAnnotationOutput = {
  annotation: JsonResponse | undefined;
  points: {
    index: number;
    response: JsonResponse | undefined;
  }[];
};
export const computeChildrenJsonResponseForAnnotation = (
  jobs: Jobs,
  job: Job,
  responses: Responses,
  annotation: Annotation,
): ChildrenJsonResponseForAnnotationOutput => {
  const categoryName = _get(annotation, [CATEGORIES, 0, NAME]);
  const categoryPath = [CONTENT, CATEGORIES, categoryName] as (string | number)[];
  const childrenJobsList = _get(job, categoryPath.concat(CHILDREN), []) || [];
  const mid: string = _get(annotation, MID);
  const responsesForChild = _get(responses, mid) as Responses;
  const responsesForChildFrame = annotation?.[CHILDREN];
  const responseOfAnnotationChildren = responsesForChild
    ? buildJsonResponse(jobs, responsesForChild, childrenJobsList)
    : responsesForChildFrame || {};

  const pointList = _get(
    job,
    [CONTENT, CATEGORIES, categoryName, POINTS],
    [],
  ) as PoseEstimationPoint[];
  const responsePathChildrenForPoints: {
    index: number;
    response: JsonResponse | undefined;
  }[] = [];
  pointList.forEach((point, index) => {
    const responsesForPoint = _get(
      responses,
      `${mid}${MID_SPLIT_POINT_STRING}${point.code}`,
    ) as Responses;
    const childrenJobsForPoint =
      _get(job, categoryPath.concat(...[POINTS, index, CHILDREN]), []) || [];
    const responseOfPointChildren =
      buildJsonResponse(jobs, responsesForPoint, childrenJobsForPoint) || {};
    const pointAnnotations = (annotation as PoseEstimationAnnotation)?.points || [];
    const indexOfPointInAnnotation = pointAnnotations.map(p => p.code).indexOf(point.code);
    if (indexOfPointInAnnotation < 0) {
      return;
    }
    responsePathChildrenForPoints.push({
      index: indexOfPointInAnnotation,
      response: responseOfPointChildren,
    });
  });
  return { annotation: responseOfAnnotationChildren, points: responsePathChildrenForPoints };
};

export const getJobNameFromMid = (mid: string, annotations: KiliAnnotation[]): string => {
  if (!annotations) return '';
  const annotationMid = annotations.filter(tuple => _get(tuple, MID) === mid);
  if (annotationMid.length > 0) {
    return annotationMid[0].jobName ?? '';
  }
  return '';
};

export const getAnnotationFromMidAndAnnotations = (
  mid: string | undefined,
  annotations: KiliAnnotation[],
): KiliAnnotation | undefined => annotations.find(annotation => annotation.mid === mid);

export const getToolFromJobName = (jobs: Jobs, jobName: string): Tool | undefined =>
  jobs?.[jobName]?.tools?.[0];

export const getMlTaskFromJobName = (
  jobs: Jobs,
  jobName: string,
): MachineLearningTask | undefined => jobs?.[jobName]?.mlTask;

export const renewHiddenEllipsis = (responses: Responses): HiddenEllipsis[] => {
  let hiddenEllipsis = [] as HiddenEllipsis[];
  Object.entries(responses)
    .filter(([mlTask, _]) => mlTask === MachineLearningTask.NAMED_ENTITIES_RECOGNITION)
    .forEach(([_, jobs]) =>
      Object.entries(jobs || {}).forEach(([_key, job]) => {
        hiddenEllipsis = hiddenEllipsis.concat(
          _get(job, ANNOTATIONS, []).map((annotation: Annotation) => {
            return { isEllipsed: true, mid: _get(annotation, MID, '') } as HiddenEllipsis;
          }),
        );
      }),
    );
  return hiddenEllipsis;
};

type SubJobKeyAnnotation = { subJobName: string; subJobValue: ChildVideoJob };
export type KeyAnnotation = { job?: VideoJob; keyFrame: number; subJobs?: SubJobKeyAnnotation[] };

export const getAnnotationFromMid = (
  mid: string | undefined,
  responses: Responses,
): KiliAnnotation | null => {
  if (!responses || !mid) return null;
  const objects = getAllObjects(responses);
  const annotations = objects.filter(annotation => _get(annotation, MID) === mid);
  if (annotations.length === 0) return null;
  return annotations[0] as KiliAnnotation;
};

export const getAllObjects = (responses: Responses | undefined): Annotation[] => {
  if (!responses) return [];
  return _flatten(
    Object.values(responses).map(jobs =>
      jobs
        ? _flatten(Object.values(jobs).map(job => (job as JobAnnotation)?.annotations ?? []))
        : [],
    ),
  );
};

export const getNextFrameWithKeyFrameForObjectDetection = ({
  newFrameResponses,
  currentFrame,
  jobName,
  mid,
}: {
  currentFrame: number;
  jobName: string;
  mid: string;
  newFrameResponses: Record<string, VideoJobs>;
}) =>
  Object.entries(newFrameResponses)
    .slice(currentFrame + 1)
    .find(([_, response]) => {
      const classicResponse = response[jobName] as ObjectDetectionAnnotations;
      return classicResponse?.annotations?.some(
        annotation => annotation.mid === mid && annotation.isKeyFrame,
      );
    });

export const DEFAULT_LIST = [];
export const DEFAULT_OBJECT = {};

export const getImageRotation = (response: ResponsesTasks): number =>
  response.ASSET_ANNOTATION?.ROTATION_JOB.rotation ?? 0;

export const getImageAnnotations = (
  objectDetectionsJobs: PoseEstimationJobs | ObjectDetectionJobs,
): KiliAnnotationProvider<ObjectAnnotation>[] => {
  const objectDetectionJobsNames = Object.keys(objectDetectionsJobs);
  if (objectDetectionJobsNames.length === 0) return DEFAULT_LIST;
  return _flatten(
    objectDetectionJobsNames.map(objectDetectionJobsName =>
      (objectDetectionsJobs?.[objectDetectionJobsName]?.[ANNOTATIONS] || DEFAULT_LIST).map(
        (annotation: ObjectAnnotation) => ({
          ...annotation,
          jobName: objectDetectionJobsName,
        }),
      ),
    ),
  );
};

export const getDetectionTaskAnnotations = (
  objectDetectionsJobs:
    | PoseEstimationJobs
    | ObjectDetectionJobs
    | ObjectRelationJobs
    | NamedEntitiesRecognitionJobs,
): KiliAnnotationProvider<ObjectAnnotation>[] => {
  const objectDetectionJobsNames = Object.keys(objectDetectionsJobs);
  if (objectDetectionJobsNames.length === 0) return DEFAULT_LIST;
  return _flatten(
    objectDetectionJobsNames.map(objectDetectionJobsName =>
      (objectDetectionsJobs?.[objectDetectionJobsName]?.[ANNOTATIONS] || DEFAULT_LIST).map(
        (annotation: ObjectAnnotation) => ({
          ...annotation,
          jobName: objectDetectionJobsName,
        }),
      ),
    ),
  );
};

export const getImageRelationAnnotations = (
  objectsRelationJobs: ObjectRelationJobs,
): ObjectRelation[] => {
  const objectsRelationJobsNames = Object.keys(objectsRelationJobs);
  if (objectsRelationJobsNames.length === 0) return DEFAULT_LIST;
  return _flatten(
    objectsRelationJobsNames.map(objectsRelationJobsName =>
      (objectsRelationJobs[objectsRelationJobsName].annotations ?? []).map(
        (annotation: ObjectRelation) => ({ ...annotation, jobName: objectsRelationJobsName }),
      ),
    ),
  );
};

export const getObjectDetectionJobs = (
  responses: ResponsesTasks,
): PoseEstimationJobs | ObjectDetectionJobs => {
  const objectDetectionJobs = _get(responses, MachineLearningTask.OBJECT_DETECTION, DEFAULT_OBJECT);
  const poseEstimationJobs = _get(responses, MachineLearningTask.POSE_ESTIMATION, DEFAULT_OBJECT);
  return { ...objectDetectionJobs, ...poseEstimationJobs };
};

export const getDetectionTaskJobs = (
  responses: ResponsesTasks,
): PoseEstimationJobs | ObjectDetectionJobs | RelationJobs | NamedEntitiesRecognitionJobs => {
  const objectDetectionJobs = _get(responses, MachineLearningTask.OBJECT_DETECTION, DEFAULT_OBJECT);
  const nerJobs = _get(responses, MachineLearningTask.NAMED_ENTITIES_RECOGNITION, DEFAULT_OBJECT);
  const poseEstimationJobs = _get(responses, MachineLearningTask.POSE_ESTIMATION, DEFAULT_OBJECT);

  const nerRelationJobs = _get(
    responses,
    MachineLearningTask.NAMED_ENTITIES_RELATION,
    DEFAULT_OBJECT,
  );
  const objectRelationJobs = _get(responses, MachineLearningTask.OBJECT_RELATION, DEFAULT_OBJECT);

  return {
    ...objectDetectionJobs,
    ...nerJobs,
    ...poseEstimationJobs,
    ...nerRelationJobs,
    ...objectRelationJobs,
  };
};

export const getClassificationJobs = (responses: ResponsesTasks): ClassificationJobs => {
  return responses[MachineLearningTask.CLASSIFICATION] ?? DEFAULT_OBJECT;
};

export const getObjectRelationJobs = (responses: ResponsesTasks): ObjectRelationJobs =>
  _get(responses, MachineLearningTask.OBJECT_RELATION, DEFAULT_OBJECT);

export const isFrameJsonResponse = (
  json: JsonResponse | FrameJsonResponse,
): json is FrameJsonResponse => {
  return json && !!json['0'];
};

export const isClassificationAnnotation = (
  annotation: JobResponse,
): annotation is ClassificationAnnotation => {
  const casted = annotation as unknown as ClassificationAnnotation;
  return casted.categories && !!casted.categories.length;
};

export const categoriesRawToSorted = (classificationResponses: ClassificationJobs | undefined) => {
  if (!classificationResponses) return [];
  const categoriesNames: string[][] = Object.entries(classificationResponses)
    .map(([key, value]) =>
      isClassificationAnnotation(value)
        ? memoizedGetNameForCategory(key, classificationResponses[key].categories || []).sort()
        : [''],
    )
    .filter(d => !!d[0]);
  return categoriesNames;
};

const flattenJobChildren = (
  jobResponse: ClassificationAnnotation | TranscriptionAnnotation,
  jobName: string,
): JsonResponse => {
  let resObject: JsonResponse = {};
  if (!('categories' in jobResponse)) return { [jobName]: jobResponse };
  const categories = jobResponse?.categories.map(category => {
    const flatChildren = Object.entries(category[CHILDREN] ?? {})
      .map(([childrenName, children]) => {
        return flattenJobChildren(
          children as ClassificationAnnotation | TranscriptionAnnotation,
          childrenName,
        );
      })
      .reduce((acc, children) => ({ ...acc, ...children }), {});
    resObject = { ...resObject, ...flatChildren };
    return { ...category, [CHILDREN]: {} };
  });

  return {
    [jobName]: { ...jobResponse, categories },
    ...resObject,
  };
};

/**
 * LAB-2451
 * Children of classification needs to be flatten in order to be correctly used by the JV.
 * For each frame,
 *  check if the job is a classification job
 *  if so, flatten the children and put them at the root of the frame.
 * 
 * Example of a data : 
 * "0": {
    "CLASSIFICATION_JOB_2": {
      "categories": [
        {
          "confidence": 100,
          "name": "MAIN_1",
          "children": {
            "CLASSIFICATION_JOB_3": {
              "categories": [
                { "confidence": 100, "name": "MAIN_11", "children": {} }
              ],
              "isKeyFrame": true
            }
          }
        }
      ],
      "isKeyFrame": true
    },
    "ANNOTATION_JOB_COUNTER": {},
    "ANNOTATION_NAMES_JOB": {}
  },
 *
 * 
 * Will become :
 * 
  * "0": {
      "CLASSIFICATION_JOB_2": {
        "categories": [
          {
            "confidence": 100,
            "name": "MAIN_1",
            "children": {}
          }
        ],
        "isKeyFrame": true
      },
      "CLASSIFICATION_JOB_3": {
        "categories": [
          { "confidence": 100, "name": "MAIN_11", "children": {} }
        ],
        "isKeyFrame": true
      },
      "ANNOTATION_JOB_COUNTER": {},
      "ANNOTATION_NAMES_JOB": {}
    },
 * @param frameResponse
 */
export const flattenFrameResponse = (
  frameResponse: FrameJsonResponse,
  jobs: Jobs,
): FrameJsonResponse => {
  const newFrameResponse: FrameJsonResponse = Object.entries(frameResponse)
    .map(([frameNumber, jsonResponse]) => {
      return {
        [frameNumber]: Object.entries(jsonResponse)
          .map(([jobName, jobResponse]) => {
            if (jobs[jobName]?.mlTask !== MachineLearningTask.CLASSIFICATION)
              return { [jobName]: jobResponse };
            return flattenJobChildren(jobResponse as ClassificationAnnotation, jobName);
          })
          .reduce((acc: JsonResponse, job: JsonResponse) => ({ ...acc, ...job }), {}),
      };
    })
    .reduce((acc: FrameJsonResponse, job: FrameJsonResponse) => ({ ...acc, ...job }), {});
  return newFrameResponse;
};

export function isKiliAnnotation(annotation: ObjectAnnotation): annotation is KiliAnnotation {
  return typeof annotation.jobName === 'string';
}

export function isValidConfidenceScore(score: number): boolean {
  return score >= 0 && score <= 100;
}
