import { InputType } from '@/__generated__/globalTypes';
import { projectInputType } from '@/redux/selectors';
import { store } from '@/store';

/**
 * Whereas the current project is using the split feature.
 */
export function doesProjectUseSplit(): boolean {
  const inputType = projectInputType(store.getState());

  if ([InputType.LLM_RLHF, InputType.LLM_INSTR_FOLLOWING, InputType.VIDEO].includes(inputType)) {
    return true;
  }
  return false;
}
