import { type IState } from 'flagsmith/types';

import DEVELOPMENT_FLAGS from './developmentFlags';

import { isDevelopmentEnvironment, isUsingFlipt } from '../../config';
import useUser from '../../hooks/useUser';
import { store } from '../../store';
import { type BoolFlagName } from '../flagsOpenFeature/defaultFlags';
import { hasFeatureActive } from '../flagsOpenFeature/useFlag';

export const FLAG_KEY = 'flags';
export const flagsStateMap = new Map<string, IState>();

export const isFlagActivated = (flagName: string, email?: string): boolean => {
  if (isUsingFlipt) {
    return hasFeatureActive(flagName as BoolFlagName);
  }

  if (isDevelopmentEnvironment() && flagName in DEVELOPMENT_FLAGS) {
    return DEVELOPMENT_FLAGS?.[flagName as keyof typeof DEVELOPMENT_FLAGS](
      email ?? store.getState().user?.email ?? '',
    );
  }
  const flagsmithState = flagsStateMap.get(FLAG_KEY);
  if (!flagsmithState) return false;
  return flagsmithState.flags?.[flagName]?.enabled ?? false;
};

export default function useFlag(flagName: keyof typeof DEVELOPMENT_FLAGS): boolean {
  const { email } = useUser();

  return isFlagActivated(flagName, email);
}
