import L from 'leaflet';

/* eslint-disable no-underscore-dangle */

L.Edit.CircleMarker.include({
  _createMoveMarker() {
    const center = this._shape.getLatLng();
    this._moveMarker = this._createMarker(center, this.options.moveIcon);
    this._moveMarker.on('click', this._onMarkerClick, this);
    this._moveMarker.on('contextmenu', this._onContextMenu, this);
  },

  _createResizeMarker() {
    // To avoid an undefined check in L.Edit.SimpleShape.removeHooks
    this._resizeMarkers = [];
  },

  _move(latlng: L.LatLng) {
    if (this._resizeMarkers.length) {
      const resizemarkerPoint = this._getResizeMarkerPoint(latlng);
      // Move the resize marker
      this._resizeMarkers[0].setLatLng(resizemarkerPoint);
    }

    // Move the circle
    this._shape.setLatLng(latlng);
  },

  /**
   * Forwards context menu events to the shape to keep the default behaviour in edition mode.
   */
  _onContextMenu(event: { originalEvent: PointerEvent }) {
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    this._shape.getElement().dispatchEvent(new PointerEvent('contextmenu', event.originalEvent));
  },

  /**
   * Forwards click events to the shape to keep the default behaviour in edition mode.
   */
  _onMarkerClick(event: { originalEvent: PointerEvent }) {
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    this._shape.getElement().dispatchEvent(new PointerEvent('click', event.originalEvent));
  },

  _onMarkerDragEnd() {
    this._fireEdit();
  },

  _unbindMoveMarker() {
    this._moveMarker.off('click', this._onMarkerClick);
    this._moveMarker.off('contextmenu', this._onContextMenu);
  },

  addHooks() {
    const shape = this._shape;
    if (this._shape._map) {
      this._map = this._shape._map;
      this._map.doubleClickZoom.disable();
      shape.setStyle(shape.options.editing);

      if (shape._map) {
        this._map = shape._map;
        if (!this._markerGroup) {
          this._initMarkers();
        }
        this._map.addLayer(this._markerGroup);
      }
    }
  },

  removeHooks() {
    const shape = this._shape;

    shape.fire('mouseout');

    if (shape._map && this._map) {
      this._map.doubleClickZoom.enable();
      this._unbindMarker(this._moveMarker);
      this._unbindMoveMarker();

      for (let i = 0, l = this._resizeMarkers.length; i < l; i += 1) {
        this._unbindMarker(this._resizeMarkers[i]);
      }
      this._resizeMarkers = null;

      this._map.removeLayer(this._markerGroup);
      delete this._markerGroup;
    }

    this._map = null;
  },
});
