import { useQuery } from '@apollo/client';
import { dequal } from 'dequal';
import { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { type AssetWhere } from '@/__generated__/globalTypes';
import { type CountAssetsQuery } from '@/graphql/asset/__generated__/queries.graphql';
import { GQL_ASSETS_COUNT_INSPECT } from '@/graphql/asset/queries';
import { type MatchParams } from '@/pages/RootModule/RootPaths';
import { parsedQueryToAssetWhere } from '@/services/assets/search';

export const useSearchQuery = () => {
  const { id: projectId } = useParams<MatchParams>();
  const location = useLocation();
  const whereRef = useRef<AssetWhere | null>(null);

  return useMemo(() => {
    if (!projectId) {
      whereRef.current = null;
    } else {
      const where = parsedQueryToAssetWhere(location);

      where.project = { id: projectId };

      if (!dequal(whereRef.current, where)) {
        whereRef.current = where;
      }
    }
    return whereRef.current;
  }, [location, projectId]);
};

export const useAssetsCount = () => {
  const where = useSearchQuery();

  const { data, refetch } = useQuery<CountAssetsQuery>(GQL_ASSETS_COUNT_INSPECT, {
    context: { clientName: 'V2' },
    fetchPolicy: 'no-cache',
    variables: { first: null, skip: null, where },
  });

  return { refetch, totalCount: data?.count ?? 0 };
};
