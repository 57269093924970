/* eslint-disable no-underscore-dangle */

import L, { type LeafletMouseEvent } from 'leaflet';

L.Draw.Polyline = L.Draw.Polyline.include({
  _onMouseDown(this: L.Draw.Polyline, e: LeafletMouseEvent) {
    if (!this._clickHandled && !this._touchHandled && !this._disableMarkers) {
      this._onMouseMove(e);
      this._clickHandled = true;
      this._disableNewMarkers();
      const { originalEvent } = e;
      const { clientX } = originalEvent;
      const { clientY } = originalEvent;
      this._startPoint.call(this, clientX, clientY);
      originalEvent.stopPropagation();
    }
  },
});
