import { type AssetLockSlice, type AssetLockSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: AssetLockSliceValues = {
  isReadOnly: false,
  reason: null,
};

const log = createLogger('assetLock');

export const createAssetLockSlice: ZustandSlice<AssetLockSlice> = (set, _get) => ({
  ...initialState,
  update: payload =>
    set(
      state => {
        state.assetLock.isReadOnly = payload.isReadOnly;
        state.assetLock.reason = payload.reason;
      },
      false,
      log('update', payload),
    ),
});

export * from './types';
