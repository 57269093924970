/* eslint-disable no-underscore-dangle */
import L from 'leaflet';

const mapProto = L.extend({}, L.Map.prototype);

// Here this is to keep a similar viewBox for the SVG (in leaflet rotate they changed a bit the rounding which was affecting then our tests)
L.Map.include({
  _getPaddedPixelBounds(padding) {
    if (!this._rotate && mapProto._getPaddedPixelBounds) {
      return mapProto._getPaddedPixelBounds.apply(this, padding);
    }
    const p = padding;
    const size = this.getSize();
    const padMin = size.multiplyBy(-p).round();
    const padMax = padMin.add(size.multiplyBy(1 + p * 2)).round();

    return new L.Bounds([
      this.containerPointToLayerPoint([padMin.x, padMin.y]).round(),
      this.containerPointToLayerPoint([padMin.x, padMax.y]).round(),
      this.containerPointToLayerPoint([padMax.x, padMin.y]).round(),
      this.containerPointToLayerPoint([padMax.x, padMax.y]).round(),
    ]);
  },
});
