import L from 'leaflet';

/* eslint-disable no-underscore-dangle */

L.Handler.PathHandler = L.Class.extend({
  addHooks() {
    throw new Error("Method 'addHooks' must be implemented.");
  },

  disable() {
    if (!this._enabled) {
      return this;
    }

    this._enabled = false;
    this.removeHooks();
    return this;
  },

  enable() {
    if (this._enabled) {
      return this;
    }

    this._enabled = true;
    this.addHooks();
    return this;
  },

  enabled() {
    return !!this._enabled;
  },

  initialize(path: L.Path) {
    this._path = path;
    this._enabled = false;
  },

  removeHooks() {
    throw new Error("Method 'removeHooks' must be implemented.");
  },
});
