import { type Draft } from 'immer';

import { type LabelSaveSlice, type LabelSaveSliceValues } from './types';

import { type ZustandSlice } from '..';
import createLogger from '../helpers';

const initialState: LabelSaveSliceValues = {
  blockSavingInProgress: false,
  clientVersion: null,
};

const log = createLogger('labelSave');

export const createLabelSaveSlice: ZustandSlice<LabelSaveSlice> = set => ({
  ...initialState,
  updateField: payload =>
    set(
      state => {
        const { path, value } = payload;
        (state.labelSave as Draft<LabelSaveSliceValues>)[path] = value;
      },
      false,
      log('updateField', payload),
    ),
});

export * from './types';
