import { type Annotation } from '@/graphql/annotations/types';

const ANNOTATION_TYPENAMES: readonly Annotation['__typename'][] = [
  'ClassificationAnnotation',
  'ComparisonAnnotation',
  'TranscriptionAnnotation',
  'VideoClassificationAnnotation',
  'VideoObjectDetectionAnnotation',
  'VideoTranscriptionAnnotation',
];

export function isAnnotation(input: unknown): input is Annotation {
  return !!input && ANNOTATION_TYPENAMES.includes((input as Annotation).__typename);
}
