import {
  type Annotation,
  type AnnotationJobCounter,
  type AnnotationNamesJob,
  type JobAnnotation,
  type Jobs,
  type JsonResponse,
} from '@kili-technology/types';
import { batch } from 'react-redux';

import { InputType, type LabelType } from '@/__generated__/globalTypes';
import { getResponsesToSet } from '@/services/jobs/setResponse';
import { type AppThunk } from '@/store';
import {
  labelInterfaceUpdateField,
  removeAllSelectedObjectIds,
  replaceAllWithSelectedObjectId,
  useStore,
} from '@/zustand';

import { updateFrameResponsesWithoutCloning } from './actions/updateFrameResponsesWithoutCloning';
import { convertJsonResponseToFrameResponses } from './helpers';
import {
  type LabelFrameSelectRangeObject,
  type LabelFrameSetResponsePayload,
  type LabelFrameUpdateCurrentFramePayload,
} from './types';

import { assetLabelLabelType } from '../asset-label/selectors';
import { LABEL_FRAMES_SET_RESPONSES } from '../label-frames/slice';
import { projectInputType } from '../selectors';

export const initializeJobCategoriesToMids = (
  jobs: Jobs,
  labelType: LabelType,
  responses: JsonResponse[],
) => {
  const { setJobCategoriesToMids, initJobCategoriesToMids } = useStore.getState().labelFrame;
  const newAnnotations: Annotation[] = [];

  if (responses['0']?.ANNOTATION_JOB_COUNTER && responses['0']?.ANNOTATION_NAMES_JOB) {
    initJobCategoriesToMids({
      ANNOTATION_JOB_COUNTER: responses['0'].ANNOTATION_JOB_COUNTER as AnnotationJobCounter,
      ANNOTATION_NAMES_JOB: responses['0']?.ANNOTATION_NAMES_JOB as AnnotationNamesJob,
    });
  } else {
    responses.forEach(item => {
      const responseToSet = getResponsesToSet(jobs, labelType, item);
      responseToSet.forEach(itm => {
        const { annotations } = itm.jobResponse as JobAnnotation;
        if (annotations) {
          annotations.forEach(ann => {
            const foundItem = newAnnotations.find(newAnn => newAnn.mid === ann.mid);
            if (!foundItem) newAnnotations.push(ann);
          });
        }
      });
    });
    newAnnotations?.forEach(anno => {
      setJobCategoriesToMids({
        category: anno.categories[0].name,
        jobName: anno.jobName as string,
        mid: anno.mid,
        name:
          jobs?.[anno.jobName as string]?.content?.categories?.[anno.categories[0].name]?.name ??
          '',
      });
    });
  }
};

export const labelFrameSet = (payload: LabelFrameSetResponsePayload): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const labelType = assetLabelLabelType(state);
    const { labelResponse } = payload;
    const frameResponses = convertJsonResponseToFrameResponses(labelResponse, labelType);
    batch(() => {
      dispatch(LABEL_FRAMES_SET_RESPONSES({ frameResponses }));
      dispatch(updateFrameResponsesWithoutCloning({ frameResponses }));
    });
  };
};

export const resetFrameStateBeforeGoingToNextAsset = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    if (inputType !== InputType.VIDEO) return;
    useStore.getState().labelFrame.resetFrame();
  };
};

export const setSelectedFrame = ({
  frame,
  selectedFrames: newSelectedFrames,
}: LabelFrameUpdateCurrentFramePayload): AppThunk => {
  return () => {
    useStore
      .getState()
      .labelFrame.setSelectedFrames({ frame, selectedFrames: newSelectedFrames || {} });
  };
};

export const selectRangeObject = ({
  mid,
  isSelectingSeveralObjects,
}: LabelFrameSelectRangeObject) => {
  const {
    labelInterface: { selectedObjectIds },
  } = useStore.getState();
  const isSelectingANewObject = !selectedObjectIds.includes(mid);
  if (!isSelectingSeveralObjects) {
    replaceAllWithSelectedObjectId({ mid });
    return;
  }
  removeAllSelectedObjectIds();
  if (mid && selectedObjectIds.length === 1 && !isSelectingANewObject) {
    return;
  }
  const newSelectedRangeObjects = isSelectingANewObject
    ? selectedObjectIds.concat([mid])
    : selectedObjectIds.filter(id => id !== mid);
  labelInterfaceUpdateField({ path: 'selectedObjectIds', value: newSelectedRangeObjects });
};

export const resetSelectedFrames = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const inputType = projectInputType(state);
    if (inputType !== InputType.VIDEO) return;
    useStore.getState().labelFrame.resetSelectedFrames();
  };
};
